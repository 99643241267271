export const resourceconstant = {
  CREATE_WISH_LIST: "createwishlist",
  LIST_WISH_LISTS: "listwishlist",
  DELETE_WISH_LISTS: "deletewishlist",
  PRODUCT_ADD_TO_CART: "createAddCart",
  UPDATE_USER_ADDRESS: "updateuseraddress",
  LIST_USER_DETAILS: "listuser",
  LIST_COUNTRY_DETAILS: "listcountries",
  PRODUCT_ADD_TO_CART_DELETE: "deleteCart",
  PRODUCT_LIST: "productlist",
  PRODUCT_DETAIL_LIST: "productdetaillist",
  CREATE_GENERAL_CONTACTUS: "creategeneralconatctus",
  LIST_ORDER_ITEM: "listorderitems",
  LIST_ORDERS: "listOrders",
  PRODUCT_BUY_NOW: "productbuynow",
  LIST_CATEGORY: "listCategory",
  BEST_SELLERS: "bestSellers",
  BRAND_LIST: "brandList",
  LIST_TOKEN_DETAILS: "listTokenDetails",
  CREATE_TOKEN_DETAIL: "createToken",
  UPDATE_TOKEN_DETAIL: "updateToken",
  LIST_CARTS: "listCarts",
  UPDATE_CART: "updateCart",
  CREATE_ORDER: "createOrder",
  UPDATE_ORDER: "updateOrder",
  UPDATE_ORDER_ITEM: "updateorderitem",
  CREATE_PRODUCT_REVIEW: "createproductreview",
  GET_DROPDOWN_MASTER:"listDropDownMasters",
  GET_COMPANY_DETAILS:"listcompanydetails",
  PROFILE_PHONE_SEND_OTP: "phoneSendOtp",
  PROFILE_PHONE_VERIFY_OTP: "phoneVerifyotp",
  PROFILE_MAIL_OTP: "mailOtp",
  UPDATE_NOTIFICATION:"updateNotifications",
  PRODUCT_VISIT_COUNT: "productVisit",
  PRODUCT_VISIT_COUNT_UPDATE: "productVisitUpdate",
  PRODUCT_VISIT_COUNT_LIST: "productVisitList",
  PRODUCT_RATING_LIST: "productRatingList",
  PROMO_CODE_LIST: "promoCodeList",
  UPDATE_PRODUCT_REVIEW:"updateProductReview",
  TOP_DEALS: "topDeals",
  CATEGORY_WISE: "categoryWise",
  UPDATE_PRODUCT_ITEM:"updateProductDetails",
  LIST_CONFIGURATION:"listConfigurations",
  CREATE_CONFIGURATION:"createConfigurations",
  UPDATE_CONFIGURATION:"updateConfigurations",
  UPDATE_PRODUCT_ITEM:"updateProductDetails",
  POLICY_CONTENTS: "listContent",
  DELIVERY_FEE: "listDeliveryFee",
  PAYMENT_MODE: "listPaymentmode",
  CAROUSAL_IMAGES: "listCarousal",
  PRODUCTS_FILTERS: "productFilter",
  PROFILE_NEW_MAIL: "newEmail",
  USER_DELETE: "userDelete",
  FILTER_LIST: "filterList",
  CREATE_USER: "createUser",
  LIST_ALL_PRODUCTS: "listAllProducts",
  FORGET_PASSWORD: "forgetPassword",
  LIST_WAREHOUSES: "listWarehouse"
};

export const commonImages = {
  LOGO: "https://jerseymepa1d95feba3e148e4937bf474442d8a6c221411-dev.s3.me-south-1.amazonaws.com/public/image+(8).png",
};

export const awsConfig = {
   AWS_ACCESS_KEY_ID:'AKIAV36Z3YERVMLDCQU7',
   AWS_ACCESS_SECRET_KEY:'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',
   AWS_REGION:'me-south-1',
   AWS_BUCKET_NAME:'jmcwebsitefiles'
}

export const OrderConstant ={
  ORDER_STATUS:[
    {label:"Order Confirmed",value:"order-Received"},{label:"Order Processing",value:"order-Processing"},{label:"Ready For Shipping",value:"order-Packing"},
    {label:"Out For Delivery",value:"order-OutForDelivery"},{label:"Delivered",value:"order-Delivered"},{label:"Order Cancelled",value:"order-Cancelled"},
    {label:"Return Requested",value:"return-Requested"},{label:"Return Cancelled",value:"return-Cancelled"},
    {label:"Return Accepted",value:"return-Accepted"},{label:"Return Rejected",value:"return-Rejected"},{label:"Return Item Shipped",value:"return-Shipped"},
    {label:"Return Item Received",value:"return-Received"},{label:"Return Refund Approved",value:"return-RefundApproved"},{label:"Return Refund Rejected",value:"return-RefundRejected"},
    ],
  PAYMENT_METHOD:["CreditCard","BankTransfer","CashOnDelivery"],
  SHIPPING_METHOD:["Doorstep","Self Pickup"]
} 

export const PaymentReturnUrl = {RETURN_URL:"/payment-confirm"}
export const PaymentReturnUrlApp = {RETURN_URL:"/paymentConfirmationApp"}

export const ShareUrls = {
  FACEBOOK: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
  TWITER: `https://twitter.com/share?url="${window.location.href}`,
  EMAIL: `mailto:?subject=I want you to see this product&amp;body=Check out this product on MEPLinks ${window.location.href}`
}

export const Status = {
  STOCKSTATUSIN : "IN STOCK",
  STOCKSTATUSOUT: "OUT OF STOCK",
  INSTOCKSTATUS: "INSTOCK",
  OUTOFSTOCKSTATUS: "OUTOFSTOCK",
  INACTIVE: "INACTIVE"
}

export const PaymentAppCredential = {
  USEREMAIL:"lavanya@links4engg.com",
  PASSWORD:"Lavanya@123"
}

export const ProductPageUrl = {
  ALLPRODUCTS: "all-products",
  PRODUCT_DETAIL_URL:"/product-detail/"
}

export const UserPoolDetails = {
  USERPOOLID: 'me-south-1_qIWdVLHt6',
  IDENTITYPOOLID: 'me-south-1:2882bc43-ca0a-475f-88fc-4a6da897f1e2',
  REGION: 'me-south-1',
}

export const PolicyContent = {
  TERMSCONDITION: 'Terms and Conditions',
  REFUNDPOLICY: 'Refund Policy and Terms',
  SHIPPINGPOLICY: 'Shipping and Delivery Terms',
  PRIVACYPOLICY: 'Privacy Policy and Terms',
  COOKIEPOLICY: 'Cookie Policy',
  COMMUNITY: 'Community Guidelines'
}

export const MobileApps = {
  ANDROID: "Android App",
  IOS: "IOS App",
  CERTIFICATE: "Certificate"
}
