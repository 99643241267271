import React, { useEffect, useState, useRef } from "react";
import { GetOrderList } from "../../utils/generalMethod"
import { PaymentReturnUrl } from "../../constants"
import {validateAndRefreshToken} from "../../providers/queryData"
import useLogoImage from "../../hooks/useLogoImage";
const QPayPayment = () => {
    const [secretHash, setSecretHash] = useState(null);
    const [responseData, setResponseData] = useState(null)
    const [orderDetail, setOrderDetail] = useState(null)
    const formRef = useRef(null);
    const [logoUrl, setLogoUrl] = useLogoImage();
    const current = new URL(window.location.href);
    const pathSegments = current?.origin
    
    useEffect(() => {
        const orderID = localStorage.getItem('orderId')
        GetOrdersDetails(orderID)
    }, [])

    const GetOrdersDetails = (orderID) => {
        let filter = {
            id: { eq: `${orderID}` },
        };
        GetOrderList(filter, setResponseData);
    };

    useEffect(() => {
        if (responseData?.severity === "success") {
            setOrderDetail(responseData?.data?.data?.listOrders?.items?.[0]);
            CreateHashCode(responseData?.data?.data?.listOrders?.items?.[0]);
        }
    }, [responseData]);

    const CreateHashCode = async(orders) => {
        const data = {
            referenceId: localStorage.getItem('orderId'),
            amount: parseFloat(orders?.totalAmount).toFixed(2),
        };
        //const token = localStorage.getItem("IdToken");
        const token = await validateAndRefreshToken();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        };
        if (data) {
            requestOptions.body = JSON.stringify(data);
        }
        requestOptions.headers.Authorization = token;
        fetch(process.env.REACT_APP_GENERATE_HASHCODE, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setSecretHash(data?.secure_hash)
            })
            .catch((error) => {
                console.error("error.message", error.message)
            });
    }

    useEffect(() => {
        if (secretHash) {
            formRef.current.submit();
        }
    }, [secretHash]);

    return (<><div>
        <p class="redirecting-message">Please wait! Redirecting to payment...</p>
         </div>
        <form method="POST" ref={formRef} action="https://paymentapi.qib.com.qa/api/gateway/v2.0">
            <input name="action" hidden value="capture" />
            <input name="gatewayId" hidden value="017855659" />
            <input name="signatureFields" hidden value="gatewayId,amount,referenceId" />
            <input name="signature" hidden value={secretHash} />
            <input name="referenceId" hidden value={orderDetail?.id} />
            <input name="amount" hidden value={parseFloat(orderDetail?.totalAmount).toFixed(2)} />
            <input name="currency" hidden value="QAR" />
            <input name="mode" hidden value="LIVE" />
            <input name="description" hidden value="PRODUCT 1" />
            <input name="returnUrl" hidden value={`${pathSegments}${PaymentReturnUrl?.RETURN_URL}`}/>
            <input name="name" hidden value={orderDetail?.userName} />
            {/* <input name="address" hidden value="Building No. 44, Zone No. 91، Street No. 3062, Logistic Park-B" />
            <input name="city" hidden value="Birkat Al Awamer" />
            <input name="state" hidden value="Birkat Al Awamer" />*/}
            <input name="country" hidden value="QA" /> 

            <input name="address" hidden value={orderDetail?.shippingAddress?.addressLine1 + "," + orderDetail?.shippingAddress?.addressLine2 + "," + orderDetail?.shippingAddress?.street} />
            <input name="city" hidden value={orderDetail?.shippingAddress?.city} />
            <input name="state" hidden value={orderDetail?.shippingAddress?.state} />
            {/* <input name="country" hidden value={orderDetail?.shippingAddress?.country} /> */}
            <input name="phone" hidden value={orderDetail?.shippingUserDetails?.phoneNumber} />
            <input name="email" hidden value={orderDetail?.shippingUserDetails?.email} />
        </form>
    </>
    )
}

export default QPayPayment