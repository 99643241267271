import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import Notificationwindow from '.';
import useNotification from '../../hooks/useNotification';
import useUserDetails from '../../hooks/useUserDetails';

function AllNotification() {
  const [notifications, setNotifications] = useNotification();
  const { handleClearAllNotifications, getIcon, handleNotificationClick } = Notificationwindow({ notifications, setNotifications });
  const [useuserDetails, setUseUserDetails] = useUserDetails()

  return (
    <li className="notification dropdown">
      <a className="dropbtn">
        <i class="fa fa-bell"></i>
        <span className="badge">{notifications?.length}</span>
        <span className='d-none d-sm-block'>Notifications</span>
        <div className="dropdown-content">
          <div className='p-4'>
            <div className='d-flex align-items-center justify-content-between'>
              <h2>Notifications</h2>
              {useuserDetails?.id && (
                <a style={{ cursor: "pointer" }} className='clearall d-none d-md-block' onClick={handleClearAllNotifications}>Clear All</a>
              )}
              <a className='d-block d-md-none' onClick={handleClearAllNotifications}>
                <i className='fa fa-trash'></i>
              </a>
            </div>
            <Tab.Container defaultActiveKey="all">
              <div className='tabs'>
                <Nav>
                  <Nav.Item>
                    <Nav.Link eventKey="all">All <span className="badge">{notifications?.length}</span></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="read">Read <span className="badge">{notifications?.filter(notification => notification.status === 2).length}</span></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="unread">Unread <span className="badge">{notifications?.filter(notification => notification.status === 1).length}</span></Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  <Row>
                    <Col className='notification-block'>
                      {notifications?.length > 0 ? (
                        notifications.map((notification) => (
                          <Row>
                            <Col md={12} className='p-0'>
                              <div key={notification.id} className={`note-item ${notification.status === 1 ? 'active' : ''}`}>
                                <div className="d-flex justify-content-evenly">
                                  <div className="note-icon on">
                                    {getIcon(notification)}
                                  </div>
                                  <div className="d-flex flex-column description-box">
                                    <div className="description">
                                      <h2 className="title" onClick={() => handleNotificationClick(notification)}>
                                        {notification.title}
                                      </h2>
                                      <p className="date">{notification.date}</p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="message">{notification.message}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <Col md={12} className='no-results'>
                          <p>No New Notifications</p>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="read">
                  <Row>
                    <Col className='notification-block'>
                      {notifications?.length > 0 ? (
                        notifications?.filter(notification => notification.status === 2).map((notification) => (
                          <Row>
                            <Col md={12} className='p-0'>
                              <div key={notification.id} className={`note-item ${notification.status === 1 ? 'active' : ''}`}>
                                <div className="d-flex justify-content-evenly">
                                  <div className="note-icon on">
                                    {getIcon(notification)}
                                  </div>
                                  <div className="d-flex flex-column description-box">
                                    <div className="description">
                                      <h2 className="title" onClick={() => handleNotificationClick(notification)}>{notification.title}</h2>
                                      <p className="date">{notification.date}</p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="message">{notification.message}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <Col md={12} className='no-results'>
                          <p>No New Notifications</p>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="unread">
                  <Row>
                    <Col className='notification-block'>
                      {notifications?.length > 0 ? (
                        notifications?.filter(notification => notification.status === 1).map((notification) => (
                          <Row>
                            <Col md={12} className='p-0'>
                              <div key={notification.id} className={`note-item ${notification.status === 1 ? 'active' : ''}`}>
                                <div className="d-flex justify-content-evenly">
                                  <div className="note-icon on">
                                    {getIcon(notification)}
                                  </div>
                                  <div className="d-flex flex-column description-box">
                                    <div className="description">
                                      <h2 className="title" onClick={() => handleNotificationClick(notification)}>{notification.title}</h2>
                                      <p className="date">{notification.date}</p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="message">{notification.message}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <Col md={12} className='no-results'>
                          <p>No New Notifications</p>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
          <Link to="/view-notification">
            <div className='viewall'>
              View All
            </div>
          </Link>
        </div>
      </a>
    </li>
  );
}

export default AllNotification;
