import { useContext, useEffect, useState } from 'react';
import { CartPageContext } from "../context/CartContext";
import { resourceconstant } from '../constants';
import { GetData } from '../providers/queryData';
import useProfile from './useProfile';
function useCart() {
  const { cartPageItem, setCartPageItem } = useContext(CartPageContext);
  const [cartList, setCartList] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [userProfile, setUserProfile] = useProfile();
  const userid = userProfile?.attributes?.sub;
  
  const CartList = () => {
      let filter = {};
      GetData(resourceconstant.LIST_CARTS, filter, setCartData);
  };
  
  useEffect(() => {
      CartList();
  }, []);
  
  useEffect(() => {
      if (cartData?.severity === "success") {
        const cart = cartData?.data?.data?.listCarts?.items
        const data = cart.filter(cart => cart?.userId === userid);
          setCartList(data);
      }
  }, [cartData]);
  
  const item = localStorage.getItem('cartData');
  const cartCount = item ? JSON.parse(item) : [];
  
  useEffect(() => {
    const item = localStorage.getItem('cartData');
    const cartCount = item ? JSON.parse(item) : [];

    const GetCartDetails = () => {
      const mergedCartList = [...cartList];

      cartCount.forEach(cartItem => {
        const existingItem = mergedCartList.find(
          item => item?.productID === cartItem?.productID && item?.attrValueID === cartItem?.attrValueID
        );
        if (!existingItem) {
          mergedCartList.push(cartItem);
        } 
      });

      setCartPageItem(mergedCartList);
      localStorage.setItem('cartData', JSON.stringify(mergedCartList));
    };

    GetCartDetails();
  }, [cartList, setCartPageItem]);
  
  return [cartPageItem, setCartPageItem];
  
}

export default useCart;