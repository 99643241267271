import React from "react";
import { CheckNull } from "../utils/validate";

export default function AddressDisplay({data}) {

    return (<>
        <p>
            {data?.address?.addressLine1} <br />{" "}
            {data?.address?.addressLine2 && data?.address?.addressLine2+ "," }
            {data?.address?.street},{" "}
            <br /> {data?.address?.city},
            {data?.address?.state && data?.address?.state + ","} <br />
            {data?.address?.country} {data?.address?.postalCode && " - "+ data?.address?.postalCode}
        </p>
        <p>{data?.phoneNumber}</p>
        <p>{data?.email}</p>
    </>
    );
}
