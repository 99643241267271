import React, { useState, useMemo, useEffect } from 'react'
import { API } from 'aws-amplify';
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import payVisaPng from '../../assets/images/icons/visa.png';
import CartGridList from './cartGridList';
import Amplify, { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import customLogger from '../../common/loggerService';
import OrderSummery from './orderSummery';
import WishlistCarousel from './wishlistCarousel';
import { Link } from 'react-router-dom';
import { CreateEntry } from '../../providers/queryData';
import { resourceconstant, Status } from '../../constants';
import { usePreviousPage } from '../../context/usePreviousPage';
import { GetData } from "../../providers/queryData";
import useProfile from '../../hooks/useProfile';
import useCart from '../../hooks/useCartPage';
import { v4 as uuid } from 'uuid';
import { MoveToWishList } from "../../utils/generalMethod";
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
import useUserDetails from '../../hooks/useUserDetails'
import { ToastrMessages } from '../../constants/toastrMessages';
import { Helmet } from 'react-helmet';

const CartList = () => {
    const [userProfile, setUserProfile] = useProfile()
    const [cartPageItem, setCartPageItem] = useCart()
    const [saveCart, setSaveCart] = useState([])
    const [isSaveCart, setIsSaveCart] = useState(false)
    const [cartError, setCartError] = useState('')
    const [previousPage, setPreviousPage] = usePreviousPage();
    const navigate = useNavigate()
    const item = localStorage.getItem('cartData')
    const cartCount = item ? JSON.parse(item) : [];
    const [cartResponseData, setCartResponseData] = useState(null);
    const [cartList, setCartList] = useState([])
    const [cartData, setCartData] = useState([])
    const [myCart, setMyCart] = useState(null)
    const [saveMyCart, setSaveMyCart] = useState([])
    const [deleteIcon, setDeleteIcon] = useState(false)
    const [wishListData, setWishListData] = useState([]);
    const [wishList, setWishList] = useState([]);
    const [responseData, setResponseData] = useState(null);
    const userid = userProfile?.attributes?.sub;
    const [userDetails, setUserDetails] = useUserDetails();
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [productsList, setProductsList] = useState([])
    const [productDetailsList, setProductDetailsList] = useState([])
    const [products, setProducts] = useState([])
    const [productDetails, setProductDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const [qtyChanges, setQtyChanges] = useState(false)

    useEffect(() => {
        let userData = userDetails?.addressDetails?.filter(
            (item) => item.default === true
        );
        if (userData?.length > 0) setDefaultAddress(userData[0]);
    }, [userDetails]);

    const redirectToLogin = () => {
        setPreviousPage('/cart');
        navigate('/login');
    };

    const CartList = () => {
        let filter = {
        }
        GetData(resourceconstant.LIST_CARTS, filter, setCartData)
    };

    useEffect(() => {
        if (cartData?.severity === "success") {
            setCartList(cartData?.data?.data?.listCarts?.items)
        }
    }, [cartData])

    useEffect(() => {
        CartList()
    }, [])

    useEffect(() => {
        const mergedCartList = [...cartCount, ...cartList.filter(item => !cartCount.some(cart => cart?.productID === item?.productID))];
        const userCartList = mergedCartList.filter(cart => cart?.userID === userid || cart?.userId === userid);
        setSaveCart(mergedCartList)
    }, [cartList])

    const GetProductList = () => {
        let filter = {
            status: {
                eq: "published"
            }
        }
        GetData(resourceconstant.PRODUCT_LIST, filter, setProductsList)
        setLoading(true)
    };

    const ProductDetails = async () => {
        let filter = {
        }
        GetData(resourceconstant.PRODUCT_DETAIL_LIST, filter, setProductDetailsList)
        setLoading(true)
    };

    useEffect(() => {
        GetProductList()
        ProductDetails()
    }, [])

    useEffect(() => {
        if (productsList?.severity === "success") {
            setLoading(false)
            setProducts(productsList?.data?.data?.listProducts?.items)
        }
    }, [productsList])

    useEffect(() => {
        if (productDetailsList?.severity === "success" && products?.length > 0) {
            setLoading(false)
            const data = productDetailsList?.data?.data?.listProductDetails?.items
            const filteredProductsDetails = data?.filter(product =>
                products?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                    (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
            );
            setProductDetails(filteredProductsDetails)
        }
    }, [productDetailsList, products])

    const HandleSaveToCart = async (name) => {
        setCartError('')
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const userid = currentUser.attributes.sub;
            const userEmail = currentUser.attributes.email;
            const userName = localStorage.getItem('fullName');
            const productInCart = cartPageItem.filter(cart => cart?.userID === userid || cart?.userId === userid);

            let condition = false
            const cartItems = cartPageItem?.map(item => {
                const stock = productDetails?.filter(product => product?.productID === item?.productID);
                const stockItem = item?.attrValueID
                    ? stock?.filter(product => product?.attrValueID === item?.attrValueID && product?.productID === item?.productID)
                    : stock;
                const outStock = products?.filter(product => product?.id === item?.productID);

                const isOutOfStock = !outStock?.[0]?.allowPreOrder && (
                    outStock?.[0]?.showWhenZeroStock && stockItem?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS ||
                    stockItem?.[0]?.stockQuantity - stockItem[0]?.reservedStock === 0 ||
                    item?.selectedQuantity > stockItem?.[0]?.stockQuantity - stockItem[0]?.reservedStock ||
                    stockItem?.length === 0
                );

                if (isOutOfStock) {
                    condition = true;
                }
                const cartTotal = outStock[0]?.taxDetail?.reduce(
                    (total, item) => total + (item?.taxCharge * stockItem[0]?.salePrice / 100),
                    0
                );
                const totalPrice = item.selectedQuantity * stockItem[0]?.salePrice + item.selectedQuantity * cartTotal;
                return {
                    userID: userid,
                    productID: item?.productID,
                    productImage: item?.productImage,
                    productName: item?.productName,
                    price: stockItem?.[0]?.regularPrice,
                    salesPrice: stockItem?.[0]?.salePrice,
                    selectedQuantity: item.selectedQuantity,
                    brandDetail: {
                        brandID: item?.brandDetail?.brandID,
                        brandName: item?.brandDetail?.brandName,
                    },
                    shippingInfo: {
                        heightUnit: `${item.shippingInfo.heightUnit}`,
                        heightValue: `${item.shippingInfo.heightValue}`,
                        lengthUnit: `${item.shippingInfo.lengthUnit}`,
                        lengthValue: `${item.shippingInfo.lengthValue}`,
                        weightUnit: `${item.shippingInfo.weightUnit}`,
                        weightValue: `${item.shippingInfo.weightValue}`,
                        widthUnit: `${item.shippingInfo.widthUnit}`,
                        widthValue: `${item.shippingInfo.widthValue}`,
                    },
                    wareHouseName: `${item.wareHouseName}`,
                    wareHouseId: `${item.wareHouseId}`,
                    userName: userName,
                    userMail: userEmail,
                    model: item?.model,
                    taxCharge: item?.taxCharge,
                    sku: `${item?.sku}`,
                    searchKey: `${item.searchKey}`,
                    totalPrice: totalPrice,
                    mobileNumber: `${item.mobileNumber}`,
                    currentStock: `${item?.currentStock}`,
                    attrName: item.attrName,
                    attrValue: item.attrValue,
                    attrValueID: item?.attrValueID,
                    mainCategory: `${item?.mainCategory}`,
                    subCategoryN: `${item?.subCategoryN}`,
                    stockUnit: `${item?.stockUnit}`,
                    itemsPerUnit: item?.itemsPerUnit,
                    currentStock: item?.currentStock,
                    mainCategoryId: item?.mainCategoryId,
                    subCategoryId: item?.subCategoryId,
                    brandId: item?.brandDetail?.brandID,
                };
            });

            if (condition) {
                if (cartPageItem?.length > 0) {
                    showErrorToastr(ToastrMessages?.CARTOUTOFSTOCK);
                } else {
                    showErrorToastr(ToastrMessages?.NOCART);
                }
            }
            else {
                CreateEntry(resourceconstant?.PRODUCT_ADD_TO_CART, true, setCartResponseData, cartItems);
            }

            if (name === "myCart") {
                setMyCart('success')
            }
            else if (name === "checkout") {
                setMyCart(null)
                const checkoutDirect = true
                localStorage.setItem('checkoutDirect', checkoutDirect)
            }
            setQtyChanges(false)
            setIsSaveCart(true)
        } catch (err) {
            customLogger.error(err);
            if (err === "The user is not authenticated") {
                redirectToLogin()
            }
        }
    };

    useEffect(() => {
        const userCartList = cartList.filter(cart => cart?.userId === userid);
        if (userCartList?.length > 0) {
            setDeleteIcon(true)
        }
    }, [cartList])

    useEffect(() => {
        localStorage.getItem('cartData')
    }, [])

    useEffect(() => {
        GetWishList();
    }, [userProfile?.attributes?.sub])

    const GetWishList = () => {
        const userid = userProfile?.attributes?.sub;
        let filter = {
            userId: {
                eq: `${userid}`,
            },
        }

        GetData(resourceconstant.LIST_WISH_LISTS, filter, setWishListData)
    };

    useEffect(() => {
        if (wishListData?.severity === "success") setWishList(wishListData?.data?.data?.listWishLists?.items)
    }, [wishListData])

    const AddToWishList = async (item) => {
        const currentDate = new Date().toISOString();

        const productList = products?.filter(product => product?.id === item?.productID);

        const stock = productDetails?.filter(product =>
            productList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
        );

        const price = productList[0]?.attrValue
            ?.map((item) => {
                const matchingDetail = stock.find(
                    (data) => data.attrValueID === item.attrValueID
                );
                return matchingDetail;
            })
            .filter((detail) => detail !== undefined);
        const checked = price ? price : stock
        const priceCondition = price?.[0] ? price?.[0] : stock[0]

        const lowestPriceDetail = checked.reduce((lowest, current) => {
            const currentSalePrice = current?.salePrice || current?.regularPrice;
            const lowestSalePrice = lowest?.salePrice || lowest?.regularPrice;

            return currentSalePrice < lowestSalePrice ? current : lowest;
        }, priceCondition);

        const wishlist = lowestPriceDetail ? lowestPriceDetail : stock[0]

        let wishListData = {
            pk: "WL#",
            id: uuid(),
            userId: userid,
            productId: item?.productID,
            productName: item?.productName,
            productImage: item?.productImage,
            mainCategory: item?.mainCategory,
            mainCategoryId: item?.mainCategoryID,
            subCategory: item?.subCategory,
            subCategoryId: item?.subCategoryID,
            brandName: item?.brandDetail?.brandName,
            brandId: item?.brandDetail?.brandID,
            model: item?.model,
            regularPrice: wishlist?.regularPrice,
            salePrice: wishlist?.salePrice,
            createdAt: currentDate
        }
        MoveToWishList(wishListData, setResponseData)
    }

    useEffect(() => {
        if (responseData?.severity === "success") {
            showSuccessToastr("Added to wishlist")
            setResponseData(null);
            GetWishList();
        }
    }, [responseData])

    useEffect(() => {
        let updatedCartPageItem = [...cartPageItem];

        cartPageItem.forEach((cart, index) => {
            const stock = productDetails.filter(item => item.productID === cart.productID);
            const stockItem = cart.attrValueID
                ? stock.filter(item => item.attrValueID === cart.attrValueID && item.productID === cart.productID)
                : stock;
            const outStock = products?.filter(item => item?.id === cart?.productID);
            const cartTotal = outStock[0]?.taxDetail?.reduce(
                (total, item) => total + (item?.taxCharge * stockItem[0]?.salePrice / 100),
                0
            );

            const updatedCart = {
                ...cart,
                price: stockItem[0]?.regularPrice,
                salesPrice: stockItem[0]?.salePrice,
                brandDetail: {
                    brandName: outStock[0]?.brandName,
                    brandID: outStock[0]?.brandID
                },
                sku: stockItem[0]?.sku,
                taxCharge: cartTotal ? cartTotal : 0,
                model: stockItem[0]?.model,
                productName: outStock[0]?.productName,
                mainCategory: outStock[0]?.mainCategory,
                subCategoryN: outStock[0]?.subCategory,
                stockUnit: stockItem[0]?.stockUnit,
                itemsPerUnit: stockItem[0]?.itemsPerUnit,
                currentStock: stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock,
                attrValue: stockItem[0]?.attrValue,
                attrName: stockItem[0]?.attrName,
                wareHouseName: stockItem[0]?.storageInfo?.wareHouse,
                wareHouseId: stockItem[0]?.storageInfo?.wareHouseId,
                productImage: outStock[0]?.thumbnailUrl
                    ? outStock[0]?.thumbnailUrl
                    : outStock[0]?.featuredImage?.url,
            };

            updatedCartPageItem[index] = updatedCart;
        });

        setCartPageItem(updatedCartPageItem);
    }, [productDetails]);

    return (
        <>

            <section id='cartPageSec' className="pt-1">
                <Container>
                    <Row className='gx-1'>
                        <CartGridList setCartList={setCartList} qtyChanges={qtyChanges} setQtyChanges={setQtyChanges} setLoading={setLoading} loading={loading} products={products} productDetails={productDetails} defaultAddress={defaultAddress} wishList={wishList} deleteIcon={deleteIcon} cartList={cartList} cartResponseData={cartResponseData} HandleSaveToCart={HandleSaveToCart} AddToWishList={AddToWishList} />
                        <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12} className='mb-1'>
                            <OrderSummery defaultAddress={defaultAddress} secartPageItem={cartPageItem} CartList={CartList} setMyCart={setMyCart} cartList={cartList} myCart={myCart} cartResponseData={cartResponseData} HandleSaveToCart={HandleSaveToCart} listingCart={saveCart} isSaveCart={isSaveCart} setCartError={setCartError} cartError={cartError} />
                            <Card className='pay-with-card mt-2'>
                            </Card>
                        </Col>
                    </Row>
                    {wishList.length > 0 && (
                        <Row className="product-list pt-0">
                            <Col md={12}>
                                <div className="products-sec mt-0">
                                    <div className="overlap-title">
                                        <h2 className="text-title">Wishlist ({wishList.length})</h2>
                                        <Link className="viewall" to="/wishlist" onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none' }}>
                                            View all
                                        </Link>
                                    </div>
                                    <div className="product-carouselItem">
                                        <WishlistCarousel products={products} productDetails={productDetails} wishList={wishList} GetWishList={GetWishList} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section></>
    )
}

export default CartList
