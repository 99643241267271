import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { DeleteFromWishList, GetProductList, GetProductDetailList, HandleAddToCartToLocal } from "../../utils/generalMethod"
import { showSuccessToastr } from '../../common/toastrService';
import useProfile from '../../hooks/useProfile';
import ProductRating from '../../components/productRating';
import { useNavigate } from "react-router-dom";
import useCart from '../../hooks/useCartPage';
import ProductToolTip from '../../components/productToolTip';
import { FormatValueDecimal } from '../../utils/validate';
import { Status } from '../../constants';
import { CTooltip } from '@coreui/react';
import useLogoImage from '../../hooks/useLogoImage';
import useUserDetails from '../../hooks/useUserDetails'

const WishlistCarousel = ({ products, productDetails, wishList, GetWishList }) => {
  const [logoUrl, setLogoUrl] = useLogoImage();
  const [userDetails, setUserDetails] = useUserDetails();
  const [dataSuccess, setDataSuccess] = useState(null);
  const [localCartData, setLocalCartData] = useState([]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 2
    }
  };
  const CustomDot = ({ active, onClick }) => {
    return (
      <li>
        <button
          className={`custom-dot ${active ? "custom-dot--active" : ""}`}
          onClick={() => onClick()}
        />
      </li>
    );
  };

  const [userProfile, setUserProfile] = useProfile()
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [productResponse, setProductResponse] = useState(null);
  const [productDetailResponse, setProductDetailResponse] = useState(null);
  const [productDetailList, setProductDetailList] = useState([])
  const [productList, setProductList] = useState([])
  const [addToCart, setAddTocart] = useState(false)
  const navigate = useNavigate()
  const [cartPageItem, setCartPageItem] = useCart()
  const [productListResponseItem, setProductListResponseItem] = useState([])
  const [productsItem, setProductsItem] = useState([])

  const DeleteWishList = (event, wishListData) => {
    event.preventDefault();
    const data = {
      pk: "WL#",
      id: wishListData?.id
    }
    DeleteFromWishList(data, setDeleteResponse)
  }

  useEffect(() => {
    if (deleteResponse?.severity === "success") {
      showSuccessToastr("Product removed from wishlist")
      setDeleteResponse(null)
      GetWishList();
    }
    if (deleteResponse?.severity === "error") {
      showSuccessToastr("Failed to remove the product")
      setDeleteResponse(null)
    }
  }, [deleteResponse])

  const WishAddToCart = (event, item) => {
    event.preventDefault();
    let productFilter = {
      id: {
        eq: `${item?.productId}`,
      },
    }
    GetProductList(productFilter, setProductResponse)

    let productDetailFilter = {
      productID: {
        eq: `${item?.productId}`,
      },
    }
    GetProductDetailList(productDetailFilter, setProductDetailResponse)
  }

  useEffect(() => {
    if (productResponse?.severity === "success") {
      setProductList(productResponse?.data?.data?.listProducts?.items);
    }
  }, [productResponse])

  useEffect(() => {
    if (productDetailResponse?.severity === "success" && productList?.length > 0) {
      const data = productDetailResponse?.data?.data?.listProductDetails?.items
      const filteredProductsDetails = data?.filter(product =>
        productList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
          (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
      );
       
      setProductDetailList(filteredProductsDetails);
    }
  }, [productDetailResponse, productList])


    const ProductLists = () => {
      const product = {
        status: {
          eq: "published"
      }
      }
      GetProductList(product, setProductListResponseItem)
    
    }

    useEffect(() => {
        ProductLists()
    }, [])

    useEffect(() => {
        if (productListResponseItem?.severity === "success") {
            setProductsItem(productListResponseItem?.data?.data?.listProducts?.items);
        }
    }, [productListResponseItem])

  useEffect(() => {
    if (addToCart === false && productList?.length > 0 && productDetailList?.length > 0) {
      setAddTocart(true);
      
      const price = productList[0]?.attrValue
      ?.map((item) => {
        const matchingDetail = productDetailList.find(
          (data) => data.attrValueID === item.attrValueID
        );
        return matchingDetail;
      })
      .filter((detail) => detail !== undefined); 

      const result = price?.length > 0 ? price[0] : productDetailList[0]

      const userId = userProfile?.attributes?.sub;
      const userEmail = userProfile?.attributes?.email;
      const mobileNo = userDetails?.phoneNumber;
      const quantity = 1
      HandleAddToCartToLocal(productList?.[0], result, userId, userEmail, mobileNo, quantity, setLocalCartData, setDataSuccess, null, setCartPageItem)

    }
  }, [productList, productDetailList])

  useEffect(() => {
    if (dataSuccess === "success") {
      setProductResponse(null)
      setProductDetailResponse(null)
      setProductList([])
      setProductDetailList([])
      setAddTocart(false);
      setDataSuccess(null)
    }
  }, [dataSuccess])

  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={false}
        swipeable={true}
        showDots
        customDot={<CustomDot />}
      >
        {wishList?.map((item, index) => {
          const outStock = productsItem?.filter(data => data?.id === item?.productId)
          const productData = productDetails?.filter(data => data?.productID === item?.productId)

          const stockItem = productData?.filter(product =>
            outStock?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
              (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
          );


          const isDisabled = !outStock?.[0]?.allowPreOrder && (
            outStock?.[0]?.showWhenZeroStock && outStock?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS || 
            outStock?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS ||
            stockItem?.length === 0
        ) ? Status?.STOCKSTATUSOUT : ''

          const HandleImageClick = (e) => {
            e.preventDefault();
            navigate(`/product-detail/${item.productId}`, {
              state: {
                values: {
                  subproduct_id: item.productId,
                  mainCategoryname: item.mainCategory,
                  subcategoryName: item.subCategory,
                  mainCategoryId: item.mainCategoryId,
                  subCategoryId: item.subCategoryId,
                  search: item.productId,
                },
              },
            });
            window.scroll(0, 0);
          };

          const price = outStock[0]?.attrValue
          ?.map((item) => {
            const matchingDetail = stockItem.find(
              (data) => data.attrValueID === item.attrValueID
            );
            return matchingDetail;
          })
          .filter((detail) => detail !== undefined);
    
        const checked = price ? price : stockItem ? stockItem : productData
        const priceCondition = price?.[0] ? price?.[0] : stockItem[0] ? stockItem[0] : productData[0]
    
        const lowestPriceDetail = checked.reduce((lowest, current) => {
          const currentSalePrice = current?.salePrice || current?.regularPrice;
          const lowestSalePrice = lowest?.salePrice || lowest?.regularPrice;
    
          return currentSalePrice < lowestSalePrice ? current : lowest;
        }, priceCondition);
    
        const wishlist = lowestPriceDetail ? lowestPriceDetail : stockItem[0] ? stockItem[0] : productData[0] ? productData[0] : item

          return (
            <div key={index} className='carousel__item'>
              <div className='image-box' onClick={HandleImageClick} >
                <img width="100%" src={outStock[0]?.featuredImage?.url ? outStock[0]?.featuredImage?.url : item?.productImage} alt="product" disabled={isDisabled} style={isDisabled ? { opacity: 0.3 } : { cursor: "pointer" }} />
              </div>
              <p className="out-stock" style={{ color: '#CA0015', fontWeight: 600 }}>{isDisabled ? Status?.STOCKSTATUSOUT : ''}</p>
              <div className='carousel__caption'>
                <p className='title' onClick={HandleImageClick}><ProductToolTip productName={outStock[0]?.productName ? outStock[0]?.productName : item?.productName}><span>{outStock[0]?.productName ? outStock[0]?.productName : item?.productName}</span></ProductToolTip></p>
                <p className='model'>
                  <span style={{ fontWeight: "600" }}>Brand: </span>
                  <CTooltip content={outStock[0]?.brandName ? outStock[0]?.brandName : item?.brandName} placement="bottom">
                    <span>{outStock[0]?.brandName ? outStock[0]?.brandName : item?.brandName}</span>
                  </CTooltip>
                </p>
                <ProductRating productid={item?.productId} products={products} />
                <h4 className='price'>{logoUrl?.currency} {FormatValueDecimal(wishlist?.salePrice)}</h4>
                <div className='my-2' disabled={isDisabled} style={isDisabled ? { opacity: 0.5, cursor: 'not-allowed' } : { cursor: 'pointer' }}>
                  <button type="button" className='btn cart-btn' onClick={(e) => WishAddToCart(e, item)} disabled={isDisabled} style={isDisabled ? { opacity: 0.5, cursor: 'not-allowed' } : { cursor: 'pointer' }}>Move to Cart</button>
                </div>
                <a className='delete-btn' href="#" onClick={(e) => DeleteWishList(e, item)}>Delete from Wishlist</a>
              </div>
            </div>
          )
        })}
      </Carousel>
    </>
  );
}

export default WishlistCarousel