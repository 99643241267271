import React, { useState, useEffect } from 'react';
import { Col, Container, Card, CardBody, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateFormatShow } from "../../utils/validate"
import OrderUpdates from "../../common/orderUpdates";
import ReturnUpdates from "../../common/returnUpdates";
import CancelOrder from "../../common/cancelOrder";
import CancelReturn from "../../common/cancelReturn";
import { Amplify, Storage } from 'aws-amplify';
import { getDecimalValue, checkValue, PriceFormat } from "../../utils/validate"
import { v4 as uuid } from 'uuid';
import useProfile from "../../hooks/useProfile";
import ValidateField from '../../utils/useValid';
import { FieldNameConstant, ValidateMessages, ValidateName } from '../../constants/validateMsgConstant'
import { AddProductReview, UpdateOrderItem, UpdateProductReview, GetProductList, GetProductDetailList, ProductBuyNow, OrderTabStatus } from '../../utils/generalMethod';
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService"
import useCart from "../../hooks/useCartPage";
import awsmobile from "../../aws-exports"
import ProductRating from "../../components/productRating";
import { resourceconstant, Status } from "../../constants";
import { GetData } from "../../providers/queryData";
import useLogoImage from '../../hooks/useLogoImage';
import {CheckNull } from "../../utils/validate";

Amplify.configure(awsmobile)

const defaultRatingForm = {
    pk: "REVP#",
    orderItemId: "",
    productId: "",
    productImage: "",
    userName: "",
    email: "",
    overallRating: "",
    title: "",
    reviewComment: "",
    attachments: [],
    createdAt: new Date(),
    reviewStatus: "Open",
    userId: ""
}
export default function OrderHistory({ filterOrderItemDetail, setFilterOrderItemDetail, showOrderDetail, orderHistoryDetail, BackTab, GetOrdersDetails }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useProfile()
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [orderItem, setOrderItem] = useState(null);
    const [orderDetail, setOrderDetail] = useState(null);
    const [orderProducts, setOrderProducts] = useState(null);
    const [showProductReview, setShowProductReview] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [returnModalShow, setReturnModalShow] = useState(false)
    const [cancelOrderDetail, setCancelOrderDetail] = useState({})
    const [cancelShow, setCancelShow] = useState(false);
    const [cancelReturn, setCancelReturn] = useState({ show: false, orderItem: null });
    const [file, setFile] = useState({ fileobj: "", fileurl: "" });
    const [reviewResponse, setReviewResponse] = useState(null);
    const [updateReviewResponse, setUpdateReviewResponse] = useState(null);
    const [ratingForm, setRatingForm] = useState(defaultRatingForm)
    const ALLOWED_FILE_TYPES = ['application/pdf'];
    const [ratingError, setRatingError] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [dataSuccess, setDataSuccess] = useState(null)
    const [rating, setRating] = useState([]);
    const [ratingList, setRatingList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [errorFile, setErrorFile] = useState("")
    const [productDetailList, setProductDetailList] = useState([])
    const [productList, setProductList] = useState([])
    const [addToCart, setAddTocart] = useState(false)
    const [productResponse, setProductResponse] = useState(null);
    const [productDetailResponse, setProductDetailResponse] = useState(null);
    const [isDifferentAddress, setIsDifferentAddress] = useState(false);
    const [buyNowData, setBuyNowData] = useState(null);
    const [disabledBuyAgain, setDisabledBuyAgain] = useState(false);
    useEffect(() => {
        setOrderItem(orderHistoryDetail?.orderItem);
        setOrderDetail(orderHistoryDetail?.orderDetail)
        setOrderProducts(orderHistoryDetail?.orderProducts);
        setRatingForm({
            ...ratingForm,
            id: uuid(),
            orderItemId: orderHistoryDetail?.orderItem?.id,
            productId: orderHistoryDetail?.orderItem?.productId,
            productImage: orderHistoryDetail?.orderItem?.itemDetail?.image,
            userName: userProfile?.attributes['custom:firstName'] + ' ' + userProfile?.attributes['custom:lastName'],
            userId: userProfile?.attributes?.sub,
            email: userProfile?.attributes?.email,
            orderedDate: orderHistoryDetail?.orderDetail?.createdAt,
            reviewedDate: new Date(),
            productName: orderHistoryDetail?.orderItem?.itemDetail?.productName,
            searchKey: ((orderHistoryDetail?.orderItem?.itemDetail?.productName ?? '') + ' - ' + (orderHistoryDetail?.orderItem?.attrValue ?? '')).replace(/\s+/g, '').toLowerCase() + '#' + (userProfile?.attributes['custom:firstName'] + ' ' + userProfile?.attributes['custom:lastName'] + '#' + userProfile?.attributes?.email).replace(/\s+/g, '').toLowerCase() + '#' + orderHistoryDetail?.orderDetail?.id        })

        const { shippingAddress, billingAddress } = orderHistoryDetail?.orderDetail

        if (shippingAddress.addressLine1 !== billingAddress.addressLine1 ||
            shippingAddress.addressLine2 !== billingAddress.addressLine2 ||
            shippingAddress.street !== billingAddress.street ||
            shippingAddress.city !== billingAddress.city ||
            shippingAddress.state !== billingAddress.state ||
            shippingAddress.country !== billingAddress.country ||
            shippingAddress.postalCode !== billingAddress.postalCode)
            setIsDifferentAddress(true)
    }, [orderHistoryDetail])

    const handleShowOrderUpdates = () => {
        setModalShow(true)
    }

    const handleShowReturnUpdates = () => {
        setReturnModalShow(true)
    }

    const handleCancelOrder = (event, orderItem) => {
        setCancelShow(true)
        setCancelOrderDetail(orderItem)
    }

    const uploadToS3 = async (fileParam) => {
        const renamedFileName = `${Date.now()}_${fileParam.name}`;
        await Storage.put(renamedFileName, fileParam, { contentType: fileParam?.type }).then(resp => {
            const fileUploadedUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_user_files_s3_bucket_region}.amazonaws.com/public/${resp?.key}`;

            setRatingForm({ ...ratingForm, attachments: [...ratingForm?.attachments, fileUploadedUrl] })
            setLoading(false);
        }).catch(error => {
            setErrorFile("File Upload Fail! Please try again")
            setLoading(false);
        })
    }

    const RemoveFile = async (event, fileParam) => {
        event?.preventDefault();
        await Storage.remove(fileParam).then(resp => {
            setRatingForm({ ...ratingForm, attachments: ratingForm.attachments?.filter(item => item !== fileParam) })
        }).catch(error => { console.error("error", error) })
    }

    const handleFileSelect = (e) => {
        setLoading(true)
        setErrorFile("");
        const url = URL.createObjectURL(e.target.files[0]);
        setFile({ fileobj: e.target.files[0], fileurl: url });
        uploadToS3(e.target.files[0])
    }

    const handleChangeRate = (rate) => {
        setRatingForm({ ...ratingForm, overallRating: rate })

        const errors = validation({
            ...ratingForm,
            overallRating: rate,
        });
        setRatingError({
            ...ratingError,
            rating: errors["rating"]
        });
    }

    const HandleFormChange = (e) => {
        const { name, value } = e.target

        setRatingForm({
            ...ratingForm,
            [name]: value,
        });

        const errors = validation({
            ...ratingForm,
            [name]: value,
        });
        setRatingError({
            ...ratingError,
            [name]: errors[name]
        });
    }

    const HandleSaveRating = (e) => {
        e.preventDefault();
        setRatingError(validation(ratingForm));
        setIsSubmit(true);
    }

    const validation = (values) => {
        const errors = {};

        errors.rating = ValidateField(
            values.overallRating,
            FieldNameConstant?.RATING,
            [ValidateName?.REQUIRED],
        );

        errors.title = ValidateField(
            values.title,
            FieldNameConstant?.TITLE,
            [ValidateName?.REQUIRED],
        );

        errors.reviewComment = ValidateField(
            values.reviewComment,
            FieldNameConstant?.DESCRIPTION,
            [ValidateName?.REQUIRED],
        );

        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })

        return error
    }

    useEffect(() => {
        if (Object.keys(ratingError).length === 0 && isSubmit) {
            if (ratingForm?.edit === true) {
                delete ratingForm?.edit;
                UpdateProductReview(ratingForm, setUpdateReviewResponse)
            }
            else {
                AddProductReview(ratingForm, setReviewResponse)
            }
        }

    }, [ratingError])

    const HandleCancelReview = () => {
        if (showProductReview === true) {
            setRatingForm({
                ...ratingForm, overallRating: "",
                title: "",
                reviewComment: "",
                attachments: [],
            })
            setIsSubmit(false)
            setRatingError({})
        }
        setShowProductReview(false)
    }

    useEffect(() => {
        if (updateReviewResponse?.severity === "success") {
            showSuccessToastr("Review updated successfully.")
            setUpdateReviewResponse(null)
            setRatingForm({
                ...ratingForm, overallRating: "",
                title: "",
                reviewComment: "",
                attachments: [],
                edit: false,
                id: ''
            })
            setShowProductReview(false)
            setIsSubmit(false)
            setRatingList([updateReviewResponse?.data?.data?.updateProductReview])
        }

        if (updateReviewResponse?.severity === "error") {
            showErrorToastr("Failed to sent")
            setUpdateReviewResponse(null)
            setRatingForm({
                ...ratingForm, overallRating: "",
                title: "",
                reviewComment: "",
                attachments: [],
                edit: false,
                id: ''
            })
            setShowProductReview(false)
            setIsSubmit(false)
        }
    }, [updateReviewResponse])

    useEffect(() => {
        if (reviewResponse?.severity === "success") {
            showSuccessToastr("Review sent successfully.")
            setReviewResponse(null)
            setRatingForm({
                ...ratingForm, overallRating: "",
                title: "",
                reviewComment: "",
                attachments: [],
                id: ''
            })
            setShowProductReview(false)
            setIsSubmit(false)
            setRatingList([reviewResponse?.data?.data?.createProductReview])
        }

        if (reviewResponse?.severity === "error") {
            showErrorToastr("Failed to sent")
            setReviewResponse(null)
            setRatingForm({
                ...ratingForm, overallRating: "",
                title: "",
                reviewComment: "",
                attachments: [],
                id: ''
            })
            setShowProductReview(false)
            setIsSubmit(false)
        }
    }, [reviewResponse])

    const HandleReturnProduct = (event, orderItem) => {
        event.preventDefault();
        navigate('/return-product', {
            state: {
                orderItem: orderItem,
                orderDetail: orderDetail
            }
        })
    }

    const HandleCancelReturn = (event, orderItem) => {
        event.preventDefault();
        setCancelReturn({
            show: true,
            orderItem: orderItem
        })
    }

    const ProductRatings = async () => {
        let filter = {};
        GetData(resourceconstant.PRODUCT_RATING_LIST, filter, setRating);
    };

    useEffect(() => {
        ProductRatings();
    }, [orderItem]);

    useEffect(() => {
        if (rating?.severity === "success") {
            setRatingList(rating?.data?.data?.listProductReviews?.items?.filter(item => item?.productId === orderItem?.productId && item?.orderItemId === orderItem?.id && item?.email === userProfile?.attributes?.email));
        }
    }, [rating]);

    const HandleEditReview = () => {
        setRatingForm({
            pk: ratingList?.[0]?.pk,
            id: ratingList?.[0]?.id,
            orderItemId: ratingList?.[0]?.orderItemId,
            productId: ratingList?.[0]?.productId,
            productImage: ratingList?.[0]?.productImage,
            userName: ratingList?.[0]?.userName,
            email: ratingList?.[0]?.email,
            userId: userProfile?.attributes.sub,
            overallRating: ratingList?.[0]?.overallRating,
            title: ratingList?.[0]?.title,
            reviewComment: ratingList?.[0]?.reviewComment,
            attachments: ratingList?.[0]?.attachments,
            createdAt: new Date(ratingList?.[0]?.createdAt),
            edit: true,
            reviewStatus: "Open",
            orderedDate: ratingList?.[0]?.orderedDate,
            reviewedDate: new Date(),
            productName: ratingList?.[0]?.productName,
            searchKey: ratingList?.[0]?.searchKey
        })
        setShowProductReview(true)
    }

    const renderStars = (ratingData) => {
        return (
            <>
                <div className='d-flex'>
                    <i className='fa fa-star' style={{ color: ratingData?.[0]?.overallRating * 1 > 0 ? "#fdd835" : "#e5e7ea" }}></i>
                    <i className='fa fa-star' style={{ color: ratingData?.[0]?.overallRating * 1 > 1 ? "#fdd835" : "#e5e7ea" }}></i>
                    <i className='fa fa-star' style={{ color: ratingData?.[0]?.overallRating * 1 > 2 ? "#fdd835" : "#e5e7ea" }}></i>
                    <i className='fa fa-star' style={{ color: ratingData?.[0]?.overallRating * 1 > 3 ? "#fdd835" : "#e5e7ea" }}></i>
                    <i className='fa fa-star' style={{ color: ratingData?.[0]?.overallRating * 1 > 4 ? "#fdd835" : "#e5e7ea" }}></i>
                </div>
            </>
        );
    };

    const HandleBuyAgain = (event, item) => {
        event.preventDefault();
        setDisabledBuyAgain(true)
        let productFilter = {
            id: {
                eq: `${orderItem?.productId}`,
            },
            status: {
                eq: "published"
            }
        }
        GetProductList(productFilter, setProductResponse)
    }

    useEffect(() => {
        if (productResponse?.severity === "success") {
            setProductList(productResponse?.data?.data?.listProducts?.items);
            if (productResponse?.data?.data?.listProducts?.items?.length <= 0) {
                showErrorToastr(ValidateMessages?.OUT_OF_STOCK)
                setDisabledBuyAgain(false)
            }
            else {
                let productDetailFilter = {
                    productID: {
                        eq: `${orderItem?.productId}`,
                    },
                }
                GetProductDetailList(productDetailFilter, setProductDetailResponse)
            }
        }
        if (productResponse?.severity === "error") {
            showErrorToastr(ValidateMessages?.OUT_OF_STOCK)
            setDisabledBuyAgain(false)
        }
    }, [productResponse])

    useEffect(() => {
        if (productDetailResponse?.severity === "success") {
            const data = productDetailResponse?.data?.data?.listProductDetails?.items
            
            const filteredProductsDetails = data?.filter(product =>
                productList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
            );
            const productData = data?.filter(data => data?.productID === orderItem?.productId && data.attrValueID === orderItem?.attrValueID)
            const product = filteredProductsDetails.find(data =>
                data.attrValueID === orderItem?.attrValueID
            )
            const result = product ? product : productData[0];

            if (filteredProductsDetails?.filter((item) => item?.productID === orderItem?.productId && (item.attrValueID === orderItem?.attrValueID || !item.attrValueID === !orderItem?.attrValueID))?.length <= 0) {
                showErrorToastr(ValidateMessages?.OUT_OF_STOCK)
                setDisabledBuyAgain(false);
            }
            else if (result?.stockStatus === Status?.OUTOFSTOCKSTATUS && !productList[0]?.allowPreOrder) {
                showErrorToastr(ValidateMessages?.OUT_OF_STOCK)
                setDisabledBuyAgain(false);
            }
            else {
                setProductDetailList(filteredProductsDetails?.filter((item) => item?.productID === orderItem?.productId && (item.attrValueID === orderItem?.attrValueID || !item.attrValueID === !orderItem?.attrValueID)));
            }
        }
        if (productDetailResponse?.severity === "error") {
            showErrorToastr(ValidateMessages?.OUT_OF_STOCK)
            setDisabledBuyAgain(false)
        }
    }, [productDetailResponse])

    useEffect(() => {
        if (addToCart === false && productList?.length > 0 && productDetailList?.length > 0) {
            const filteredProductsDetails = productDetailList?.filter(product =>
                productList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
            );
            const product = filteredProductsDetails.find(data =>
                data.attrValueID === orderItem?.attrValueID
            )

            const result = product ? product : filteredProductsDetails[0];

            if (productList[0]?.allowPreOrder) {
                setAddTocart(true);
                HandleBuyNow();
                return;
            } else if (result?.stockQuantity - result?.reservedStock === 0) {
                showErrorToastr(ValidateMessages?.OUT_OF_STOCK);
                setDisabledBuyAgain(false);
                return;
            }
            setAddTocart(true);
            HandleBuyNow();
        }
    }, [productList, productDetailList])

    useEffect(() => {
        if (buyNowData?.severity === "success") {
            setProductResponse(null)
            setProductDetailResponse(null)
            setProductList([])
            setProductDetailList([])
            setAddTocart(false);
            setDisabledBuyAgain(false);
            localStorage.setItem('checkoutDirect', true)
            localStorage.setItem('buyNow', buyNowData)
            navigate("/checkout", {
                state: {
                    checkoutData: buyNowData,
                },
            });
        }
    }, [buyNowData]);

    const HandleBuyNow = async () => {
        const userId = userProfile?.attributes?.sub;
        const productId = productList[0]?.id;
        const price = orderItem?.regularPrice
        const salesPrice = orderItem?.itemDetail?.price
        const product = productDetailList.find(data =>
            data.attrValueID === orderItem?.attrValueID
        )
        const result = product ? product : productDetailList[0];

        const tax = productList[0]?.taxDetail?.reduce(
            (total, item) => total + (item?.taxCharge * result?.salePrice / 100),
            0
        );

        let data = {
            productID: productId,
            userId: userId,
            quantity: 1,
            price: result?.regularPrice,
            salesPrice: result?.salePrice,
            attrName: result?.attrName ? result?.attrName : '',
            attrValueID: result?.attrValueID ? result?.attrValueID : '',
            attrValue: result?.attrValue ? result?.attrValue : '',
            taxCharge: tax ? `${tax}` : '0',
            productImage: productList[0]?.thumbnailUrl
                ? productList[0]?.thumbnailUrl
                : productList[0]?.featuredImage?.url,
            model: result?.model,
            sku: result?.sku,
            mainCategory: productList[0]?.mainCategory,
            subCategoryN: productList[0]?.subCategory,
            stockUnit: result?.stockUnit,
            itemsPerUnit: result?.itemsPerUnit,
            currentStock: result?.stockQuantity - result?.reservedStock,
            mainCategoryId: productList[0]?.mainCategoryID,
            subCategoryId: productList[0]?.subCategoryID,
            wareHouseName: result?.storageInfo?.wareHouse,
            wareHouseId: result?.storageInfo?.wareHouseId,
        }
        ProductBuyNow(data, true, setBuyNowData)
    }

    useEffect(() => {
        if (dataSuccess === "success") {
            setProductResponse(null)
            setProductDetailResponse(null)
            setProductList([])
            setProductDetailList([])
            setAddTocart(false);
            setDataSuccess(null)
        }
    }, [dataSuccess])

    const createdAt = orderDetail?.createdAt;
    
    if (!createdAt) {
        return false;
    }
    
    const now = new Date();
    const createdAtDate = new Date(createdAt);

    const daysDifference = Math.floor((now - createdAtDate) / (1000 * 60 * 60 * 24));
    const daysDifferenceMath = Math.floor((now - createdAtDate) / (1000 * 60 * 60 * 24)) + 1;

    return (
        <>
            <section id="order-history" className='mt-3'>
                <Container>
                    <Card className='mt-3 mb-1'>
                        <CardBody>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="bread-crumbs">
                                        <nav>
                                            <a className="breadcrumb-not-active" style={{ cursor: "pointer" }} onClick={BackTab}>
                                                Orders
                                            </a>
                                            <span className="breadcrumb-arrow">/</span>
                                            <a className="breadcrumb-active">{orderItem?.pk?.replace("ORDERI#", "")}</a>
                                        </nav>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='justify-content-between'>
                                <Col md={4}>
                                    <h2 className='sub-title mb-3'>Billing Address</h2>
                                    <div className='user-address'>
                                        <h4>{orderDetail?.billingUserDetails?.userName}</h4>
                                        <br />
                                        <p>
                                            {orderDetail?.billingAddress?.addressLine1 && `${orderDetail.billingAddress.addressLine1}, `}
                                            {orderDetail?.billingAddress?.addressLine2 && `${orderDetail.billingAddress.addressLine2}, `}
                                            {orderDetail?.billingAddress?.street && `${orderDetail.billingAddress.street}`} <br />
                                            {orderDetail?.billingAddress?.city && `${orderDetail.billingAddress.city}, `}
                                            {orderDetail?.billingAddress?.state && `${orderDetail.billingAddress.state}`} <br />
                                            {orderDetail?.billingAddress?.country && `${orderDetail.billingAddress.country}`}
                                            {orderDetail?.billingAddress?.postalCode && ` - ${orderDetail.billingAddress.postalCode}`}
                                        </p>
                                        <p><span>Contact Number :{orderDetail?.billingUserDetails?.phoneNumber}</span></p>
                                    </div>
                                </Col>
                                {orderDetail?.deliveryInfo?.deliveryType === "Doorstep" ? isDifferentAddress ? <Col md={4}>
                                    <h2 className='sub-title mb-3'>Shipping Address</h2>
                                    <div className='user-address'>
                                        <h4>{orderDetail?.shippingUserDetails?.userName}</h4>
                                        <br />
                                        <p>
                                            {orderDetail?.shippingAddress?.addressLine1 && `${orderDetail.shippingAddress.addressLine1}, `}
                                            {orderDetail?.shippingAddress?.addressLine2 && `${orderDetail.shippingAddress.addressLine2}, `}
                                            {orderDetail?.shippingAddress?.street && `${orderDetail.shippingAddress.street}`} <br />
                                            {orderDetail?.shippingAddress?.city && `${orderDetail.shippingAddress.city}, `}
                                            {orderDetail?.shippingAddress?.state && `${orderDetail.shippingAddress.state}`} <br />
                                            {orderDetail?.shippingAddress?.country && `${orderDetail.shippingAddress.country}`}
                                            {CheckNull(orderDetail?.shippingAddress?.postalCode) !=="" && ` - ${orderDetail.shippingAddress.postalCode}`}
                                        </p>
                                        <p><span>Contact Number :{orderDetail?.shippingUserDetails?.phoneNumber}</span></p>
                                    </div>
                                </Col> :
                                    <Col md={4}>
                                        <h2 className='sub-title mb-3'>Shipping Address</h2>
                                        <div className='user-address'>
                                            <p>Same as Billing address</p>
                                        </div>
                                    </Col> : ""}
                                <Col md={4}>
                                    <h2 className='sub-title mb-3'>Order Summary</h2>
                                    <div className='table-responsive'>
                                        <table className='table table-borderless mb-0'>
                                            <tbody>
                                                <tr>
                                                    <th>Product Total</th>
                                                    <td className='text-end'>{logoUrl?.currency} {PriceFormat(orderItem?.itemDetail?.price * orderItem?.quantity)}</td>
                                                </tr>
                                                {orderItem?.taxAmount > 0 && (<tr>
                                                    <th>Tax</th>
                                                    <td className='text-end'>{logoUrl?.currency} {PriceFormat(orderItem?.taxAmount * orderItem?.quantity)}</td>
                                                </tr>)}
                                                <tr>
                                                    <th className='total'>Total</th>
                                                    <th className='text-end total'>{logoUrl?.currency} {PriceFormat((getDecimalValue(orderItem?.totalPrice)))}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className='mb-1 mt-2'>
                        <CardBody>
                            <div className='product-table mb-2'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='product-list'>
                                                    <div className='pdt-img me-3'>
                                                        <img src={orderItem?.itemDetail?.image} className='img-fluid' />
                                                    </div>
                                                    <div className='pdt-detail'>
                                                        <h6>{orderItem?.itemDetail?.productName} {orderItem?.attrValue && "-"} {orderItem?.attrName} {orderItem?.attrValue}</h6>
                                                        <p className="pdt-model" style={{ color: "black", fontSize: "15px", fontWeight: "400", marginBottom: "4px" }}>
                                                            <span> Brand:</span> {checkValue(orderItem?.itemDetail?.brand) && orderItem?.itemDetail?.brand} {checkValue(orderItem?.itemDetail?.model) && (<><span> | </span> <span>Model:</span> {orderItem?.itemDetail?.model}</>)} {checkValue(orderItem?.itemDetail?.sku) && (<><span>|</span> <span> SKU: </span>{orderItem?.itemDetail?.sku}</>)}                                                          </p>
                                                        <p className='qty-price' style={{ color: "black", fontSize: "15px", fontWeight: "400", marginBottom: "4px" }}><span>Unit Price: </span>{logoUrl?.currency} {PriceFormat(orderItem?.itemDetail?.price)} <span>|</span> <span>Qty: </span> {orderItem?.quantity}</p>
                                                        <p className='delivery-type'> <span>Delivery Type: </span>{orderDetail?.deliveryInfo?.deliveryType}</p>
                                                        {orderItem?.deliveryStatus === "Delivered" &&
                                                            <p className='delivery-type'><span>Delivered on: </span>{DateFormatShow((orderItem?.deliveryTracking?.filter((item) => item.status === "Delivered")?.[0]?.date), true)}</p>}
                                                        <p>Status: {orderItem?.returnRefundActivity?.status ? OrderTabStatus("return", orderItem?.returnRefundActivity?.status, orderDetail?.deliveryInfo?.deliveryType) : OrderTabStatus("order", orderItem?.deliveryStatus, orderDetail?.deliveryInfo?.deliveryType)}</p>
                                                        {orderItem?.returnRefundActivity?.status === "RefundRejected" && (
                                                            <p className='delivery-type' style={{ whiteSpace: 'normal' }}><span>Reason: </span>{orderItem?.returnRefundActivity?.refundInfo?.rejectedReason}</p>
                                                        )}
                                                        <div className="star-rating-container">
                                                            <div className='d-flex align-items-center'>
                                                                {ratingList?.length > 0 && renderStars(ratingList)}
                                                                {ratingList?.length > 0 && ratingList?.[0]?.removeAbusiveComment !== true && <i className="fa fa-pencil"
                                                                    onClick={HandleEditReview}> </i>}
                                                                {ratingList?.length > 0 && ratingList?.[0]?.removeAbusiveComment === true &&
                                                                    <span className="form-error">Content removed from the display due to violation</span>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td align='right'>
                                                <div className='d-flex flex-column'>
                                                    {orderItem?.deliveryStatus === "Delivered" && ratingList?.length <= 0 && <button type='button' className='btn return-btn mb-2' onClick={() => setShowProductReview(true)}>Write a Review</button>}
                                                    {!orderItem?.returnRefundActivity && orderItem?.deliveryStatus === "Delivered" && (logoUrl?.daysToReturn > 0 ? daysDifferenceMath : daysDifference) <= logoUrl?.daysToReturn && <button type='button' className='btn return-btn mb-2' onClick={(event) => HandleReturnProduct(event, orderItem)}>Return Product</button>}
                                                    {orderItem?.returnRefundActivity?.status === "Requested" && <button type='button' className='btn return-btn mb-2' onClick={(event) => HandleCancelReturn(event, orderItem)}>Cancel Return</button>}
                                                    {(orderItem?.deliveryStatus === "Received" || orderItem?.deliveryStatus === "Processing") && <button type='button' className='btn return-btn mb-2' onClick={(event) => handleCancelOrder(event, orderItem)}>Cancel Product</button>}
                                                    {!orderItem?.returnRefundActivity && orderItem?.deliveryStatus !== "Delivered" && <button type='button' className='btn return-btn mb-2' onClick={handleShowOrderUpdates}>Track Order</button>}
                                                    {orderItem?.returnRefundActivity && orderItem?.returnRefundActivity?.status !== "RefundRejected" && <button type='button' className='btn return-btn mb-2' onClick={handleShowReturnUpdates}>Track Return</button>}
                                                    <button type='button' className='btn track-btn mb-2' onClick={HandleBuyAgain} disabled={disabledBuyAgain}>Buy Again</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                    {showProductReview === true && (<Card className='mb-1 mt-2'>
                        <CardBody>
                            <h2 className='sub-title mb-1'><span><i className='fa fa-star me-2' style={{ color: "#fdd835", fontSize: '22px' }}></i></span> Rate this Product</h2>
                            <hr />
                            <form className='w-100'>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div className="form-group mb-1">
                                            <h4 className="sub-title">Over all rating</h4>
                                            <div className='d-flex my-3'>
                                                <i className='fa fa-star me-3' style={{ color: ratingForm?.overallRating > 0 ? "#fdd835" : "#e5e7ea", fontSize: '23px' }} onClick={() => handleChangeRate(1)}></i>
                                                <i className='fa fa-star me-3' style={{ color: ratingForm?.overallRating > 1 ? "#fdd835" : "#e5e7ea", fontSize: '23px' }} onClick={() => handleChangeRate(2)}></i>
                                                <i className='fa fa-star me-3' style={{ color: ratingForm?.overallRating > 2 ? "#fdd835" : "#e5e7ea", fontSize: '23px' }} onClick={() => handleChangeRate(3)}></i>
                                                <i className='fa fa-star me-3' style={{ color: ratingForm?.overallRating > 3 ? "#fdd835" : "#e5e7ea", fontSize: '23px' }} onClick={() => handleChangeRate(4)}></i>
                                                <i className='fa fa-star me-3' style={{ color: ratingForm?.overallRating > 4 ? "#fdd835" : "#e5e7ea", fontSize: '23px' }} onClick={() => handleChangeRate(5)}></i>
                                            </div>
                                            {ratingError?.rating && <p className="form-error">{ratingError?.rating}</p>}
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="form-group mb-1">
                                            <h4 className="sub-title">Write a title</h4>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="form-group">
                                            <input type='text' name="title" value={ratingForm?.title} className='form-control' onChange={HandleFormChange} placeholder='Enter' />
                                            {ratingError?.title && <p className="form-error">{ratingError?.title}</p>}
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="form-group mb-1">
                                            <h4 className="sub-title">Description</h4>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="form-group">
                                            <textarea className='form-control' value={ratingForm?.reviewComment} onChange={HandleFormChange} name="reviewComment" rows={5} placeholder='Enter'></textarea>
                                            {ratingError?.reviewComment && <p className="form-error">{ratingError?.reviewComment}</p>}
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="form-group mb-1">
                                            <h4 className="sub-title">Add photos</h4>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="file-section mt-2">
                                            <div className="image-input">
                                                <input className="form-control" id="imageInput" type="file" name="imageInput" accept=".jpg, .jpeg, .pdf" value="" onChange={handleFileSelect} />
                                                <label for="imageInput" className="image-button contract" id="document-images"><span><i className='fa fa-plus'></i></span></label>
                                                {loading && <p className="form-error">...Loading</p>}
                                                {errorFile && <p className="form-error">{errorFile}</p>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12} className='mt-4'>
                                        {ratingForm?.attachments?.map((attachItem, index) => {
                                            if (attachItem == "") return
                                            return (
                                                <div className='image-container'>
                                                    {attachItem.split('.').pop().trim() === "pdf" ?
                                                        <object type="application/pdf" data={attachItem} width="100%" height="200" /> :
                                                        <img src={attachItem} width="100%" height="100" />}
                                                    <button className="close-button" onClick={(event) => RemoveFile(event, attachItem)}>✖</button>
                                                </div>
                                            )
                                        })}
                                    </Col>
                                    <Col md={12}>
                                        <div className='d-flex bottom-buttons aign-items-end justify-content-end'>
                                            <button type='button' className='btn cancel-btn mb-2 me-2' onClick={HandleCancelReview}>Cancel</button>
                                            <button type='button' className='btn confirm-btn mb-2 me-2' onClick={HandleSaveRating}>Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>)}
                </Container>
            </section >
            <OrderUpdates orderDetail={orderDetail} modalShow={modalShow} setModalShow={setModalShow} orderItem={orderItem} />
            <ReturnUpdates returnModalShow={returnModalShow} setReturnModalShow={setReturnModalShow} orderItem={orderItem} orderDetail={orderDetail} />
            <CancelOrder showOrderDetail={showOrderDetail} setCancelShow={setCancelShow} cancelShow={cancelShow} cancelOrderDetail={cancelOrderDetail} GetOrdersDetails={GetOrdersDetails} />
            <CancelReturn filterOrderItemDetail={filterOrderItemDetail} setFilterOrderItemDetail={setFilterOrderItemDetail} cancelReturn={cancelReturn} setCancelReturn={setCancelReturn} orderDetail={orderDetail} setOrderItem={setOrderItem} GetOrdersDetails={GetOrdersDetails} />
        </>
    )
}