import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Footer from "../../../pages/footer";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { CreateEntry, PostData } from "../../../providers/queryData";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import { AccountFieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import { isRequired } from "../../../utils/validate";
import useProfile from "../../../hooks/useProfile";
import useUserDetails from '../../../hooks/useUserDetails'
import { UpdateUserAddress } from "../../../utils/generalMethod";
import { ToastrMessages } from "../../../constants/toastrMessages";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
  phoneNo: '',
}
const ChangeMobile = ({ setPhoneOtp, setPhoneChange }) => {
  const navigate = useNavigate();
  const [sentMail, setSentMail] = useState(null)
  const [values, setValues] = useState(formValues)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [currentCountryCode, setCurrentCountryCode] = useState('');
  const [userProfile, setUserProfile] = useProfile()
  const userid = userProfile?.attributes?.sub;
  const [useuserDetails, setUseUserDetails] = useUserDetails();
  const [logoUrl, setLogoUrl] = useLogoImage();

  const HandleChangePhone = (value, name) => {
    const item = value && formatPhoneNumber(value)
    setCurrentCountryCode(item)
    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validation = (values) => {
    const errors = {};
      errors.phoneNo = ValidateField(
          values.phoneNo,
          AccountFieldNameConstant?.PHONE_NUMBER,
          [ValidateName?.REQUIRED, ValidateName?.ISVALID_PHONE],
      );

    let error = {};
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined) error[key] = errors[key];
    });
    return error
  }

  const SendPhoneOtp = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
  }

  useEffect(() => {
    const HandlePhone = async () => {
      try {      
        const data = {
          pk: useuserDetails?.pk,
          id: useuserDetails?.id,
          phoneNumber: values.phoneNo,
          status: useuserDetails?.status,
          role: useuserDetails?.role
        };

        PostData(resourceconstant?.UPDATE_USER_ADDRESS, data, setSentMail);
        showSuccessToastr(ToastrMessages?.USERPHONENO)
        navigate('/profile')
      } catch (error) {
        showErrorToastr(error.message);
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      HandlePhone();
    }
  }, [errors]);

  return (
    <>
      <div className="login-page profile-mobile">
        <Card>
          <Row>
            <Col md={12}>
              <div className="login-sec">
                <div className="login-form" style={{ maxWidth: "650px" }}>
                  <form>
                    <Row>
                      <Col md={12} className="mt-3">
                        <div className="logo-sec d-flex gap-2 align-items-center">
                          <Link to="/profile" style={{ color: "inherit" }}>
                            <div class="back-button">
                              <i className="fa fa-chevron-left"></i>
                            </div>
                          </Link>
                          <h1 className="mb-0 w-100">Change Mobile Number?</h1>
                        </div>
                      </Col>
                      <Col sm={12} md={12} className="mt-3">
                        <p>
                          Update your mobile number to ensure secure access
                          and receive important notifications. Enter your new
                          number for uninterrupted service.
                        </p>
                      </Col>
                      <Col sm={12} md={12}>
                        <div className="form-group mt-3">
                          <label>
                            Mobile Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <PhoneInput
                            defaultCountry="QA"
                            international
                            initialValueFormat="national"
                            id='phoneNo'
                            name="phoneNo"
                            onChange={(value) => HandleChangePhone(value, 'phoneNo')}
                            countryCallingCodeEditable={false}
                            class="form-control"
                            placeholder="Mobile Number"
                            enableSearch
                            disableSearchIcon
                            menuPlacement="bottom"
                          />
                        </div>
                        {
                          errors?.phoneNo && <p className="form-error">{errors?.phoneNo}</p>
                        }
                      </Col>
                      <Col md={12}>
                        <button
                          className="btn submit-btn"
                          onClick={(e) => SendPhoneOtp(e)}
                        >
                          Continue
                        </button>
                      </Col>

                      <Col md={12}>
                        <p className="text-center">
                          By continuing, you agree to {logoUrl?.companyName}’s{" "}
                          <Link
                            to="/terms-condition"
                            state={{ activeKey: "terms" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Conditions of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Privacy Policy
                          </Link>
                          .
                        </p>

                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default ChangeMobile;