import React from 'react';
import Lottie from "react-lottie";
import { Link } from 'react-router-dom';
import animationData from './Animation - 1717648897878.json';

const CartNotFound = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    return (
        <div className='not-found'>
            <Lottie options={defaultOptions} width={400} height={400} />
            <Link to="/all-products">
                <button>Explore more Product</button>
            </Link>
        </div>
    )
};

export default CartNotFound;
