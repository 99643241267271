import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from "../context/UserDetailsContext";
import Amplify, { Auth } from 'aws-amplify';
import { GetUserDetails } from '../utils/generalMethod';

function useUserDetails() {

  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    const GetUserProfileDetails = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser?.attributes?.sub) {
          let data = {
            id: { eq: `${currentUser?.attributes?.sub}` }
          }
          GetUserDetails(data, setUserInfo)
        }
      } catch (error) {
        setUserDetails({});
      }
    };
    GetUserProfileDetails();
  }, [])

  useEffect(() => {
    if (userInfo?.data?.data?.listUsers?.items[0]) {
        setUserDetails(userInfo?.data?.data?.listUsers?.items[0])
    }
  }, [userInfo])

  return [userDetails, setUserDetails];
}

export default useUserDetails;