import React, { useEffect, useState, useRef } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import AddressIcon from "../../assets/images/icons/addaddress.png";
import { UpdateUserAddress, GetUserDetails } from "../../utils/generalMethod";
import { resourceconstant } from "../../constants";
import {
  FieldNameConstant,
  ValidateName,
  ValidateMessages
} from "../../constants/validateMsgConstant";
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
import ValidateField from "../../utils/useValid";
import useProfile from "../../hooks/useProfile";
import useCountry from "../../hooks/useCountry";
import { DropDownComponent } from "../../utils/dropDownComponet";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import useChangeLocation from "../../hooks/useChangeLocation";
import Amplify, { Auth } from 'aws-amplify';
import { GetData } from '../../providers/queryData';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { CheckNull } from "../../utils/validate";
import AddressDisplay from "../../common/addressDisplay";
import MapView from "./MapView";

const autoCompleteStyle = {
  height: "34px",
  border: "1px solid #eae8e8",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
  boxShadow: "none",
  hoverBackgroundColor: "#445568",
  color: "black",
  fontSize: "14px",
  fontWeight: "500",
  iconColor: "#445568",
  lineColor: "#445568",
  placeholderColor: "#445568",
  clearIconMargin: "3px 8px 0 0",
  zIndex: 2,
}
const defaultValues = {
  id: 0,
  name: "",
  phoneNumber: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    street: "",
    landmark: "",
    state: "",
    city: "",
    postalCode: "",
    country: "",
  },
  addressName: "",
  type: "",
  default: false,
};
export default function AddressTab() {
  const [userProfile, setUserProfile] = useProfile();
  const [countryList, setCountryList] = useCountry();
  const [changeLocation, setChangeLocation] = useChangeLocation();
  const [address, setAddress] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [addressResponse, setAddressResponse] = useState(null);
  const [removedResponse, setRemovedResponse] = useState(null);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [addresstile, showAddressTile] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState([]);
  const [shippingDetails, setShippingDetails] = useState([])
  const [shippingList, setShippingList] = useState([])
  const [country, setCountry] = useState(null);
  const [addressType, setAddressType] = useState(null)
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [showMapView, setShowMapView] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const stateRef = useRef();
  const cityRef = useRef();
  const addressTypeList = [{ label: "Home", value: "HOME" }, { label: "Office", value: "OFFICE" }, { label: "Others", value: "OTHERS" }]

  useEffect(() => {
    const GetUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (!currentUser) navigate('/login')
      }
      catch {
        navigate('/login')
      }
    }
    const DeliveryData = () => {
      let filter = {
      }
      GetData(resourceconstant.DELIVERY_FEE, filter, setShippingDetails)
    };
    GetUser()
    DeliveryData();
  }, [])

  useEffect(() => {
    if (shippingDetails?.severity === "success") {
      const shippingData = shippingDetails?.data?.data?.listShippings?.items || [];
      setShippingList(shippingData)
    }
  }, [shippingDetails]);

  useEffect(() => {
    if (addressResponse?.severity === "success") {
      formValues?.id > 0
        ? showSuccessToastr("Address updated successfully.")
        : showSuccessToastr("Address added successfully.");
      setAddressDetails(
        addressResponse?.data?.data?.updateUser?.addressDetails?.map(
          (element, index) => {
            return { ...element, id: index + 1 };
          }
        )
      );
      setChangeLocation(addressResponse?.data?.data?.updateUser?.addressDetails)
      setIsSubmit(false);
      showAddressTile(true);
      setAddress(false);
      setShowMapView(false);
      setAddressResponse(null);

      const address = location?.state?.newAddress;
      if (address) {
        navigate("/checkout", {
          state: {
            checkoutData: location?.state?.checkoutData
          }
        });
      }
    }
    if (addressResponse?.severity === "error") {
      formValues?.id > 0
        ? showErrorToastr("Failed to update address")
        : showErrorToastr("Failed to add address");
      setIsSubmit(false);
      setAddressResponse(null);
      setAddress(false);
      setShowMapView(false);
      addressDetails?.length > 0 ? showAddressTile(true) : showAddressTile(false)
      navigate(window.location.pathname, { replace: true });
    }
  }, [addressResponse]);

  useEffect(() => {
    if (userProfile?.attributes?.sub) {
      let data = {
        id: { eq: `${userProfile?.attributes?.sub}` },
      };
      GetUserDetails(data, setUserDetails);
    }
  }, [userProfile?.attributes?.sub]);

  useEffect(() => {
    if (userDetails?.data?.data?.listUsers?.items[0]) {
      let usersData = userDetails?.data?.data?.listUsers?.items[0]
      let defaultCheck = usersData?.addressDetails?.filter((defaultItem) => defaultItem.default === true)

      setAddressDetails(
        usersData?.addressDetails?.map(
          (element, index) => {
            if (defaultCheck?.length <= 0 && index === 0)
              return { ...element, default: true, id: index + 1 };
            else
              return { ...element, id: index + 1 };
          }
        )
      );
    }
  }, [userDetails]);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      let addressDetailsCopy =
        addressDetails?.length > 0 ? [...addressDetails] : [];
      let updateAddressDetail = addressDetails?.filter(
        (filterItem) => filterItem?.id !== formValues.id
      );
      let updateIndex = addressDetails?.findIndex(
        (filterItem) => filterItem?.id === formValues.id
      );
      updateAddressDetail?.forEach((item, index) => {
        delete item?.id;
        delete item?.__typename;
        delete item?.address?.__typename;
      });

      let formValuescopy = { ...formValues };
      formValuescopy = trimStringValues(formValuescopy);

      delete formValuescopy?.id;
      delete formValuescopy?.__typename;
      delete formValuescopy?.address?.__typename;
      updateAddressDetail?.map((item, index) => {
        if (formValuescopy?.default === true) {
          item.default = false;
        }
        return item;
      });
      if (updateIndex > -1) addressDetailsCopy[updateIndex] = formValuescopy;
      let data = {
        id: userProfile?.attributes?.sub,
        pk: "USER#",
        addressDetails:
          updateIndex > -1
            ? addressDetailsCopy
            : updateAddressDetail?.length > 0
              ? [...updateAddressDetail, formValuescopy]
              : [formValuescopy],
      };

      const location = data?.addressDetails?.filter((item) => item?.default === true)

      if (location?.length <= 0) {
        data?.addressDetails?.map((item, index) => {
          if (index == 0) item.default = true;
          return item;
        });
      }
      UpdateUserAddress(data, setAddressResponse);
    }
  }, [formError]);

  const trimStringValues = (obj) => {
    const trimmedObject = { ...obj };

    Object.entries(trimmedObject).forEach(([key, value]) => {
      if (typeof value === 'string') {
        trimmedObject[key] = value.trim();
      } else if (typeof value === 'object' && value !== null) {
        trimmedObject[key] = trimStringValues(value);
      }
    });

    return trimmedObject;
  };

  const ChooseAddressFromMap = (address) => {
    let strDisplay = "";
    if (address?.address?.house_number) strDisplay = strDisplay + address?.address?.house_number + ","
    if (address?.address?.village || address?.address?.road || address?.address?.industrial)
      strDisplay = strDisplay + (address?.address?.village || address?.address?.road || address?.address?.industrial)

    setFormValues({
      ...formValues,
      latitude: address?.lat,
      longitude: address?.lon,
      address: {
        addressLine1: strDisplay,
        addressLine2: address?.address?.city && address?.address?.city,
        street: (address?.address?.state_district || address?.address?.district || address?.address?.town) && (address?.address?.state_district || address?.address?.district || address?.address?.town),
        landmark: "",
        state: address?.address?.state && address?.address?.state,
        city: (address?.address?.county || address?.address?.municipality) && (address?.address?.county || address?.address?.municipality),
        postalCode: address?.address?.postcode && address?.address?.postcode,
        country: address?.address?.country && address?.address?.country,
      },
    })
    setCountry({ label: address?.address?.country, value: address?.address?.country });
    showAddressTile(false);
    setAddress(true);
    setShowMapView(false);
  }

  useEffect(() => {
    if (addressDetails?.length > 0) showAddressTile(true);
    else showAddressTile(false);
  }, [addressDetails]);

  useEffect(() => {
    if (removedResponse?.severity === "success") {
      showSuccessToastr("Address removed successfully.");
      setAddressDetails(
        removedResponse?.data?.data?.updateUser?.addressDetails?.map(
          (element, index) => {
            return { ...element, id: index + 1 };
          }
        )
      );
      setChangeLocation(removedResponse?.data?.data?.updateUser?.addressDetails)
      setRemovedResponse(null);
    }
    if (removedResponse?.severity === "error") {
      showErrorToastr("Failed to remove address");
      setRemovedResponse(null);
    }
  }, [removedResponse]);

  useEffect(() => {
    // re-run the validation check on the changed field value
    const errors = validation({
      ...formValues,
      address: {
        ...formValues?.address,
        "country": country?.value,
      },
    });
    // update the formErrors state
    setFormError({
      ...formError,
      "country": errors[country],
    });

    let stateList = shippingList?.filter((item) => item?.countryName === country?.value)
    let cityList = shippingList?.filter((item) => item?.countryName === country?.value)
    const uniqueStateItems = stateList.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.state === item.state)
    );

    const uniqueCityItems = cityList.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.city === item.city)
    );
    setStateList(uniqueStateItems || [])
    setCityList(uniqueCityItems || [])
  }, [country]);

  useEffect(() => {
    let cityList = []
    if (CheckNull(formValues?.address?.state) === "")
      cityList = shippingList?.filter((item) => item?.countryName === country?.value)
    else
      cityList = shippingList?.filter((item) => item?.countryName === country?.value && item?.state === formValues?.address?.state)

    const uniqueCityItems = cityList.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.city === item.city)
    );
    setCityList(uniqueCityItems || [])
  }, [formValues?.address?.state])

  const HandleFormChange = (e, address) => {
    const { name, value, checked } = e.target;

    if (address === false) {
      setFormValues({
        ...formValues,
        [name]: e.target.type === "checkbox" ? checked : value,
      });

      // re-run the validation check on the changed field value
      const errors = validation({
        ...formValues,
        [name]: value,
      });
      // update the formErrors state
      setFormError({
        ...formError,
        [name]: errors[name],
      });

    }
    if (address === true) {
      UpdateForm(name, value)
    }
  };

  const HandleCancelAddress = () => {
    setFormValues(defaultValues);
    addressDetails?.length > 0 ? showAddressTile(true) : showAddressTile(false)
    setFormError({});
    setAddress(false);
    setShowMapView(false);
    setIsSubmit(false);
  };

  const HandleSaveAddress = (e) => {
    e.preventDefault();
    setFormError(validation(formValues));
    setIsSubmit(true);
  };

  const validation = (values) => {
    const errors = {};
    errors.name = ValidateField(values?.name?.trim(), FieldNameConstant?.NAME, [
      ValidateName?.REQUIRED,
      ValidateName?.ALL_CHARACTER,
      ValidateName?.MAXIMUM_LENGTH
    ]);

    errors.phoneNumber = ValidateField(
      values.phoneNumber,
      FieldNameConstant?.PHONE_NUMBER,
      [ValidateName?.REQUIRED, ValidateName?.ISVALID_PHONE]
    );

    errors.email = ValidateField(values.email, FieldNameConstant?.EMAIL, [
      ValidateName?.REQUIRED,
      ValidateName?.EMAIL,
    ]);

    errors.addressLine1 = ValidateField(
      values?.address?.addressLine1,
      FieldNameConstant?.HOUSER_NUMBER,
      [ValidateName?.REQUIRED, ValidateName?.MAXIMUM_LENGTH]
    );
    errors.street = ValidateField(
      values?.address?.street,
      FieldNameConstant?.AREA,
      [ValidateName?.REQUIRED, ValidateName?.MAXIMUM_LENGTH]
    );

    errors.city = ValidateField(
      values?.address?.city,
      FieldNameConstant?.CITY,
      [ValidateName?.REQUIRED, ValidateName?.ISTEXT_ONLY, ValidateName?.MAXIMUM_LENGTH]
    );

    errors.state = ValidateField(
      values?.address?.state,
      FieldNameConstant?.STATE,
      [ValidateName?.ISTEXT_ONLY, ValidateName?.MAXIMUM_LENGTH]
    );

    errors.country = ValidateField(
      values?.address?.country,
      FieldNameConstant?.COUNTRY,
      [ValidateName?.REQUIRED]
    );

    errors.postalCode = ValidateField(
      values?.address?.postalCode,
      FieldNameConstant?.POSTALCODE,
      [ValidateName?.ISNUMBERONLY]
    );

    errors.landmark = ValidateField(
      values?.address?.landmark,
      FieldNameConstant?.LANDMARK,
      [ValidateName?.MAXIMUM_LENGTH]
    );
 
    let updateAddressDetail = addressDetails?.filter(
      (filterItem) => filterItem?.id !== formValues.id
    );

    if (updateAddressDetail?.some(item => item?.address?.addressLine1 === formValues?.address?.addressLine1)) {
      errors.duplicate = ValidateMessages?.DUBLICATE_ADDRESS;
    }

    let error = {};
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined) error[key] = errors[key];
    });

    return error;
  };

  useEffect(() => {
    setFormValues({ ...formValues, type: addressType?.value })
  }, [addressType])

  useEffect(() => {
    if (stateRef.current) {
      stateRef.current.handleOnBlur();
    }

    if (cityRef.current) {
      cityRef.current.handleOnBlur();
    }
  }, [formValues])

  const EditAddress = (item) => {
    showAddressTile(false);
    setAddress(true);
    setShowMapView(false);
    setFormValues(item);

    let value = countryList?.filter(
      (country) => country?.name === item?.address?.country
    );
    if (value?.length > 0)
      setCountry({ label: value[0].name, value: value[0]?.name });

    if (CheckNull(item?.type) !== "")
      setAddressType({ label: capitalizeFirstLetter(item?.type), value: item?.type });
    else setAddressType(null)
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };


  const RemoveAddress = (item) => {
    let delAddressDetails = addressDetails?.filter(
      (delItem) => delItem?.id !== item?.id
    );
    delAddressDetails?.map((item, index) => {
      return delete item?.id;
    });
    delAddressDetails?.map((item, index) => {
      return delete item?.__typename;
    });
    delAddressDetails?.map((item, index) => {
      return delete item?.address?.__typename;
    });
    let data = {
      id: userProfile?.attributes?.sub,
      pk: "USER#",
      addressDetails: delAddressDetails,
    };
    const location = data?.addressDetails?.filter((item) => item?.default === true)

    if (location?.length <= 0) {
      data?.addressDetails?.map((item, index) => {
        if (index == 0) item.default = true;
        return item;
      });
    }
    UpdateUserAddress(data, setRemovedResponse);
  };

  const AddAddress = () => {
    let usersData = userDetails?.data?.data?.listUsers?.items[0]
    setFormValues({ ...defaultValues, email: usersData?.email, phoneNumber: usersData?.phoneNumber })
    setCountry(null);
    setAddressType(null);
    setAddress(true);
    setShowMapView(false);
    showAddressTile(false);
    setFormError({});
    setIsSubmit(false);
  };

  const HandleChangePhone = (value, name) => {
    setFormError({
      ...formError,
      [name]: "",
    });
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const HandleBlur = (e) => {
    const { name, value } = e.target;

    const fieldErrors = validation({
      ...formValues,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: fieldErrors[name],
    });
  };

  const [hoveredId, setHoveredId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const handleOnSearch = (string, result, name) => {

    UpdateForm(name, string)
  }

  const handleOnSelect = (e, name) => {
    setFormValues({
      ...formValues,
      address: { ...formValues.address, city: "" },
    });
    UpdateForm(name, e?.state)
  }

  const handleOnSelectCity = (e, name) => {
    UpdateForm(name, e?.city)
  }

  const UpdateForm = (name, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      address: {
        ...prevFormValues.address,
        [name]: value,
      },
    }));

    // re-run the validation check on the changed field value
    const errors = validation({
      ...formValues,
      address: {
        ...formValues?.address,
        [name]: value,
      },
    });
    // update the formErrors state
    setFormError({
      ...formError,
      [name]: errors[name],
    });
  }

  const ShowMapView = () => {
    setShowMapView(true)
    setAddress(false);
    showAddressTile(false);
  }

  const HandleCountryChange = (e) => {
    setFormValues({
      ...formValues,
      address: { ...formValues.address, country: e?.value, state: "", city: "" },
    });
    setCountry(e)
  }

  return (<>
    {!showMapView && (<section id="account" className='my-1'>
      <Container>
        <Row className='mt-1'>
          <Col md={12} className='address-section'>
            <Tab.Container>
              <Card className='mx-0 mx-md-5'>
                <Nav className="flex-column">
                  <CardHeader>
                    <Nav.Item><Nav.Link><h2>Manage Address</h2></Nav.Link></Nav.Item>
                  </CardHeader>
                  <CardBody>
                    <div className="manage-address">
                      <div className="d-flex justify-content-end align-items-center mb-2">
                        {addresstile && (
                          <button
                            type="button"
                            className="btn shop-btn w-auto"
                            onClick={AddAddress}
                          >
                            <i className="fa fa-plus"></i>Add Address
                          </button>
                        )}
                      </div>
                      {!address && !addresstile && !showMapView && (
                        <div className="save-address">
                          <img src={AddressIcon} className="img-fluid mb-2" />
                          <h2>Save Your Addresses Now</h2>
                          <p>Add your addresses and enjoy faster checkout</p>
                          <button
                            type="button"
                            className="btn address-btn mt-3"
                            onClick={() => AddAddress()}
                          >
                            <i className="fa fa-plus"></i>Add Address
                          </button>
                        </div>
                      )}
                      {address && !addresstile && (
                        <div className="address-form">
                          {/* <Link to="/mapview" >Choose Address</Link> */}
                          <form className="w-100" autoComplete="off">
                            <Row>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <div className="form-group mb-1">
                                  <h4 className="sub-title">Contact Details</h4>
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="text-end">
                                <button className="btn choose-address" type='button' onClick={() => ShowMapView()}>Choose Address</button>
                              </Col>
                              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Company Name / Full Name <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="name"
                                    value={formValues?.name}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, false)}
                                  />
                                  {formError?.name && (
                                    <p className="form-error">{formError?.name}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Mobile Number <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <PhoneInput
                                    defaultCountry="QA"
                                    international
                                    id="phoneNo"
                                    name="phoneNo"
                                    onBlur={(e) => HandleBlur(e)}
                                    onChange={(value) =>
                                      HandleChangePhone(value, "phoneNumber")
                                    }
                                    class="form-control"
                                    value={formValues?.phoneNumber}
                                    placeholder="Mobile Number"
                                    enableSearch
                                    disableSearchIcon
                                  />
                                  {formError?.phoneNumber && (
                                    <p className="form-error">{formError?.phoneNumber}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="pe-0">
                                <div className="form-group">
                                  <label>
                                    Email Address <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="email"
                                    value={formValues?.email}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, false)}
                                  />
                                  {formError?.email && (
                                    <p className="form-error">{formError?.email}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="form-group mb-1">
                                  <h4 className="sub-title">Delivery Address</h4>
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Address Type
                                  </label>
                                  <DropDownComponent
                                    placeHolder="Select address Type"
                                    pageName="addaddress"
                                    dpDownName="type"
                                    stateData={addressTypeList}
                                    controlName="type"
                                    handleChange={(e) => setAddressType(e)}
                                    value={addressType}
                                  />
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Building/house number{" "}
                                    <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="addressLine1"
                                    value={formValues?.address?.addressLine1}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, true)}
                                  />
                                  {(formError?.addressLine1 || formError?.duplicate) && (
                                    <p className="form-error">{formError?.addressLine1 || formError?.duplicate}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Apartment/Suite/Unit Number{" "}
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="addressLine2"
                                    value={formValues?.address?.addressLine2}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, true)}
                                  />
                                </div>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Street/Area{" "}
                                    <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="street"
                                    value={formValues?.address?.street}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, true)}
                                  />
                                  {formError?.street && (
                                    <p className="form-error">{formError?.street}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Country <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <DropDownComponent
                                    placeHolder="Select country"
                                    pageName="addaddress"
                                    dpDownName="country"
                                    stateData={countryList}
                                    controlName="country"
                                    handleChange={(e) => HandleCountryChange(e)}
                                    value={country}
                                  />
                                  {formError?.country && (
                                    <p className="form-error">{formError?.country}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                <div class="form-group">
                                  <label>
                                    State
                                  </label>
                                  <ReactSearchAutocomplete
                                    ref={stateRef}
                                    items={stateList}
                                    fuseOptions={{ keys: ["state"] }} // Search on both fields
                                    resultStringKeyName="state" // String to display in the results
                                    onSearch={(string, result) => handleOnSearch(string, result, "state")}
                                    onSelect={(e) => handleOnSelect(e, "state")}
                                    showIcon={false}
                                    inputSearchString={formValues?.address?.state || ""}
                                    styling={autoCompleteStyle}
                                    autoComplet="off"
                                    placeholder="Search"
                                    showClear={false}
                                  />
                                  {formError?.state && (
                                    <p className="form-error">{formError?.state}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    City <span style={{ color: "#CA0015" }}>*</span>
                                  </label>
                                  <ReactSearchAutocomplete
                                    ref={cityRef}
                                    items={cityList}
                                    fuseOptions={{ keys: ["city"] }} // Search on both fields
                                    resultStringKeyName="city" // String to display in the results
                                    onSearch={(string, result) => handleOnSearch(string, result, "city")}
                                    onSelect={(e) => handleOnSelectCity(e, "city")}
                                    showIcon={false}
                                    inputSearchString={formValues?.address?.city || ""}
                                    styling={autoCompleteStyle}
                                    autoComplet="off"
                                    showClear={false}
                                    placeholder="Search"
                                  />
                                  {formError?.city && (
                                    <p className="form-error">{formError?.city}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Postal Code
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="postalCode"
                                    value={formValues?.address?.postalCode}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, true)}
                                  />
                                  {(formError?.postalCode) && (
                                    <p className="form-error">{formError?.postalCode}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label>
                                    Land Mark{" "}
                                  </label>
                                  <input
                                    class="form-control"
                                    autoComplete="off"
                                    name="landmark"
                                    value={formValues?.address?.landmark}
                                    type="text"
                                    placeholder="Enter"
                                    onChange={(e) => HandleFormChange(e, true)}
                                  />
                                   {formError?.landmark && (
                                    <p className="form-error">{formError?.landmark}</p>
                                  )}
                                </div>
                              </Col>
                              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="my-auto">
                                <div class="form-check">
                                  <input class="form-check-input" name="default"
                                    checked={formValues?.default}
                                    type="checkbox"
                                    onChange={(e) => HandleFormChange(e, false)} id="defaultAddressChecked" />
                                  <label class="form-check-label" for="defaultAddressChecked">
                                    Default Address{" "}
                                  </label>
                                </div>
                              </Col>
                              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="d-flex justify-content-end mt-3">
                                <button
                                  className="btn shop-btn"
                                  onClick={HandleSaveAddress}
                                >
                                  {formValues?.id ? "Update Address" : "Save Address"}
                                </button>
                                <button
                                  className="btn cancel-btn"
                                  onClick={HandleCancelAddress}
                                >
                                  Cancel
                                </button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      )}
                      {addresstile && (
                        <div className="address-list">
                          {addressDetails?.map((item, index) => {
                            return (

                              <div className="address-tile">
                                <div className="user-detail" key={item.id} onMouseEnter={() => handleMouseEnter(item.id)} onMouseLeave={handleMouseLeave}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h2>{item?.name}</h2>
                                    {hoveredId === item.id && (
                                      <div className="user-actions">
                                        <i className="fa fa-edit" style={{ pointer: "cursor" }} onClick={() => EditAddress(item)}></i>
                                        <i className="fa fa-trash" style={{ pointer: "cursor" }} onClick={() => {
                                          RemoveAddress(item);
                                        }}></i>
                                      </div>
                                    )}
                                  </div>
                                  <AddressDisplay data={item} />

                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Nav>
              </Card>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </section>)}
    {showMapView && <MapView setShowMapView={setShowMapView} setAddress={setAddress} ChooseAddressFromMap={ChooseAddressFromMap} />}
  </>
  );
}
