import React, { useState, useEffect,useRef } from "react";
import useProfile from "../../../hooks/useProfile";
import { CreateGeneralContactUs} from "../../../utils/generalMethod";
import { showSuccessToastr,showErrorToastr } from "../../../common/toastrService";
import ContactForm from "./contactForm";
import { v4 as uuid } from "uuid";
import { contactValidation } from "./contactValidation";
import {Amplify, Storage } from 'aws-amplify';
import { ValidateMessages } from "../../../constants/validateMsgConstant";
import { resourceconstant} from "../../../constants"
import { GetData, PostData } from "../../../providers/queryData"
import awsmobile from "../../../aws-exports"
import useLogoImage from "../../../hooks/useLogoImage";
import ReactGA from 'react-ga4';
Amplify.configure(awsmobile)

export default function Contacts() {
  //AWSDateTime
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split(".")[0] + "Z";
  const captchaRef = useRef();

  const defaultValues = {
    pk: "GEN#",
    id: uuid(),
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    subject: "",
    referenceNumber: null,
    message: "",
    searchKey: "",
    createdAt: formattedCurrentDate,
    updatedAt: formattedCurrentDate,
    enquiryStatus: "Open",
    attachments: [],
    captcha: "",
  };

  const userProfile = useProfile();
  const [formValues, setFormValues] = useState(defaultValues);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [messageResponse, setMessageResponse] = useState(null);
  const [file, setFile] = useState({ fileobj: "", fileurl: "" });
  const [captchaVerified, setCaptchaVerified] = useState("");
  const [listConfigData, setListConfigData] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [contactRefNo, setContactRefNo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorFile, setErrorFile] = useState("")
  const [logoUrl, setLogoUrl] = useLogoImage();

  const updateSearchKey = (name, email, id, subject, referenceNumber) => {
    const formattedName = name.replace(/\s+/g, "").toLowerCase();
    return `#${formattedName}#${email}#${id}#${subject}#${referenceNumber}`;
  };

  const HandleFormChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = {
      ...formValues,
      [name]: value,
    };
    if (name === "userName" || name === "email" || name === "id" || name === "subject" || name === "referenceNumber") {
      updatedValues.searchKey = updateSearchKey(
        updatedValues.userName,
        updatedValues.email,
        updatedValues.id,
        updatedValues.subject,
        updatedValues.referenceNumber

      );
    }
    setFormValues(updatedValues);

    const errors = contactValidation(updatedValues, userProfile,captchaVerified);
    setFormError({
      ...formError,
      [name]: errors[name],
    });
  };

  const HandleBlur = (e) => {
    const { name, value } = e.target;

    const fieldErrors = contactValidation({
      ...formValues,
      [name]: value,
    },userProfile,captchaVerified);
    setFormError({
      ...formError,
      [name]: fieldErrors[name],
    });
  };

  const HandleChangePhone = (value) => {
    setFormValues({
      ...formValues,
      phoneNumber: value,
    });
  };

  const HandleSendMessage = (e, platform) => {
    e.preventDefault();
    setFormError(contactValidation(formValues, userProfile, captchaVerified));
    setIsSubmit(true);
    ReactGA.event({
      category: 'Contact Us',
      action: 'Click',
      label: platform,
  });
  };

  const resetForm = () => {
    setFormValues({ ...defaultValues, phoneNumber: "" });
    setFormError({});
    setIsSubmit(false);
    setFile({ fileobj: "", fileurl: "" });
    setCaptchaVerified("");
  };

  const GetReferenceNo = () => {
    GetData(resourceconstant?.LIST_CONFIGURATION, {}, setListConfigData);
  }

  useEffect(() => {
    if (listConfigData?.severity === "success") {
      const refContact = listConfigData?.data?.data?.listConfigurations?.items?.filter((item) => item?.configurationDescription === "creating Ref ID for contact")
      if (refContact?.length > 0) {
        let data = {
          pk: "REF#",
          id: refContact[0]?.id,
          continuousSettings: {
            ...refContact[0]?.continuousSettings,
            runningNumber: (refContact[0]?.continuousSettings?.runningNumber * 1) + 1
          },
          modifiedTime: new Date(),
          modifiedBy: userProfile?.attributes?.email
        }
        delete data?.continuousSettings?.__typename;
        PostData(resourceconstant?.UPDATE_CONFIGURATION, data, setConfigData);
      }
      else {
        let data = {
          pk: "REF#",
          id: uuid(),
          configurationDescription: "creating Ref ID for contact",
          continuousSettings: {
            prefixValue: "CONREF",
            numberOfDigits: 4,
            includeYearSuffix: true,
            startingNumber: 1,
            runningNumber: 1
          },
          type: "continuous",
          modifiedTime: new Date(),
          modifiedBy: userProfile?.attributes?.email
        }
        PostData(resourceconstant?.CREATE_CONFIGURATION, data, setConfigData);
      }
    }
  }, [listConfigData])

  useEffect(() => {
    if (configData?.severity === "success") {
      let RefConfigData = configData?.data?.data?.createConfiguration
      if (configData?.data?.data?.updateConfiguration)
        RefConfigData = configData?.data?.data?.updateConfiguration
      let refNo = "CONREF" + (new Date()).getFullYear() + "0000" + RefConfigData?.continuousSettings?.runningNumber
      setContactRefNo(refNo)
      let formValuesDetails = { ...formValues, referenceNumber: refNo }
      const { captcha, ...formValuesCopy } = formValuesDetails
      CreateGeneralContactUs(formValuesCopy, setMessageResponse);
    }
  }, [configData])

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      GetReferenceNo()
    }
  }, [formError]);

  useEffect(() => {
    if (messageResponse?.severity === "success") {
      showSuccessToastr(ValidateMessages?.SUCCESS_MESSAGE + contactRefNo);
      resetForm();
      setConfigData(null)
      setListConfigData(null)
      setContactRefNo(null)
      reloadCaptcha();
    }
    if (messageResponse?.severity === "error") {
      showErrorToastr(ValidateMessages?.CONTACT_ERROR_MESSAGE);
      resetForm();
      setConfigData(null)
      setListConfigData(null)
      setContactRefNo(null)
      reloadCaptcha();
    }
  }, [messageResponse]);


  useEffect(() => {
    if (
      Array.isArray(userProfile) &&
      userProfile.some(obj => obj !== null && Object.keys(obj).length > 0)
    ) {
      const userNameFromStorage = localStorage.getItem("fullName");
      setFormValues(prevValues => {
        const { userName, phoneNumber, email } = prevValues;
        const newPhoneNumber = userProfile[0]?.attributes?.phone_number || "";
        const newEmail = userProfile[0]?.attributes?.email || "";
        if (
          userName !== (userNameFromStorage || "") ||
          phoneNumber !== newPhoneNumber ||
          email !== newEmail
        ) {
          return {
            ...prevValues,
            userName: userNameFromStorage || "",
            phoneNumber: newPhoneNumber,
            email: newEmail,
          };
        }
        return prevValues;
      });
    }
  }, [userProfile]);

  const uploadToS3 = async (fileParam) => {
    const renamedFileName = `${Date.now()}_${fileParam.name}`;
    await Storage.put(renamedFileName, fileParam, { contentType: fileParam?.type }).then(resp => {
      const fileUploadedUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_user_files_s3_bucket_region}.amazonaws.com/public/${resp?.key}`;

      setFormValues({ ...formValues, attachments: [...formValues?.attachments, fileUploadedUrl] })
      setLoading(false);
    }).catch(error => {
      setErrorFile("File Upload Fail! Please try again")
      setLoading(false);
    })
  }

  const RemoveFile = async (event, fileParam) => {
    event?.preventDefault();
    await Storage.remove(fileParam).then(resp => {
      setFormValues({ ...formValues, attachments: formValues.attachments?.filter(item => item !== fileParam) })
    }).catch(error => { console.error("error", error) })
  }

  const handleFileSelect = (e) => {
    setLoading(true)
    setErrorFile("");
    const url = URL.createObjectURL(e.target.files[0]);
    setFile({ fileobj: e.target.files[0], fileurl: url });
    uploadToS3(e.target.files[0])
  }
  const [captchaKey, setCaptchaKey] = useState("")
  const reloadCaptcha = () => {
    setCaptchaKey(Math.random());
  };
  

  return (
    <ContactForm
      file={file}
      userProfile={userProfile}
      formValues={formValues}
      formError={formError}
      HandleFormChange={HandleFormChange}
      HandleChangePhone={HandleChangePhone}
      HandleSendMessage={HandleSendMessage}
      handleFileSelect={handleFileSelect}
      setCaptchaVerified={setCaptchaVerified}
      RemoveFile={RemoveFile}
      loading={loading}
      errorFile={errorFile}
      captchaRef={captchaRef}
      captchaKey={captchaKey}
      HandleBlur={HandleBlur}
      logoUrl={logoUrl}
    />
  );
}
