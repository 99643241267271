import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import emailIcon from '../../assets/images/icons/email.png';
import mailIcon from "../../assets/images/icons/mail.png";
import linkIcon from '../../assets/images/icons/linkicon.png'
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import customLogger from '../../common/loggerService';
function ShareUserCart({ cartList, productName, currentUrl, setShareCartShow, shareCartShow }) {
  const handleClose = () => setShareCartShow(false);
  const handleShow = () => setShareCartShow(true);
  const url = currentUrl;
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    let inputc = document.body.appendChild(document.createElement("input"));
    inputc.value = url;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
  };

  const copyUrl = (event) => {
    event.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 2000);
      }).catch(err => {
        customLogger.error(err);
      });
    } else {
      const tempInput = document.createElement('input');
      tempInput.value = url;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  };

  return (
    <>
      <Modal show={shareCartShow} onHide={handleClose} animation={false} className='social-media'>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={8}>
              <h4>Share this cart to</h4>
            </Col>
          </Row>
          <Link to={`mailto:?subject=${encodeURIComponent(productName)}&body=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row className='mt-3'>
              <Col xs={3} md={2}>
                <img src={mailIcon} />
              </Col>
              <Col xs={9} md={9} className='d-flex align-items-center'>
                <p>Email</p>
              </Col>
            </Row>
          </Link>
          <Row className='mt-3'>
            <Col xs={12} md={8}>
              <h4>Click to copy link</h4>
            </Col>
          </Row>
          <Row className='mt-3' onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
            <Col xs={3} md={2}>
              <img src={linkIcon} alt="Link Icon" />
            </Col>
            <Col xs={9} md={9} className='d-flex align-items-center'>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="copied-tooltip">{showTooltip ? "Copied!" : ""}</Tooltip>}
                show={showTooltip}
              >
                <div className='copy-url' onClick={copyUrl}>
                  Click to copy link
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShareUserCart;
