import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AccountTab from './accountTab';
import ProfileTab from './profileTab';
import WishlistTab from './wishlistTab';
import OrdersTab from './ordersTab';
import AddressTab from './addressTab';
import Amplify, { Auth } from 'aws-amplify';

const Account = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        const GetUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                if (!currentUser) navigate('/login')
            }
            catch {
                navigate('/login')
            }
        }
        GetUser()
    }, [])

    useEffect(() => {
        setActiveKey(location?.state?.activeKey);
    }, [location?.state?.activeKey])

    useEffect(() => {
        if (activeKey !== undefined) localStorage.setItem("activeKey", activeKey)
    }, [activeKey])

    useEffect(() => {
        if (localStorage.getItem("activeKey"))
            setActiveKey(localStorage.getItem("activeKey"));
        else
            setActiveKey("account");
    }, [])
    return (
        <section id="account" className='my-1'>
            <Container>
                <Row className='mt-1'>
                    <Col md={12}>
                        <Tab.Container activeKey={activeKey}>
                            <Card className='mx-5'>
                                <Nav className="flex-column">
                                    <CardHeader>
                                        <Nav.Item><Nav.Link eventKey="account" onClick={() => setActiveKey("account")}><h2>Account</h2></Nav.Link></Nav.Item>
                                    </CardHeader>
                                    <CardBody>
                                        <ProfileTab />
                                    </CardBody>
                                </Nav>
                            </Card>
                        </Tab.Container>
                    </Col>
                </Row>

            </Container>
        </section>
    )
}

export default Account