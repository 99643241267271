import React, { useEffect, useState, useRef } from "react";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Card, CardBody, Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
const MapContainer = (props) => {
    const mapStyles = {
        width: '100%',
        height: '100%',
    };

    const lat = sessionStorage.getItem('latitude');
    const lng = sessionStorage.getItem('longitude');

    return (
        <section id="storeLocationSection">
            <container>
                <Row className="mb-3">
                    <Col md={12}>
                        <Card className='mt-1'>
                            <CardBody className='py-2'>
                                <i className="fa fa-chevron-left"></i>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={12} className="mt-1">
                        <Card>
                            <CardBody>
                                <Map
                                    google={props.google}
                                    zoom={10}
                                    style={mapStyles}
                                    className="map-container"
                                    initialCenter={{
                                        lat: lat ? lat : 37.7749, 
                                        lng: lng ? -lng : -122.4194
                                    }}>
                                    {lat && lng && (
                                        <Marker position={{ lat: lat, lng: -lng }} />
                                    )}
                                </Map>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </container>
        </section>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAFp4VTBGhPLTrcSXpHil5XO0L2HDsR1X4',
})(MapContainer);

