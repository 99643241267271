import React from "react";

const ProductRating = ({ productid, products }) => {
    const product = products?.find(p => p.id === productid);
    const overallRating = product ? product?.overallRating : 0;
    const noOfRating = !product?.noOfRating || product?.noOfRating === "" ? 0 : product?.noOfRating;

    const renderStars = () => {
        const fullStars = Math.floor(overallRating);
        const halfStar = overallRating - fullStars >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;

        return (
            <>
                {Array(fullStars).fill().map((_, i) => <i key={`full-${i}`} className='fa fa-star'></i>)}
                {halfStar ? <i key="half" className='fa fa-star-half-o'></i> : null}
                {Array(emptyStars).fill().map((_, i) => <i key={`empty-${i}`} className='fa fa-star-o'></i>)}
            </>
        );
    };

    return (
        <h3 className='rating'>
            {renderStars()} ({noOfRating})
        </h3>
    );
};

export default ProductRating;