import React, { useEffect } from 'react';
import Lottie from "react-lottie";
import animationData from './Animation - 1715598069126.json';

const NoContentFound = () =>{
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    return (
        <div className='not-found'>
            <Lottie options={defaultOptions} width={200} height={200} />
            <div className="n-found my-1">
                <div className='d-flex flex-column align-items-center'>
                    <p>No content found</p>
                </div>
            </div>
        </div>
    )
}

export default NoContentFound