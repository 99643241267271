import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Container, Row } from "react-bootstrap";
function AllProductsSkeleton() {
    return (

        <Col className="product-listing-col">
            <div className='card mb-1 border-0'>
                <div className='card-body'>
                    <div className='image-box'>
                        <Skeleton variant="rectangular" width="100%" height={158} />
                    </div>
                    <div className='product-details mt-2'>
                        <h2 className='mb-0'><Skeleton height={15} /></h2>
                        <p className='mb-0'>Brand : <Skeleton width={130} height={15} />
                        </p>
                        <h3 className='mb-0'><Skeleton height={15} /></h3>
                        <h4 className='mb-0'><Skeleton height={15} /></h4>
                    </div>
                </div>
            </div>
        </Col >

    )
}
export default AllProductsSkeleton