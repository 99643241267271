import React, { useState, useEffect } from 'react';
import useNotification from '../../hooks/useNotification';
import useProfile from '../../hooks/useProfile';
import { DeleteNotification, UpdateNotificationStatus } from '../../utils/generalMethod';
import { showErrorToastr } from '../../common/toastrService';
import OrderDelivery from '../../assets/images/icons/delivery.png';
import LockedIcon from '../../assets/images/icons/locked.png';
import CartNotify from '../../assets/images/icons/cartIcon.png';
import { useNavigate } from 'react-router-dom';
import OrderCancelled from '../../assets/images/icons/OrderCancelled.png';
import OrderConfirmed  from '../../assets/images/icons/OrderConfirmed.png';
import OrderDelivered  from '../../assets/images/icons/OrderDelivered.png';
import OrderProcessing from '../../assets/images/icons/OrderProcessing.png';
import OutforDelivery from '../../assets/images/icons/OutforDelivery.png';
import ReturnRejected from '../../assets/images/icons/ReturnRejected.png';
import ReturnItemReceived from '../../assets/images/icons/ReturnItemReceived.png';
import ReturnItemShipped from '../../assets/images/icons/ReturnItemShipped.png';
import ReturnRefundApproved from '../../assets/images/icons/ReturnRefundApproved.png';
import ReturnRefundCancelled from '../../assets/images/icons/ReturnRefundCancelled.png';
import ReadyforShipping from '../../assets/images/icons/ReadyforShipping.png';
import ReturnAccepted from '../../assets/images/icons/ReturnAccepted.png';
import OrderAcknowledgement from '../../assets/images/icons/OrderAcknowledgement.png'
import ReturnCancelled from '../../assets/images/icons/ReturnCancelled.png';
const Notificationwindow = () => {
    const [notifications, setNotifications] = useNotification();
    const userDetails = useProfile();
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState([]);
    const [currentNoti, setCurrentNoti] = useState(null);
    const [NotificationData, setNotificationData] = useState();
    const userid = userDetails[0]?.attributes?.sub;

    const handleNotificationClick = async (notification) => {
        if(notification.status !==1)
        {
            navigate('/orders', {state: {search: notification?.orderID}});
        }else{
        const currentDate = new Date();
        setCurrentNoti(notification)
        const formattedCurrentDate = currentDate.toISOString().split('.')[0] + 'Z';
        let data = {
            pk: `NOTI#${userid}`,
            id: notification?.id,
            notificationStatus: 2,
            updatedAt: formattedCurrentDate,
        };

        await UpdateNotificationStatus(data, setResponseData);
    }
    };

    useEffect(() => {
        if (responseData?.severity === "success") {
            const updatedNotifications = notifications?.map(notification =>
                notification.id === currentNoti?.id ? { ...notification, status: 2 } : notification
            );
            setNotifications(updatedNotifications);
           navigate('/orders', {state: {search: currentNoti?.orderID}});
            setCurrentNoti(null);
            setResponseData(null);
           
        }
        else if (responseData?.severity == "error") {
            showErrorToastr('Failed to update notification status');
            setCurrentNoti(null);
            setResponseData(null);
        }
    }, [responseData])

    const handleClearAllNotifications = async () => {
        for (const notification of notifications) {
            const success = await handleDeleteNotification(notification.id, "clearAll");
            if (success) {
                setNotifications([]);
            }
        }
    };

    const handleDeleteNotification = async (notificationId, fuctionName) => {
        try {
            const deletedNotification = await DeleteNotification(userid, notificationId, setNotificationData, fuctionName);

            if (deletedNotification) {
                setNotifications((prevNotifications) =>
                    prevNotifications.filter((notification) => notification.id !== notificationId)
                );
            }
        } catch (error) {
            showErrorToastr("Failed to delete notification");
        }
    };

    const getIcon = (item) => {
        switch (item?.title) {
            case 'Order Delivered':
                return <img src={OrderDelivered} alt="Order delivered icon" />;
            case 'Orderitem cancelled':
                return <img src={OrderCancelled} alt="Order cancelled icon" />;
            case 'Order Confirmed':
                return <img src={OrderConfirmed} alt="Order confirmed icon" />;
            case 'Order Processing':
                return <img src={OrderProcessing} alt="Order processing icon" />;
            case 'Out for Delivery':
                return <img src={OutforDelivery} alt="Out for delivery icon" />;
            case 'Return rejected':
                return <img src={ReturnRejected} alt="Return rejected icon" />;
            case 'Return Item Received':
                return <img src={ReturnItemReceived} alt="Return item received icon" />;
            case 'Refund approved':
                return <img src={ReturnRefundApproved} alt="Return refund approved icon" />;
            case 'Return Item Shipped':
                return <img src={ReturnItemShipped} alt="Return item shipped icon" />;
            case 'Refund rejected':
                return <img src={ReturnRefundCancelled} alt="Return refund cancelled icon" />;
            case 'Ready for Shipping':
                return <img src={ReadyforShipping} alt="Ready for shipping icon" />;
            case 'Return accepted':
                 return <img src={ReturnAccepted} alt="Return accept Icon" />;
            case 'Order Acknowledgement':
                return <img src={OrderAcknowledgement} alt="Order acknowledgement icon" />;
                case 'Return cancelled':
                    return <img src={ReturnCancelled} alt="Return cancelled icon" />;
            default:
                return null;
        }
    };

    const splitDate = (date) => {
        const [day, month, year] = date.split('/').map(Number);
        return { day, month, year };
    };

    const getToday = (date) => {
        const { day, month, year } = splitDate(date);
        const today = new Date();

        if (day === today.getDate() && month === (today.getMonth() + 1) && year === today.getFullYear()) {
            return 'Today';
        } else {
            const notificationDate = new Date(year, month - 1, day);
            return notificationDate.toLocaleDateString('en-GB');
        }
    };

    return {
        notifications,
        setNotifications,
        handleClearAllNotifications,
        getIcon,
        getToday,
        handleNotificationClick,
        handleDeleteNotification,
    };
};

export default Notificationwindow;
