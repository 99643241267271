import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Footer from "../../../pages/footer";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { AccountFieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { CreateEntry } from "../../../providers/queryData";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import useProfile from "../../../hooks/useProfile";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
  email: '',
}
const ChangeEmail = ({ setEmailChange, emailNewOtpChange, emailNewChange, emailOtpChange, setEmailOtpChange, setEmailNewChange, setEmailNewOtpChange }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState(formValues)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [oldMail, setOldMail] = useState(null)
  const [userProfile, setUserProfile] = useProfile()
  const userid = userProfile?.attributes?.sub;
  const [logoUrl, setLogoUrl] = useLogoImage();

  const HandleChange = (e) => {
    const { name, value } = e.target
    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  }

  const HandleBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validation({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: fieldErrors[name]
    });
  };

  const validation = (values) => {
    const errors = {};
    errors.email = ValidateField(
      values.email,
      AccountFieldNameConstant?.EMAIL,
      [ValidateName?.REQUIRED, ValidateName?.EMAIL],
    );

    let error = {}
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined)
        error[key] = errors[key]
    })
    return error
  }

  const ChangeEmail = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
  }

  useEffect(() => {
    const HandleEmail = async () => {
      try {
        const data = {
          user_id: userid,
          old_email: values?.email
        };

        await CreateEntry(resourceconstant?.PROFILE_MAIL_OTP, true, setOldMail, data)
      } catch (error) {
        if (error.response) {
          customLogger.error(error.response.data);
        }
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      HandleEmail();
    }
  }, [errors]);

  useEffect(() => {
    if (oldMail?.status === true) {
      localStorage.setItem('profileOldEmail', values.email)
      showSuccessToastr(oldMail?.message)
      navigate('/email-otp')
    }
    else if(oldMail?.status === false) {
      showErrorToastr(oldMail?.message);
    }
  }, [oldMail])

  return (
    <>
      <div className="login-page profile-email">
        <Card>
          <Row>
            <Col md={12}>
              <div className="login-sec">
                <div className="login-form" style={{ maxWidth: "650px" }}>
                  <form>
                    <Row>
                      <Col md={12} className="mt-3">
                        <Link to="/profile" style={{ color: "inherit" }} className="text-decoration-none">
                          <div class="back-btn">
                            <i className="fa fa-chevron-left"></i>
                          </div>
                        </Link>
                        <h1>Change Email?</h1>
                      </Col>
                      <Col sm={12} md={12}>
                        <p>
                          Enter your current email address to receive an OTP for updating your email. This ensures secure verification and account protection.
                        </p>
                      </Col>
                      <Col sm={12} md={12}>
                        <div className="form-group mt-3">
                          <label>
                            Current Email
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Enter"
                            name="email"
                            id="email"
                            onBlur={(e) => HandleBlur(e)}
                            onChange={(e) => HandleChange(e)} />
                        </div>
                        {
                          errors?.email && <p className="form-error">{errors?.email}</p>
                        }
                      </Col>
                      <Col md={12}>
                        <button
                          className="btn submit-btn"
                          onClick={(e) => ChangeEmail(e)}
                        >
                          Continue
                        </button>
                      </Col>

                      <Col md={12}>
                        <p className="text-center">
                          By continuing, you agree to {logoUrl?.companyName}’s{" "}
                          <Link
                            to="/terms-condition"
                            state={{ activeKey: "terms" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}>
                            Conditions of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Privacy Policy
                          </Link>
                          .
                        </p>

                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default ChangeEmail;