import { isValidPhoneNumber } from 'react-phone-number-input';
import { ValidateMessages } from '../constants/validateMsgConstant';
import moment from "moment";

function exists(obj) {
  return obj && obj !== undefined && obj !== null;
}
function isEmpty(obj) {
  return !exists(obj) || (obj instanceof Array ? obj.length === 0 : Object.keys(obj).length === 0);
}
const CheckNull = (value) => {
  if (value === null || typeof (value) === "undefined" || value === NaN || value === "")
    return ""
  else
    return value;
}
const isRequired = (value) => {
  const result = value != '' && typeof value === 'string' ? value.trim() : value;
  if (result == '' || result === null || typeof (result) === "undefined" || result === NaN)
    return false;
  else
    return true;
}
const isRequiredNo = (value) => {
  if (value === null || typeof (value) === "undefined" || value === NaN || value === "" || value === 0)
    return false;
  else
    return true;
}
const FormatValueDecimal = (value) => {
  const parsedValue = parseFloat(value).toFixed(2);
  // const roundedValue = Math.round(parsedValue);
  return parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const FormatSeperateValue = (value) => {
  let parsedValue = parseFloat(value);
  if (parsedValue % 1 === 0) {
    return parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    parsedValue = parsedValue.toFixed(2);
    const [integerPart, decimalPart] = parsedValue.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formattedIntegerPart}.${decimalPart}`;
  }
};

const FormatDecimalSeperateValue = (value) => {
  let parsedValue = parseFloat(value);
  parsedValue = parsedValue.toFixed(2);
  const [integerPart, decimalPart] = parsedValue.split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedIntegerPart}.${decimalPart}`;
};

const isValidEmail = (value) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}
const isValidPhone = (value) => {
  const regex = /^\+\d{1,3}\d{10}$/;
  return regex.test(value);
};
const isValidCountryPhone = (value) => {
  return isValidPhoneNumber(value)
}
const isAlphaNumeric = (value) => {
  const regex = /^[A-Za-z0-9]*$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}
const isValidString = (value) => {
  return typeof value === 'string' && !/\d/.test(value) && value.trim() !== "";
}
const isTextOnly = (value) => {
  // let regex = /^[A-Za-z]+$/
  let regex = /^[a-zA-Z]+(?:[\s. ]+[a-zA-Z]+)*$/
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}
const isValidPassword = (value) => {
  let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}
const isNumberOnly = (value) => {
  let regex = /^[0-9]*\.*\-?[0-9]+$/;
  const result = value != '' && typeof value === 'string' ? value.trim() : value;
  if (result == '') return false
  else if (result != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}
const isValidUrl = (value) => {
  let regex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
  return regex.test(value);
};
const isValidFileUploaded = (file) => {
  const validExtensions = ["png", "jpg"];
  const fileExtension = file?.type.split("/")[1];
  return validExtensions.includes(fileExtension);
};
const isValidFileSize = (file) => {
  const MAX_FILE_SIZE = 500000;
  if (file > MAX_FILE_SIZE) {
    return false;
  } else {
    return true;
  }
};
function isValidFaxNumber(values) {
  const regex = /^\+?[0-9]{6,}$/ // only allowed 9 digits and more
  return regex.test(values);
}
function diffdays(date1, date2) {
  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
}
const isValidDate = (value) => {
  if (value === null || typeof (value) === "undefined" || value === "")
    return false;
  else if (new Date(value) === "Invalid Date")
    return false;
  else
    return true;
}
const dateCheck = (value) => {
  if (value === null || typeof (value) === "undefined" || value === "")
    return new Date();
  else if (new Date(value) === "Invalid Date")
    return new Date();
  else
    return new Date(value);
}
function validateName(name) {
  const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/; // Regular expression to check if input contains only text
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression to check if input contains email format
  const urlRegex = /(?:https?:\/\/)?(?:www\.)?[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}/; // Regular expression to check if input contains a URL

  if (name.match(emailRegex)) {
    return ValidateMessages?.VALID_NAME_MSG;
  } else if (name.charAt(0).match(/\d/)) {
    return ValidateMessages?.START_WITH_NO;
  } else if (name.match(urlRegex)) {
    return ValidateMessages?.SHOULD_NOT_WEB_URL;
  } else if (!name.match(regex)) {
    return ValidateMessages?.ONLY_TEXT_ALLOWED;
  } else {
    return "";
  }
}
function convertLocalToUTCDate(date) {
  if (!date) return date
  date = new Date(date)
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}
//exclude negative numbers and zero,allow upto 4 decimal places
function isValidConversionRate(values) {
  const reg = /^(?!0(\.0*)?$)\d+(\.?\d{0,4})?$/;
  return reg.test(values);
}
function getMonthShortName(monthNo) {
  const date = new Date();
  date.setMonth(monthNo);
  return date.toLocaleString('en-US', { month: 'short' });
}
const padDate = (n) => {
  return n < 10 ? '0' + n : n;
}
function formatDate(date, params) {
  let dt = new Date(date);
  let day = dt.getDate();
  let month = dt.getMonth();
  let monthName = getMonthShortName(month)
  let year = dt.getFullYear();
  let dateObject;
  if (params == "view") {
    dateObject = padDate(day) + "/" + padDate(month + 1) + "/" + year;
  }
  else dateObject = day + '-' + monthName + '-' + year
  return dateObject
}
const compareDates = (date1, date2) => {
  let dateResult;
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  if (d1 > d2) dateResult = false
  else if (d1 < d2) dateResult = true
  else dateResult = false
  return dateResult
}
const isValidAdvancePayment = (value) => {
  const regex = /^[0-9]*[0-9]+[0-9]*$/
  const result = value != '' && typeof value === 'string' ? value.trim() : value;
  if (regex.test(result)) return true
  else return false
}
const getStorageData = () => {
  const userProf = JSON.parse(localStorage.getItem("userProfile"))
  return userProf
}
const getDecimalValue = (pNumber) => {
  if (pNumber === "" || pNumber === null || typeof (pNumber) === "undefined" || pNumber === NaN)
    return 0
  else return Number(pNumber)
}
const AvatarName = (preName) => {
  if (preName) {
    const nameArr = preName.split(" ")
    if (nameArr.length > 0)
      if (nameArr.length === 1)
        return nameArr[0]?.substring(0, 2).toUpperCase()
      else
        return nameArr[0].substring(0, 1).toUpperCase() + nameArr[1].substring(0, 1).toUpperCase()
    else return ""
  } else return "CC"
}
const PriceCheck = (event, value) => {
  let result = (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46;
  if (result) {
    let t = value;
    if (t === '' && event.charCode === 46) {
      return false;
    }
    let dotIndex = t.indexOf(".");
    let valueLength = t.length;
    if (dotIndex > 0) {
      if (dotIndex + 2 < valueLength) {
        return false;
      } else {
        return true;
      }
    } else if (dotIndex === 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
const checkValue = (value) => {
  if (value === null || value === "undefined" || value === "" || value === "null") return ""
  else return value
}
const PriceFormat = (value) => {
  if (value)
    return value?.toLocaleString('en-US', { minimumFractionDigits: 2 })
}

const GetTimeFormat = (time) => {
  if (!time) return "";
  const arrTime = time.split(":");
  const hours24 = parseInt(arrTime[0], 10);
  const minutes = arrTime[1];
  const hours12 = hours24 % 12 || 12; 
  const ampm = hours24 < 12 ? "AM" : "PM";
  const formattedMinutes = minutes.padStart(2, "0");
  const formattedTime = `${hours12}:${formattedMinutes} ${ampm}`;
  return formattedTime;
};


const DateFormatShow = (value,time) =>{
  if (value === null || typeof (value) === "undefined" || value === "")
    return new Date();
  else if (new Date(value) === "Invalid Date")
    return new Date();
  else if (time === true)
    return  moment(value).format("DD/MM/YYYY hh:mm A")
  else
  return  moment(value).format("DD/MM/YYYY")
}

const stripHtmlUsingRegex = (input) => {
  const htmlEntities = {
    "&nbsp;": " ",
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'"
  };

  let output = input.replace(/<\/?[^>]+(>|$)/gs, "");

  Object.entries(htmlEntities).forEach(([entity, replacement]) => {
    output = output.replace(new RegExp(entity, 'g'), replacement);
  });

  output = output.replace(/&amp;/g, '&');

  return output.trim();
};

const isSingleSpaceAllowed = (value) => {
    let regex = /^[a-zA-Z0-9.,\-!@#$%^&*()_+={}[\]:";'<>?\\|~`]+(?: [a-zA-Z0-9.,\-!@#$%^&*()_+={}[\]:";'<>?\\|~`]+)*$/;
    return value === "" || regex.test(value);  
};

export {
  exists,
  isEmpty,
  CheckNull,
  isRequired,
  isValidEmail,
  isValidPhone,
  isValidCountryPhone,
  isTextOnly,
  isValidPassword,
  isNumberOnly,
  isValidUrl,
  isRequiredNo,
  isValidFileUploaded,
  isValidFileSize,
  isValidFaxNumber,
  diffdays,
  isValidDate,
  dateCheck,
  validateName,
  isAlphaNumeric,
  convertLocalToUTCDate,
  isValidConversionRate,
  formatDate,
  compareDates,
  isValidAdvancePayment,
  getStorageData,
  getDecimalValue,
  FormatValueDecimal,
  FormatSeperateValue,
  FormatDecimalSeperateValue,
  AvatarName,
  isValidString,
  PriceCheck,
  checkValue,
  PriceFormat,
  GetTimeFormat,
  DateFormatShow,
  stripHtmlUsingRegex,
  isSingleSpaceAllowed
};
