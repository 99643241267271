import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import imgUploadIcon from '../../assets/images/icons/gallary.png';
import awsmobile from '../../aws-exports';
import { Storage } from 'aws-amplify';
import customLogger from '../../common/loggerService';

const MyVerticallyCenteredModal = ({ userDetails, setProfileImage, setUserImageAddedResponse, UpdateUserAddress, setProfileImgUpload }) => {
    const [imgUpload, setImgUpload] = useState(null);
    const [file, setFile] = useState(null);
    const handleClose = () => setProfileImgUpload(false);

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImgUpload(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleFileChange = async () => {
        if (!file) return; 

        const fileName = file.name;
        const fileKey = `${fileName}`;

        try {
            await Storage.put(fileKey, file, {
                contentType: file.type,
            });

            const image = `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_user_files_s3_bucket_region}.amazonaws.com/public/${fileKey}`;

            setProfileImage(image);

            const data = {
                pk: userDetails?.pk || `USER#`,
                id: userDetails?.id || userDetails?.attributes?.sub,
                profileImageUrl: image
            };

            UpdateUserAddress(data, setUserImageAddedResponse);
            handleClose();
        } catch (error) {
            customLogger.log(error);
        }
    };

    const handleBrowseClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        if (droppedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImgUpload(reader.result);
            };
            reader.readAsDataURL(droppedFile);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <Modal
            show={true}
            onHide={handleClose} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="img-upload"
        >
            <Modal.Body>
                <div className='img-file' onDrop={handleDrop} onDragOver={handleDragOver}>
                    <img src={imgUpload ? imgUpload : imgUploadIcon} alt="Upload" />
                    <p>
                        Drop your file here <br /> or
                    </p>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                    />
                    <button onClick={handleBrowseClick}>Browse Files</button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='cancel-btn' onClick={handleClose}>Cancel</Button>
                <Button className='save-btn' onClick={handleFileChange}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MyVerticallyCenteredModal;
