import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import CommentIcon from '../../assets/images/icons/message.png';
import { Accordion } from 'react-bootstrap';
import { GetData } from '../../providers/queryData';
import { resourceconstant } from '../../constants';
import NoResultsFound from '../not-found/noResultsFound';
export default function FAQ() {
    const [listFaq, setListFaq] = useState([])
    const [faqContent, setFaqContent] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [searchContent, setSearchContent] = useState([])
    const GetFaqContent = () => {
        let filter = {}
        GetData(resourceconstant.FAQS_CONTENTS, filter, setListFaq)
    };

    useEffect(() => {
        if (listFaq?.severity === "success") {
            const content = listFaq?.data?.data?.listFAQS?.items
            const policyCon = content.filter(item => item.name && item.FAQSections?.length > 0 && item?.type === "Buyer");
            const sortedContent = [...policyCon].sort((a, b) => a.sequenceNumber - b.sequenceNumber);
            setFaqContent(sortedContent);
            setActiveTab(sortedContent[0]?.id || '');
        }
    }, [listFaq])

    useEffect(() => {
        GetFaqContent()
    }, [])
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setSearchContent(faqContent);
            const data = faqContent.find(data => data?.id);
            setActiveTab(data?.id);
        } else {
            const filteredContent = faqContent.filter(item => {
                const matchesName = item.name.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesSections = item?.FAQSections?.some(section =>
                    section.question.toLowerCase().includes(searchQuery.toLowerCase())
                );

                return matchesName || matchesSections;
            });

            setSearchContent(filteredContent);
            const data = filteredContent.find(data => data?.id);
            setActiveTab(data?.id);
        }
    }, [searchQuery, faqContent]);

    const activeItem = searchContent.find(item => item.id === activeTab);

    const getPolicyContent = () => {
        const currentPolicy = searchContent.find(policy => policy.id === activeTab);
        if (currentPolicy) {
            const filteredSections = currentPolicy.FAQSections.filter(section =>
                section.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
                currentPolicy.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            return filteredSections;
        }
        return [];
    };

    return (
        <section id="policy-sec" className='my-3'>
            <Container>
                <Row className='mt-1'>
                    <Col md={12}>
                        <Tab.Container activeKey={activeTab}>
                            <Card>
                            {getPolicyContent()?.length > 0 &&
                                <CardHeader>
                                    <Row>
                                        <Col xxl={9} xl={9} lg={8} md={7} sm={12} xs={12} >
                                            <h2 className='mx-auto text-center d-flex align-items-center'>
                                                {getPolicyContent()?.length > 0 && <img src={CommentIcon} alt="comment icon" />}
                                                {activeItem?.name}
                                            </h2>
                                        </Col>
                                        <Col xxl={3} xl={3} lg={4} md={4} sm={12} xs={12} className='search-box' >
                                            <div className="input-group">
                                                <input
                                                    type="search"
                                                    placeholder="Search"
                                                    aria-label="Input group"
                                                    aria-describedby="btnGroupAddon"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                                <div className="input-group-text">
                                                    <i className="fa fa-search"></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            }
                                <CardBody>
                                    <Row className='gx-1'>
                                        <Col md={3} className='pe-1'>
                                            <div className="policy-left">
                                                <Nav className="flex-column">
                                                    {searchContent.map((item, index) => (
                                                        <Nav.Item key={index}>
                                                            <Nav.Link eventKey={item?.id || faqContent[0]?.id} onClick={() => setActiveTab(item.id)}><span><i className='fa fa-info'></i></span>{item.name}</Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>
                                            </div>
                                        </Col>
                                        {getPolicyContent()?.length > 0 ? (
                                            <Col md={9} className='ps-0'>
                                                <div className="policy-right">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={activeTab}>
                                                            <div className='policy-description'>
                                                                <Accordion>
                                                                    {getPolicyContent().map((data, dataIndex) => (
                                                                        <Accordion.Item eventKey={dataIndex} key={dataIndex}>
                                                                            <Accordion.Header>{data.question}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <p dangerouslySetInnerHTML={{ __html: data?.answer }} />
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    ))}
                                                                </Accordion>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Col>
                                        ) : <NoResultsFound />}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}