import React, { useState, useEffect } from 'react';
import LocationIcon from '../assets/images/icons/location.png';
import Locationimg from '../assets/images/icons/delivery-location.png';
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { InputGroup } from 'react-bootstrap';
import useChangeLocation from "../hooks/useChangeLocation";
import { UpdateUserAddress } from "../utils/generalMethod";
import useProfile from '../hooks/useProfile';
import { Row, Col, CardBody, Card, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

const ChangeLocation = () => {
    const [userProfile, setUserProfile] = useProfile();
    const [changeLocation, setChangeLocation] = useChangeLocation();
    const [isLocationEnabled, setIsLocationEnabled] = useState(false);
    const [addressResponse, setAddressResponse] = useState(null);
    const userid = userProfile?.attributes?.sub
    const handleEnableLocation = () => {
        setIsLocationEnabled(true);
    };

    const handleCloseLocation = () => {
        setIsLocationEnabled(false);
    };

    const HandleChangeLocation = (item,index) => {
        let changeLocationCopy = changeLocation
        changeLocationCopy?.map((location, locationIndex) => {
            if (locationIndex === index) location.default = true;
            else location.default = false;
            delete location?.address?.__typename;
            delete location?.__typename;
            return location;
        });

        let data = {
            id: userProfile?.attributes?.sub,
            pk: "USER#",
            addressDetails: changeLocationCopy
        };
        UpdateUserAddress(data, setAddressResponse);
    }

    useEffect(() => {
        if (addressResponse?.severity === "success")
            setChangeLocation(addressResponse?.data?.data?.updateUser?.addressDetails)
    }, [addressResponse])
    return (
        <>
            <div className="dropdown-content">
                <div className='p-3'>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                        <h2>Location</h2>
                        {!userid && (
                            <a href="#" class="clearall">Reload</a>
                        )}
                    </div>
                    {!userid && (
                        <>
                            <div>
                                <div style={{ width: '200px', height: '350px', border: '0' }}>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121059.0344739699!2d73.86296739999999!3d18.52461645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf2e67461101%3A0x828d43bf9d9ee343!2sPune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1717991480080!5m2!1sen!2sin"
                                        width="530"
                                        height="350"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Pune Map"></iframe>
                                </div>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className='current-location mt-2'>
                                        Choose Current Location
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='location-btn mt-2'>
                                        <Link to="/login">Sign Up</Link>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}

                    {userid ? (
                        changeLocation?.length > 0 ? (
                            <div className="location-dropdown">
                                <Row>
                                    {changeLocation.map((item, index) => {
                                        const location = changeLocation.filter((loc) => loc.default === true);
                                        let classFlex = `d-flex gap-2 location-address mt-2 ${item.default ? 'active' : ''}`;
                                        if (location.length <= 0 && index === 0) classFlex = `d-flex location-address mt-2 active`;

                                        return (
                                            <Col
                                                xxl={6}
                                                xl={6}
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="mb-2"
                                                key={index}
                                            >
                                                <div className={classFlex} onClick={() => HandleChangeLocation(item,index)}>
                                                    <div className="location-drpicon on">
                                                        <input name="default" type="checkbox" checked={item.default} readOnly />
                                                    </div>
                                                    <div>
                                                        <div className="description">
                                                            <h2 className="title">{item.name}</h2>
                                                        </div>
                                                        <p className="message">
                                                        {item?.address?.addressLine1} <br />{" "}
                                                        {item?.address?.addressLine2 && item?.address?.addressLine2+ "," }
                                                        {item?.address?.street},{" "}
                                                        <br /> {item?.address?.city},
                                                        {item?.address?.state && item?.address?.state + ","} <br />
                                                        {item?.address?.country} {item?.address?.postalCode && " - "+ item?.address?.postalCode}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        ) : (
                            <Row>
                                <Col className="d-flex align-items-center justify-content-center no-results">
                                    <p>There is no address added yet</p>
                                </Col>
                            </Row>
                        )
                    ) : (
                        ""
                    )}


                    {userid && (
                        <div className='location-btn mt-2'>
                            <Link
                                to="/address"
                            > Add New Address</Link>
                        </div>
                    )}
                </div>
            </div >
        </>
    );
};

export default ChangeLocation;