import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row, Table } from 'react-bootstrap';
import { FormatValueDecimal } from "../../utils/validate";
import useLogoImage from "../../hooks/useLogoImage";
const OrderConfirmSummeryApp = ({ orderList }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();
    return (
        <Col md={4} sm={12} className='my-1'>
            <Card className='order-summary-card'>
                <CardHeader>
                    <h2>Order Summary</h2>
                </CardHeader>
                <CardBody className='px-2'>
                    <Row>
                        <Col md={12}>
                            <div className='table-responsive'>
                                {orderList.map((order, index) => {
                                    const saved = Number(order?.orderSummary?.productDiscount || 0);
                                    return (
                                        <table className='table table-borderless mb-0'>
                                            <tbody>
                                                <tr>
                                                    <th>Cart Total</th>
                                                    <td className='text-end'>{logoUrl?.currency} {FormatValueDecimal(order?.orderSummary?.cartTotal)}</td>
                                                </tr>
                                                {saved !== 0 && (
                                                    <tr>
                                                        <th>You Saved</th>
                                                        <td className='text-end' style={{ color: "#1BAA57" }}>{logoUrl?.currency} {FormatValueDecimal(saved)}</td>
                                                    </tr>
                                                )}
                                                {order?.couponDetails?.couponCode && (
                                                <tr>
                                                    <th>Coupon Discount <br />({order?.couponDetails?.couponCode})</th>
                                                    <td className='text-end' style={{ color: "#1A0003" }}>{logoUrl?.currency} {FormatValueDecimal(order?.couponDetails?.amount)}</td>
                                                </tr>
                                                )}
                                                {order?.orderSummary?.deliveryFee && (
                                                <tr>
                                                    <th>Delivery Fee</th>
                                                    <td className='text-end' style={{ color: "#1A0003" }}>{logoUrl?.currency} {FormatValueDecimal(order?.orderSummary?.deliveryFee)}</td>
                                                </tr>
                                                )}
                                                <tr>
                                                    <th>Tax</th>
                                                    <td className='text-end'>{logoUrl?.currency} {FormatValueDecimal(order?.orderSummary?.tax)}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Total</th>
                                                    <th>{logoUrl?.currency} {FormatValueDecimal(order?.totalAmount)}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default OrderConfirmSummeryApp