import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listProducts, listCategories, listNotifications } from "../graphql/queries";
import customLogger from "../common/loggerService";
import * as mutations from "../graphql/mutations";
import * as query from "../graphql/queries";
import { resourceconstant } from "../constants";
import { showSuccessToastr, showErrorToastr } from "../common/toastrService";
import { DeleteCart } from "../utils/generalMethod";
import Amplify, { Auth } from "aws-amplify";
import { isEmpty } from "../utils/validate";
import queryString from "query-string";
import { onCreateNotifications, onUpdateUser } from "../graphql/subscriptions";

export const MainListProducts = async () => {
  let isLoggedIn = await LoggedIn();
  let data;
  await API.graphql({
    query: listProducts,
    authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  })
    .then((reponse) => (data = reponse.data))
    .catch((error) => customLogger.error(error));
  return data;
};

export const MainListNotification = async ({ userid, limit = 650 }) => {
  let isLoggedIn = await LoggedIn();
  let allData = [];
  let nextToken = null;
  let responseKey = '';

  try {
    do {
      const response = await API.graphql({
        query: listNotifications,
        variables: {
          pk: `NOTI#${userid}`,
          limit: limit,
          nextToken: nextToken
        },
        authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const data = response.data;
      responseKey = Object.keys(data)[0];
      const items = data[responseKey].items;
      nextToken = data[responseKey].nextToken;
      allData = [...allData, ...items];

    } while (nextToken !== null);

    return { data: { [responseKey]: { items: allData } }, allData };

  } catch (error) {
    customLogger.error(error);
  }
};

export const subscribeToNotifications = (userid, setNotifications) => {
  const createSubscription = API.graphql(
    graphqlOperation(onCreateNotifications, { owner: userid })
  ).subscribe({
    next: (notificationData) => {
      const newNotification = notificationData.value.data.onCreateNotification;
      setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
    },
    error: (error) => customLogger.error(error)
  });

  return createSubscription;
};

export const DeleteNotificationById = async (resource, userid, notificationId, setResponseData, fuctionName) => {
  try {
    const apiUrl = GetQueryFunctions(resource);
    const isLoggedIn = await LoggedIn();
    const token = isLoggedIn ? localStorage.getItem("IdToken") : null;
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (isLoggedIn) {
      requestOptions.headers.Authorization = `Bearer ${token}`;
    }

    const input = { id: notificationId, pk: `NOTI#${userid}` };
    const response = await API.graphql({
      query: apiUrl,
      variables: { input },
      authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      requestOptions,
    });
    setResponseData(response.data.deleteNotifications);
    if (fuctionName != "clearAll") { showSuccessToastr("Notification deleted successfully"); }
    return true;
  } catch (error) {
    customLogger.error(error);
    if (fuctionName != "clearAll") { showErrorToastr("Failed to delete notification"); }
    return false;
  }
};
export const MainListCategories = async () => {
  let isLoggedIn = await LoggedIn();
  let data;
  await API.graphql({
    query: listCategories,
    authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  })
    .then((reponse) => (data = reponse.data))
    .catch((error) => customLogger.error(error));
  return data;
};

export const MainFilteredListCategories = async () => {
  let isLoggedIn = await LoggedIn();
  let data;
  await API.graphql({
    query: listCategories,
    variables: { pk: "MAIN#" },
    authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  })
    .then((reponse) => (data = reponse.data))
    .catch((error) => customLogger.error(error));
  return data;
};

export const PostData = async (resource, data, setResponseData) => {
  let isLoggedIn = await LoggedIn();
  const queryFn = GetQueryFunctions(resource);
  try {
    await API.graphql({
      query: queryFn,
      authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      variables: {
        input: data,
      },
    }).then((res) => {
      setResponseData({ data: res, severity: "success" });
    });
  } catch (err) {
    customLogger.error(err);
    setResponseData({ data: err, severity: "error" });
  }
};
export const GetData = async (resource, filter, setResponseData) => {
  let isLoggedIn = await LoggedIn();
  const queryFn = GetQueryFunctions(resource);

  let allData = [];
  let nextToken = null;
  let res = null;
  let responseKey = "";
  try {
    do {
      res = await API.graphql({
        query: queryFn,
        variables: {
          filter: filter,
          limit: 650,
          nextToken: nextToken
        },
        authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const data = res.data;
      responseKey = Object.keys(data)[0];
      const items = data[responseKey].items;
      nextToken = data[responseKey].nextToken;
      allData = [...allData, ...items];

    } while (nextToken !== null);
    let newData = {
      data: {
        [responseKey]: {
          items: allData
        }
      }
    }
    setResponseData({ data: newData, allData: allData, severity: "success" });
  } catch (err) {
    customLogger.error(err);
    setResponseData({ data: err, severity: "error" });
  }
};

export const validateAndRefreshToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
  const accessTokenExpirationTime = new Date(currentUser.signInUserSession.accessToken.payload.exp * 1000);
  const currentDate = new Date();
  if (accessTokenExpirationTime <= currentDate) {
    await Auth.currentSession();
    const refreshedUser = await Auth.currentAuthenticatedUser();
    const refreshedAccessToken = refreshedUser.signInUserSession.accessToken.jwtToken;
    localStorage.setItem('IdToken', refreshedAccessToken);
    return refreshedAccessToken;
  }
  return accessToken;
};
export const CreateEntry = async (resource, isLoggedin, setResponseData, params) => {
  const apiUrl = GetQueryFunctions(resource);
  const token = isLoggedin ? await validateAndRefreshToken() : null;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (params) {
    requestOptions.body = JSON.stringify(params);
  }
  if (token) {
    requestOptions.headers.Authorization = `Bearer ${token}`;
  }
  fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      setResponseData(data);
    })
    .catch((error) => {
      if (isLoggedin) {
        showErrorToastr(error.message);
      }
    });
};

export const DeleteEntry = async (resource, isLoggedin, params, setResponseData) => {
  let apiUrl = GetQueryFunctions(resource);
  const token = isLoggedin ? await validateAndRefreshToken() : null;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (isLoggedin) {
    requestOptions.headers.Authorization = `Bearer ${token}`;
  }
  const queryString = new URLSearchParams(params).toString();
  const urlWithParams = `${apiUrl}?${queryString}`;
  fetch(urlWithParams, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      setResponseData(data)
    })
    .catch((error) => {
      showErrorToastr(error.message);
    });
};

export const GetList = async (resource, params, isLoggedIn, setListData) => {
  let baseUrl = GetQueryFunctions(resource);

  const token = isLoggedIn ? await validateAndRefreshToken() : null;
  const queryString = new URLSearchParams(params).toString();

  const fullUrl = `${baseUrl}?${queryString}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: token,
    },
  };

  fetch(fullUrl, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      setListData({ data: data, severity: "success", params: params });
    })
    .catch((e) => {
      setListData({ data: e?.errors, severity: "Error", params: params });
    });
};

const GetQueryFunctions = (resource, params) => {
  const paramQuery = {};
  const apiUrl = process.env.REACT_APP_ADD_TO_CART;
  const apiDeleteCartUrl = process.env.REACT_APP_CART_PRODUCT_DELETE;
  let queryFunction = "";
  if (resource === resourceconstant?.CREATE_WISH_LIST)
    queryFunction = mutations?.createWishList;
  if (resource === resourceconstant?.LIST_WISH_LISTS)
    queryFunction = query?.listWishLists;
  if (resource === resourceconstant?.DELETE_WISH_LISTS)
    queryFunction = mutations?.deleteWishList;
  if (resource === resourceconstant?.PRODUCT_ADD_TO_CART)
    queryFunction = apiUrl;
  if (resource === resourceconstant?.UPDATE_USER_ADDRESS)
    queryFunction = mutations?.updateUser;
  if (resource === resourceconstant?.LIST_USER_DETAILS)
    queryFunction = query?.listUsers;
  if (resource === resourceconstant?.LIST_COUNTRY_DETAILS)
    queryFunction = query?.listCountries;
  if (resource === resourceconstant?.PRODUCT_LIST)
    queryFunction = query?.listProducts;
  if (resource === resourceconstant?.PRODUCT_DETAIL_LIST)
    queryFunction = query?.listProductDetails;
  if (resource === resourceconstant?.PRODUCT_ADD_TO_CART_DELETE)
    queryFunction = apiDeleteCartUrl;
  if (resource === resourceconstant?.CREATE_GENERAL_CONTACTUS)
    queryFunction = mutations?.createGeneralContactUs;
  if (resource === resourceconstant?.LIST_ORDER_ITEM)
    queryFunction = query?.listOrderItems;
  if (resource === resourceconstant?.PRODUCT_BUY_NOW) {
    queryFunction = process.env.REACT_APP_PRODUCT_BUY_NOW;
    if (params?.productID) paramQuery["productID"] = params?.productID;
    if (params?.userId) paramQuery["userId"] = params?.userId;
    if (params?.quantity) paramQuery["quantity"] = params?.quantity;
    if (params?.price) paramQuery["price"] = params?.price;
    if (params?.salesPrice) paramQuery["salesPrice"] = params?.salesPrice;
    if (params?.attrName) paramQuery["attrName"] = params?.attrName;
    if (params?.attrValue) paramQuery["attrValue"] = params?.attrValue;
    if (params?.taxCharge) paramQuery["taxCharge"] = params?.taxCharge;
    if (params?.productImage) paramQuery["productImage"] = params?.productImage;
    if (params?.model) paramQuery["model"] = params?.model;
    if (params?.sku) paramQuery["sku"] = params?.sku;
    if (params?.mainCategory) paramQuery["mainCategory"] = params?.mainCategory;
    if (params?.subCategoryN) paramQuery["subCategoryN"] = params?.subCategoryN;
    if (params?.attrValueID) paramQuery["attrValueID"] = params?.attrValueID;
    if (params?.stockUnit) paramQuery["stockUnit"] = params?.stockUnit;
    if (params?.itemsPerUnit) paramQuery["itemsPerUnit"] = params?.itemsPerUnit;
    if (params?.currentStock) paramQuery["currentStock"] = params?.currentStock;
    if (params?.mainCategoryId) paramQuery["mainCategoryId"] = params?.mainCategoryId;
    if (params?.subCategoryId) paramQuery["subCategoryId"] = params?.subCategoryId;
    if (params?.wareHouseName) paramQuery["wareHouseName"] = params?.wareHouseName;
    if (params?.wareHouseId) paramQuery["wareHouseId"] = params?.wareHouseId;
    if (!isEmpty(paramQuery))
      queryFunction = `${queryFunction}?${queryString?.stringify(paramQuery)}`;
  }
  if (resource === resourceconstant?.LIST_CATEGORY)
    queryFunction = query?.listCategories;
  if (resource === resourceconstant?.BEST_SELLERS)
    queryFunction = process.env.REACT_APP_BEST_SELLERS;
  if (resource === resourceconstant?.BRAND_LIST)
    queryFunction = query?.listBrands;
  if (resource === resourceconstant?.LIST_TOKEN_DETAILS)
    queryFunction = query?.listTokenDetails;
  if (resource === resourceconstant?.CREATE_TOKEN_DETAIL)
    queryFunction = mutations?.createTokenDetails;
  if (resource === resourceconstant?.UPDATE_TOKEN_DETAIL)
    queryFunction = mutations?.updateTokenDetails;
  if (resource === resourceconstant?.LIST_CARTS)
    queryFunction = query?.listCarts;
  if (resource === resourceconstant?.UPDATE_CART)
    queryFunction = mutations?.updateCart;
  if (resource === resourceconstant?.LIST_ORDERS)
    queryFunction = query?.listOrders;
  if (resource === resourceconstant?.CREATE_ORDER)
    queryFunction = process.env.REACT_APP_PAYMENT_CHECKOUT;
  if (resource === resourceconstant?.UPDATE_ORDER)
    queryFunction = process.env.REACT_APP_ORDER_UPDATE;
  if (resource === resourceconstant?.UPDATE_ORDER_ITEM)
    queryFunction = mutations?.updateOrderItem;
  if (resource === resourceconstant?.CREATE_PRODUCT_REVIEW)
    queryFunction = mutations?.createProductReview
  if (resource === resourceconstant?.GET_DROPDOWN_MASTER)
    queryFunction = query?.listDropDownMasters
  if (resource === resourceconstant?.GET_COMPANY_DETAILS)
    queryFunction = query?.listCompanyDetails
  if (resource === resourceconstant?.UPDATE_NOTIFICATION)
    queryFunction = mutations?.updateNotifications
  if (resource === "deleteNotifications")
    queryFunction = mutations?.deleteNotifications
  if (resource === resourceconstant?.PRODUCT_VISIT_COUNT)
    queryFunction = mutations?.createProductVisit
  if (resource === resourceconstant?.PRODUCT_VISIT_COUNT_UPDATE)
    queryFunction = mutations?.updateProductVisit
  if (resource === resourceconstant?.PRODUCT_VISIT_COUNT_LIST)
    queryFunction = query?.listProductVisits
  if (resource === resourceconstant?.PROFILE_PHONE_SEND_OTP)
    queryFunction = process.env.REACT_APP_PROFILE_PHONE_NUMBER_OTP;
  if (resource === resourceconstant?.PROFILE_PHONE_VERIFY_OTP)
    queryFunction = process.env.REACT_APP_PROFILE_VERIFY_OTP;
  if (resource === resourceconstant?.PROFILE_MAIL_OTP)
    queryFunction = process.env.REACT_APP_PROFILE_MAIL_OTP;
  if (resource === resourceconstant?.PRODUCT_RATING_LIST)
    queryFunction = query?.listProductReviews;
  if (resource === resourceconstant?.PROMO_CODE_LIST)
    queryFunction = query?.listPromoCodes;
  if (resource === resourceconstant?.UPDATE_PRODUCT_REVIEW)
    queryFunction = mutations?.updateProductReview;
  if (resource === resourceconstant?.TOP_DEALS)
    queryFunction = process.env.REACT_APP_TOP_DEALS;
  if (resource === resourceconstant?.CATEGORY_WISE)
    queryFunction = process.env.REACT_APP_CATEGORYWISE_BEST_SELLERS;
  if (resource === resourceconstant?.UPDATE_PRODUCT_ITEM)
    queryFunction = mutations?.updateProductDetails;
  if (resource === resourceconstant?.LIST_CONFIGURATION)
    queryFunction = query?.listConfigurations
  if (resource === resourceconstant?.CREATE_CONFIGURATION)
    queryFunction = mutations?.createConfiguration
  if (resource === resourceconstant?.UPDATE_CONFIGURATION)
    queryFunction = mutations?.updateConfiguration
  if (resource === resourceconstant?.POLICY_CONTENTS)
    queryFunction = query?.listContents;
  if (resource === resourceconstant?.FAQS_CONTENTS)
    queryFunction = query?.listFAQS;
  if (resource === resourceconstant?.DELIVERY_FEE)
    queryFunction = query?.listShippings;
  if (resource === resourceconstant?.PAYMENT_MODE)
    queryFunction = query?.listPaymentModes;
  if (resource === resourceconstant?.CAROUSAL_IMAGES)
    queryFunction = query?.listCarouselImages;
  if (resource === resourceconstant?.PRODUCTS_FILTERS)
    queryFunction = process.env.REACT_APP_PRODUCT_FILTER;
  if (resource === resourceconstant?.PROFILE_NEW_MAIL)
    queryFunction = process.env.REACT_APP_PROFILE_NEW_MAIL;
  if (resource === resourceconstant?.USER_DELETE)
    queryFunction = process.env.REACT_APP_DELETE_ACCOUNT;
  if (resource === resourceconstant?.FILTER_LIST)
    queryFunction = process.env.REACT_APP_PRODUCT_FILTER_LIST;
  if (resource === resourceconstant?.CREATE_USER)
    queryFunction = mutations?.createUser;
  if (resource === resourceconstant?.LIST_ALL_PRODUCTS)
    queryFunction = query?.listAllProducts;
  if (resource === resourceconstant?.FORGET_PASSWORD)
    queryFunction = process.env.REACT_APP_FORGET_PASSWORD;
  if (resource === resourceconstant?.LIST_WAREHOUSES)
    queryFunction = query?.listWareHouses;
  return queryFunction;
};

export const LoggedIn = async () => {
  // Another way to get if its a guest or not
  //return await Auth.Credentials.getCredSource() === "guest"
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
};

export const GetAllProducts = async (resource, filter, setResponseData) => {
  let isLoggedIn = await LoggedIn();
  const queryFn = GetQueryFunctions(resource);

  let allData = [];
  let nextToken = null;
  let res = null;
  let responseKey = "";
  let currentPage = 0
  let items;
  try {
    do {
      res = await API.graphql({
        query: queryFn,
        variables: {
          globalPk: "PRODUCTS#",
          limit: 650,
          sortDirection: "ASC",
          filter: filter,
          nextToken: nextToken
        },
        authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const data = res.data;
      responseKey = Object.keys(data)[0];
      items = data[responseKey].items;
      nextToken = data[responseKey].nextToken;
      allData = [...allData, ...items];
      currentPage++;
    } while (nextToken !== null);
    let newData = {
      data: {
        [responseKey]: {
          items: allData
        }
      }
    }
    setResponseData({ data: newData, allData: allData, currentItem: items, severity: "success" });
  } catch (err) {
    customLogger.error(err);
    setResponseData({ data: err, severity: "error" });
  }
};

export const GetFetchProducts = async (setProductsList, setNextToken, setIsFetching, isFetching, nextToken) => {
  if (isFetching) return; // Prevent multiple fetches
  setIsFetching(true);

  try {
    const response = await API.graphql(graphqlOperation(query?.listAllProducts, {
      globalPk: "PRODUCTS#",
      limit: 20, // Number of items per page
      nextToken: nextToken, // Next token for pagination
    }));

    const data = response.data;
    let responseKey = Object.keys(data)[0];
    nextToken = data[responseKey].nextToken;
    const fetchedItems = data[responseKey].items;
    const newNextToken = data[responseKey].nextToken;
    setProductsList((prevItems) => [...prevItems, ...fetchedItems]); // Append new items to the existing list
    setNextToken(newNextToken); // Update next token for the next fetch
  } catch (error) {
    console.error('Error fetching items:', error);
  } finally {
    setIsFetching(false);
  }
};