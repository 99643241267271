import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCartPage";
import { OrderConfirmation } from "../../utils/generalMethod";
import { CheckNull } from "../../utils/validate";

const PaymentConfirmation = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [updateData, setUpdateData] = useState({})
    const [cartPageItem, setCartPageItem] = useCart()
    const [deleteResponse, setDeleteResponse] = useState({})
    const buyData = useMemo(() => {
        return localStorage.getItem('buyNow');
      }, []);
    const myParam = new URLSearchParams(location.search);

    useEffect(() => {
        if (myParam?.get('status') && CheckNull(localStorage.getItem("orderId")) !=="") {
            let detail = { 
                paymentStatus: myParam?.get('status') === "success" ? "CONFIRMED" : "FAILED",
                orderid: localStorage.getItem('orderId'),
                paymentDate: new Date(myParam?.get('datetime')).toISOString(),
                referenceNumber: myParam?.get("transactionId"),
                receivedAmount: myParam?.get("amount")
            }
            OrderPaymentUpdate(detail);
        }
        else{
            navigate("/")
        }
    }, [myParam?.get('status')])

    useEffect(() => {
        if (deleteResponse?.status === true) {
            localStorage.removeItem('cartData');
            setCartPageItem([]);
        }
    }, [deleteResponse]);

    const OrderPaymentUpdate = async (paymentDetail) => {
        let data = {
            paymentMethod: "CreditCard",
            paymentStatus: paymentDetail?.paymentStatus,
            orderid: paymentDetail?.orderid,
            paymentDate: paymentDetail?.paymentDate,
            referenceNumber: paymentDetail?.referenceNumber,
            receivedAmount: paymentDetail?.receivedAmount
        }
        OrderConfirmation(data, setUpdateData, setDeleteResponse)
    };

    useEffect(() => {
        if (updateData?.statusCode === 200) {
            if (!buyData && myParam?.get('status') === "success") {
                localStorage.removeItem('cartData');
                localStorage.removeItem('couponData')
                setCartPageItem([])
            }
            if (myParam?.get('status') === "success")
                navigate('/order-confirm');
            else if (myParam?.get('status') === "error")
                navigate("/paymentFailed", { state: { reason: myParam?.get('reason') }, });
        }
    }, [updateData]);

    return (<div></div>)
}

export default PaymentConfirmation