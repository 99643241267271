import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Container, Row } from "react-bootstrap";
function WishlistCarouselSkeleton() {
    return (
        <Col md={3} className="px-0 col-12 mb-3">
            <div className='carousel__item'>
                <div className='image-box' >
                    <Skeleton variant="rectangular" width="100%" height={158} />
                </div>
                <div className='carousel__caption'>
                    <p className='title mb-0'><Skeleton width={'100%'} height={15} /></p>
                    <p className='model'>
                        Brand : <Skeleton width={215} height={15} />
                    </p>
                    <h3 className='rating'>
                        <Skeleton width={'100%'} height={15} />
                    </h3>
                    <h4 className='price mb-0'>
                        <Skeleton width={'100%'} height={15} />
                    </h4>
                    <div>
                        <Skeleton width={'100%'} height={15} />
                    </div>
                    <a className='delete-btn' href="#"><Skeleton width={165} height={15} /></a>
                </div>
            </div>
        </Col >
    )
}
export default WishlistCarouselSkeleton


