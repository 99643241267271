export const ToastrMessages = {
    COUPONCODENOTFOUND: "Coupon is not valid",
    COUPONEXIST: "Please remove the current coupon",
    DELIVERYNOTAVAILABLE: "Delivery not available in this address",
    PASSWORDSUCCESS: "Password changed successfully",
    OTPMESSAGE: "A new OTP has been sent to your email. Please check your inbox.",
    CARTOUTOFSTOCK: "The product is currently out of stock. Please remove it from your cart.",
    NEWOTPMESSAGE: "A new OTP has been sent to your new email. Please check your inbox.",
    USERPHONENO: "User phoneNumber updated successfully",
    PRODUCTADDCART: "Product added to cart successfully",
    NOCART: "Item not found in the cart",
    BILLINGADDRESS: "Please choose billingAddress",
    OTPERRORMSG: "OTP has expired.",
    USERDEACTIVATE: "User account is deactivated",
    USERUPDATED: "User Updated successfully.",
    PROFILEADDED: "User image updated successfully",
    PROFILEDELETED: "User image removed successfully"
  }