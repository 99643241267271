import { useContext, useEffect, useState } from 'react';
import { NotificationContext } from "../context/NotificationContext";
import { MainListNotification } from '../providers/queryData';
import useProfile from "../hooks/useProfile"
import { DateFormatShow } from '../utils/validate';
import moment from 'moment';
import { onCreateNotifications } from '../graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';

function useNotification() {
    const { notifications, setNotifications } = useContext(NotificationContext);
    const [notificates, setNotificates] = useState([]);
    const userDetails = useProfile();
    const userid = userDetails[0]?.attributes?.sub;

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await MainListNotification({ userid });
                const list = data?.data?.listNotifications?.items || [];
                setNotificates(list);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        }
        if (userid) {
            fetchData();
        }
    }, [userid]);

    useEffect(() => {
        const subscription = API.graphql(
          graphqlOperation(onCreateNotifications)
        ).subscribe({
          next: (eventData) => {
            const data = eventData.value.data.onCreateNotifications;
            if (data) {
              const { id } = data;
    
              // Update state with the new notification
              setNotifications((prevNotifications) => [
                ...prevNotifications,
                data,
              ]);
            }
          },
          error: (error) => {
            console.error('Error in subscription stream:', error);
          },
        });
    
        // Clean up the subscription when the component unmounts
        return () => subscription.unsubscribe();
      }, []); // Empty dependency array means this effect runs once on mount and cleans up on unmount
    
    useEffect(() => {
        if (notificates.length > 0) {
            const newNotifications = notificates.map(notification => {
                return {
                    id: notification.id,
                    title: notification.Title,
                    message: notification.Message,
                    type: notification.notificationType === "NC#" ? "info" : "other",
                    status: notification.notificationStatus,
                    module: notification.notificationModule,
                    date: DateFormatShow(notification.createdAt, false),
                    time: moment(notification.createdAt).format("hh:mm A"),
                    orderID:notification?.orderID,
                    createdAt:notification.createdAt
                };
            });
            const parseDate = (dateStr) => {
              const [day, month, year] = dateStr.split('/').map(Number);
              return new Date(year, month - 1, day);
            };
            const currentDate = new Date();
            const sixDaysBeforeDate = new Date();
            sixDaysBeforeDate.setDate(currentDate.getDate() - 6);

            const filteredNotifications = newNotifications.filter((notification) => {
              const notificationDate = parseDate(notification.date);
              return notificationDate >= sixDaysBeforeDate && notificationDate <= currentDate;
            });
            
            const sortedNotifications = filteredNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            
            setNotifications(sortedNotifications);
        }
    }, [notificates]);

    return [notifications, setNotifications];
}

export default useNotification;