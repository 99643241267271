import { useContext, useEffect, useState } from 'react';
import { ChangeLocationContext } from "../context/ChangeLocationContext";
import Amplify, { Auth } from 'aws-amplify';
import { GetUserDetails } from '../utils/generalMethod';

function useChangeLocation() {

  const { changeLocation, setChangeLocation } = useContext(ChangeLocationContext);
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    const GetUserLocation = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser?.attributes?.sub) {
          let data = {
            id: { eq: `${currentUser?.attributes?.sub}` }
          }
          GetUserDetails(data, setUserInfo)
        }
      } catch (error) {
        // setChangeLocation({});
        console.error("error", error)
      }
    };
    GetUserLocation();
  }, [])

  useEffect(() => {
    if (userInfo?.data?.data?.listUsers?.items[0]) {
      setChangeLocation(userInfo?.data?.data?.listUsers?.items[0]?.addressDetails)
    }
  }, [userInfo])

  return [changeLocation, setChangeLocation];
}

export default useChangeLocation;