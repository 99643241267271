import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png'
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify'
import Footer from "../../pages/footer";
import useLoginForm from "./useLoginForm";
import { isRequired } from '../../utils/validate'
import customLogger from "../../common/loggerService";
import { useNavigate } from 'react-router-dom';
import { createTokenDetails, updateTokenDetails } from "../../graphql/mutations";
import { listTokenDetails } from "../../graphql/queries";
import { ValidateName, AccountFieldNameConstant } from "../../constants/validateMsgConstant";
import { v4 as uuid } from 'uuid';
import ValidateField from "../../utils/useValid";
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
import { commonImages, resourceconstant } from "../../constants";
import { Link } from 'react-router-dom';
import useLogoImage from "../../hooks/useLogoImage";
import { CreateEntry } from "../../providers/queryData";
const formValues = {
    email: '',
    password: ''
}
const ForgotPassword = () => {
    const [showSignin, setSignin] = useState("Email");
    const [authError, setAuthError] = useState(null);
    const [listToken, setListToken] = useState([])
    const [values, setValues] = useState(formValues)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [forgetEmail, setForgetEmail] = useState(null)

    const handleChange = (e) => {
        setSignin(e.target.value);
    }

    const HandleChangeLogin = (e) => {
        const { name, value } = e.target

        setErrors({
            ...errors,
            [name]: ""
        });
        setValues({
            ...values,
            [name]: value,
        });
    }

    const HandleBlur = (e) => {
        const { name, value } = e.target;
        const fieldErrors = validation({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: fieldErrors[name]
        });
    };

    const validation = (values) => {
        const errors = {};

        errors.email = ValidateField(
            values.email,
            AccountFieldNameConstant?.EMAIL,
            [ValidateName?.REQUIRED, ValidateName?.ISVALID_EMAIL],
        );

        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })

        return error
    }

    const navigate = useNavigate();

    const ForgetPasswordEmail = (e) => {
        e.preventDefault();
        setErrors(validation(values));
        setIsSubmit(true);
    }

    useEffect(() => {
        const Login = async () => {
            try {
                const data = {
                    email: values?.email
                };

                await CreateEntry(resourceconstant?.FORGET_PASSWORD, false, setForgetEmail, data)
              } catch (error) {
                customLogger.error(error);
                showErrorToastr(error.message);
            }
        };
        if (Object.keys(errors).length === 0 && isSubmit) {
            Login();
        }
    }, [errors]);

    useEffect(() => {
        if (forgetEmail?.status === true) {
            localStorage.setItem('forgetEmail', values.email);
            showSuccessToastr(forgetEmail?.message)
            navigate('/reset-otp')        }
        else if(forgetEmail?.status === false) {
          showErrorToastr(forgetEmail?.message);
        }
      }, [forgetEmail])

    return (
        <>
            <div className="login-page">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="logo-sec">
                                    <Link to="/login" style={{ color: "inherit" }}>
                                        <div class="back-btn">
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className="login-form">
                                    <form>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Forgot Password?</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <p>Please enter the email address associated with your account to begin the password recovery process</p>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="form-group mt-3">
                                                    <label>Email <span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control"
                                                        type="email"
                                                        placeholder="Enter"
                                                        name="email"
                                                        id='email'
                                                        onBlur={(e) => HandleBlur(e)}
                                                        onChange={(e) => HandleChangeLogin(e)} />
                                                    {
                                                        errors?.email && <p className="form-error">{errors?.email}</p>
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <button className="btn submit-btn" onClick={ForgetPasswordEmail}>Continue</button>
                                            </Col>

                                            <Col md={12}>
                                                <p className="text-center">By continuing, you agree to {logoUrl?.companyName}’s<Link to="/terms-condition" state={{ activeKey: "terms" }} onClick={() => { window.scroll(0, 0); }}>Conditions of Use</Link>and<Link to='/privacy-policy' state={{ activeKey: "privacy" }} onClick={() => { window.scroll(0, 0); }}>Privacy Policy.</Link></p>
                                                <hr />
                                            </Col>
                                            <Col md={12}>
                                                <p className="register-link">New User ?<Link to="/create-account">Create New Account</Link></p>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="footer-sec">
                <div className="footer-wrapper">
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default ForgotPassword