import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../../../pages/footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { CreateEntry } from "../../../providers/queryData";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import useProfile from "../../../hooks/useProfile";
import { ToastrMessages } from "../../../constants/toastrMessages";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
  newEmailOtp: '',
}
const NewEmailOtp = () => {
  const [values, setValues] = useState(formValues)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [sendNewEmailOtp, setSendNewEmailOtp] = useState('')
  const navigate = useNavigate()
  const [userProfile, setUserProfile] = useProfile()
  const userid = userProfile?.attributes?.sub;
  const newemail = localStorage.getItem('profileNewEmail')
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem('timeLeftNew');
    return savedTime ? parseInt(savedTime, 10) : 30;
  });    
  const initialTime = 30;
  const [resendNewEmail, setResendNewEmail] = useState([])
  const [logoUrl, setLogoUrl] = useLogoImage();
 
  useEffect(() => {
    localStorage.setItem('timeLeftNew', timeLeft);

    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`;
  };

  const HandleChangeNewEmailOtp = (e) => {
    const { name, value } = e.target

    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  }

  const HandleBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validation({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: fieldErrors[name]
    });
  };

  const validation = (values) => {
    const errors = {};
    errors.newEmailOtp = ValidateField(
      values.newEmailOtp,
      FieldNameConstant?.OTP,
      [ValidateName?.REQUIRED, ValidateName?.OTPCODE],
    );

    let error = {}
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined)
        error[key] = errors[key]
    })
    return error
  }

  const NewEmailOtp = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
  }

  useEffect(() => {
    const HandleNewEmailOtp = async () => {
      try {
        const data = {
          user_id: userid,
          new_email: newemail,
          otp: values?.newEmailOtp
        };

        await CreateEntry(resourceconstant?.PROFILE_NEW_MAIL, true, setSendNewEmailOtp, data)
      } catch (error) {
        if (error.response) {
          customLogger.error('Response:', error.response.data);
        }
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      HandleNewEmailOtp();
    }
  }, [errors])

  const HandleResendNewEmailOtp = async () => {
    try {
      const data = {
        user_id: userid,
        new_email: newemail
      };

      await CreateEntry(resourceconstant?.PROFILE_NEW_MAIL, true, setResendNewEmail, data)

    } catch (error) {
      if (error.response) {
        customLogger.error(error.response.data);
      }
    }
  };

  useEffect(() => {
    if (sendNewEmailOtp?.status === true) {
      showSuccessToastr(sendNewEmailOtp?.message)
      navigate("/login");
    }
    else if(sendNewEmailOtp?.status === false) {
      showErrorToastr(sendNewEmailOtp?.message);
    }
  }, [sendNewEmailOtp])

  useEffect(() => {
    if (resendNewEmail?.status === true) {
      showSuccessToastr(ToastrMessages?.NEWOTPMESSAGE)
      setTimeLeft(initialTime);
    }
  }, [resendNewEmail])

  return (
    <>
      <div className="login-page otp">
        <Card>
          <Row>
            <Col md={12}>
              <div className="login-sec">
                <div className="logo-sec">
                  <Link to="/new-email" style={{ color: "inherit" }}>
                    <div class="back-btn">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                  </Link>
                </div>
                <div className="login-form" style={{ maxWidth: "650px" }}>
                  <form
                    onSubmit={(e) => NewEmailOtp(e)}
                  >
                    <Row>
                      <Col md={12}>
                        <h1>Verify Your Identity</h1>
                      </Col>
                      <Col sm={12} md={12}>
                        <div class="form-group">
                          <p>
                            Please enter the One-Time Password (OTP) sent to {newemail}.This helps us ensure the security of your account
                          </p>
                        </div>
                      </Col>
                      <Col sm={12} md={12}>
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter"
                            name="newEmailOtp"
                            id="newEmailOtp"
                            onChange={(e) => HandleChangeNewEmailOtp(e)}
                            onBlur={(e) => HandleBlur(e)}
                          />
                          {errors?.newEmailOtp && (
                            <p className="form-error">{errors?.newEmailOtp}</p>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                      <p className="register-link mt-2 text-start" onClick={(e) => {
                          if (timeLeft === 0) {
                            HandleResendNewEmailOtp(e);
                          }
                        }}>
                          <a style={{
                            color: timeLeft > 0 ? "#B0B0B0" : "#045EC6",
                            cursor: timeLeft > 0 ? "not-allowed" : "pointer",
                            pointerEvents: timeLeft > 0 ? "none" : "auto"
                          }}
                          >
                            Resend OTP
                          </a>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-end mt-2">
                          {formatTime(timeLeft)}
                        </p>
                      </Col>
                      <Col md={12}>
                        <button className="btn submit-btn" type="submit">
                          Verify Code
                        </button>
                      </Col>
                      <Col md={12}>
                        <p className="text-center">
                          By continuing, you agree to {logoUrl?.companyName}’s{" "}
                          <Link
                            to="/terms-condition"
                            state={{ activeKey: "terms" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Conditions of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Privacy Policy
                          </Link>
                          .
                        </p>
                        <hr />
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default NewEmailOtp;
