import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, CardBody } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCartPage";
import { PaymentError } from "../../constants/validateMsgConstant";
import { OrderConfirmation } from "../../utils/generalMethod";
import { resourceconstant } from "../../constants";
import { GetData } from "../../providers/queryData";
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
const PaymentMethod = () => {
    const navigate = useNavigate()
    const [updateData, setUpdateData] = useState({})
    const [deleteResponse, setDeleteResponse] = useState({})
    const [cartPageItem, setCartPageItem] = useCart()
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
    const [paymentError, setPaymentError] = useState('')
    const location = useLocation()
    const buyData = useMemo(() => {
        return localStorage.getItem('buyNow');
    }, []);
    const [selectedOption, setSelectedOption] = useState('');
    const [paymentData, setPaymentData] = useState([])
    const [paymentModeData, setPaymentModeData] = useState([])
    const [selectedMethod, setSelectedMethod] = useState('');
    const [selectedProvider, setSelectedProvider] = useState('');
    const [processedPaymentModeData, setProcessedPaymentModeData] = useState([]);
    const PaymentMethod = async () => {
        let filter = {
        }
        GetData(resourceconstant.PAYMENT_MODE, filter, setPaymentData)
    };

    useEffect(() => {
        PaymentMethod()
    }, [])

    useEffect(() => {
        if (paymentData?.severity === "success") {
            const item = paymentData?.data?.data?.listPaymentModes?.items
            const filtered = item?.filter(data => data?.status === "ACTIVE")
            setPaymentModeData(filtered)
        }
    }, [paymentData])

    useEffect(() => {
        if (deleteResponse?.status === true) {
            localStorage.removeItem('cartData');
            setCartPageItem([]);
        }
    }, [deleteResponse]);

    const handleMethodChange = (event, item) => {
        setSelectedMethod(event.target.value);
        const firstProvider = item?.provider?.split(',')[0] || '';
        setSelectedProvider(firstProvider);
        setPaymentError('');
    };

    const handleProviderChange = (event) => {
        setSelectedProvider(event.target.value);
        setPaymentError('');
    };

    const HandleOrderConfirmation = async (e) => {
        e.preventDefault()
        setIsUpdateButtonDisabled(true)
        let data = {}
        if (selectedMethod === "CashOnDelivery") {
            data = {
                paymentMethod: selectedMethod,
                paymentStatus: "PENDING",
                orderid: localStorage.getItem('orderId'),
                paymentDate: "",
                referenceNumber: "",
                receivedAmount: ""
            }
        } else {
            navigate("/qPayPayment")
        }
        try {
            OrderConfirmation(data, setUpdateData, setDeleteResponse)

        }
        catch (err) {
            showErrorToastr(err.message)
        }

    };

    useEffect(() => {
        if (updateData?.message === "Success") {
            const id = localStorage.getItem('orderId')
            if (selectedMethod === "CashOnDelivery") {
                showSuccessToastr(`Order ${id} created successfully`)
            }
            if (!buyData) {
                localStorage.removeItem('cartData');
                setCartPageItem([])
            }
            localStorage.setItem('orderConfirm', true)
            navigate('/order-confirm');
        }
    }, [updateData]);

    useEffect(() => {
        if (paymentModeData && paymentModeData.length > 0) {
            const mergedData = paymentModeData.reduce((acc, item) => {
                const existingItem = acc.find(data => data.method === item.method);
                if (existingItem) {
                    existingItem.providers = existingItem.providers || [];
                    existingItem.providers.push({
                        provider: item.provider,
                        logoUrl: item.logoUrl
                    });
                } else {
                    acc.push({
                        method: item.method,
                        providers: item.provider ? [{
                            provider: item.provider,
                            logoUrl: item.logoUrl
                        }] : [{
                            provider: null,
                            logoUrl: item.logoUrl
                        }]
                    });
                }
                return acc;
            }, []);

            mergedData.forEach(item => {
                const uniqueProviders = item.providers.filter((providerItem, index, self) =>
                    providerItem.provider && self.findIndex(p => p.provider === providerItem.provider) === index
                );
                if (uniqueProviders.length === 0 && item.providers[0].provider === null) {
                    uniqueProviders.push(item.providers[0]);
                }
                item.providers = uniqueProviders;
            });

            setProcessedPaymentModeData(mergedData);

            const initialMethod = mergedData[0]?.method;
            setSelectedMethod(initialMethod);
            const initialProviders = mergedData[0]?.providers || [];
            setSelectedProvider(initialProviders[0]?.provider || '');
        }
    }, [paymentModeData]);

    const splitCamelCase = (str) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2');
    }


    return (<>
        <CardBody>
            <Row style={{ 'borderBottom': '1px solid #bab3b3' }} className='mb-2'>

                <Col md={4} className='mb-2'>
                </Col>
                <Col md={4} className='mb-2'>
                </Col>
                <Col md={3} className='mb-2'>
                </Col>
                <div>
                    <>
                        {processedPaymentModeData?.map((item, index) => (
                            <Col key={index} md={12} className='mb-2'>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentMethod"
                                        id={`paymentMethod-${index}`}
                                        value={item.method}
                                        checked={selectedMethod === item.method}
                                        onChange={(event) => handleMethodChange(event, item)}
                                    />
                                    <label className="form-check-label" htmlFor={`paymentMethod-${index}`}>
                                        {splitCamelCase(item.method)}
                                    </label>
                                    {selectedMethod === item.method && item.providers.length > 0 && (
                                        <div className="selected-method-providers d-flex gap-3 mt-2 align-items-center">
                                            {item.providers.map((providerItem, providerIndex) => (
                                                providerItem.provider && (
                                                    <div key={providerIndex} >
                                                        <div
                                                            name="paymentProvider"
                                                            id={`paymentProvider-${providerIndex}`}
                                                            value={providerItem.provider}
                                                            onChange={handleProviderChange}
                                                        />
                                                        <div className="form-check-label d-flex flex-column align-items-center" htmlFor={`paymentProvider-${providerIndex}`}>
                                                            <img src={providerItem.logoUrl} alt={`${providerItem.provider} logo`} />
                                                            <p>{providerItem.provider}</p>
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        ))}
                        {paymentError && <div className="text-danger">{paymentError}</div>}
                    </>
                </div>
                <Col md={12} className='mb-3'>
                    <button type='button' className='btn' onClick={HandleOrderConfirmation} disabled={isUpdateButtonDisabled}>{selectedMethod === "CashOnDelivery" ? "Confirm Order" : "Make Payment"}</button>                </Col>
            </Row>
        </CardBody>
    </>
    )
}

export default PaymentMethod