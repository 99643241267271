import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, CardBody } from 'react-bootstrap';
import Footer from "../../../pages/footer";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AccountDelete from "../../../assets/images/products/delete-account.png"
import { AccountFieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { CreateEntry } from "../../../providers/queryData";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import useProfile from "../../../hooks/useProfile";
import useUserDetails from '../../../hooks/useUserDetails'
import { showSuccessToastr, showErrorToastr } from "../../../common/toastrService";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
    email: '',
}
const DeleteAccount = ({ emailOtpChange }) => {
    const navigate = useNavigate();
    const [useuserDetails, setUseUserDetails] = useUserDetails();
    const [userDetails, setUserDetails] = useProfile()
    const [deleteOtp, setDeleteOtp] = useState([])
    const [isChecked, setIsChecked] = useState(false);
    const [logoUrl, setLogoUrl] = useLogoImage();

    const DeleteAccountOtp = async (e) => {
        e.preventDefault();
        try {
            const data = {
                user_id: useuserDetails?.id || userDetails?.attributes?.sub,
                email: useuserDetails?.email || userDetails?.attributes?.email
            };
            await CreateEntry(resourceconstant?.USER_DELETE, true, setDeleteOtp, data)
        } catch (error) {
            if (error.response) {
                customLogger.error(error.response.data);
            }
        }
    };

    useEffect(() => {
        if (deleteOtp?.status === true) {
            showSuccessToastr(deleteOtp?.message)
            navigate('/account-deletion-otp')
        }
        else if (deleteOtp?.status === false) {
            showErrorToastr(deleteOtp?.message);
        }
    }, [deleteOtp])

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <section id="deleteAccountSection">
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12} className="mt-3">
                                            <Link to="/profile" style={{ color: "inherit" }} className="text-decoration-none">
                                                <div class="back-btn">
                                                    <i className="fa fa-chevron-left"></i>
                                                </div>
                                            </Link>
                                            <h1>Delete Account</h1>
                                        </Col>
                                        <Col md={12} className="text-center mb-3 delete-acc-icon">
                                            <img src={AccountDelete} />
                                        </Col>
                                        <Col md={12}>
                                            <h4>Terms and Conditions for Account Deletion</h4>
                                        </Col>
                                        <Col md={12}>
                                            <p>1. Data Deletion</p>
                                            <p><strong>Immediate Effects:</strong>Explain that deleting the account will remove all user data from the platform, including personal information, order history, and saved preferences.<br />
                                                <strong> Data Retention:</strong> Specify any data that may be retained after account deletion for legal or regulatory reasons, such as transaction records for financial auditing. Timeframe for Deletion: Inform users about the time it will take to completely remove their data from all systems.</p>
                                            <p><strong>2. Service Access Loss of Access:</strong> Clearly state that deleting the account will result in loss of access to all services, features, and any in-app purchases or content. No Reinstatement: Mention that once the account is deleted, it cannot be reinstated, and the same username or email may not be available for future registrations. </p>
                                            <p><strong>3. Subscriptions and Paid Services Handling of Subscriptions:</strong> Detail the process for any ongoing subscriptions or services. Specify whether there will be automatic cancellation or if the user needs to cancel these services separately. Refunds: Outline the policy for any potential refunds or lack thereof, explaining how outstanding balances will be handled. </p>
                                            <p><strong>4. Third-party Services Disassociation: </strong>Explain that deleting the account may not remove data shared with third-party services and that users may need to contact those services separately. </p>
                                            <p><strong>5. Liability User Responsibilities:</strong> Clarify that the user is responsible for any activity on the account until it is closed and for securing any data they wish to keep before deletion. Limitation of Liability: Include a disclaimer stating the company’s non-liability for any consequences resulting directly or indirectly from the account deletion process. </p>
                                            <p><strong>6. Changes to Terms and Conditions Updates:</strong> Notify users that the terms and conditions related to account deletion may be updated and how they will be informed of these changes (e.g., via email or notifications within the app).</p>
                                        </Col>
                                        <Col md={12} className="d-flex">
                                            <input
                                                name="default"
                                                type="checkbox"
                                                className="mb-3"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <p className="text-center">
                                                By continuing, you agree to {logoUrl?.comapnyName}’s{" "}
                                                <Link
                                                    to="/terms-condition"
                                                    state={{ activeKey: "terms" }}
                                                    onClick={() => {
                                                        window.scroll(0, 0);
                                                    }}>
                                                    Conditions of Use
                                                </Link>{" "}
                                                and{" "}
                                                <Link
                                                    to="/privacy-policy"
                                                    state={{ activeKey: "privacy" }}
                                                    onClick={() => {
                                                        window.scroll(0, 0);
                                                    }}>
                                                    Privacy Policy
                                                </Link>
                                                .
                                            </p>
                                        </Col>
                                        <Col sm={12} md={12} className="d-flex justify-content-end align-items-center gap-2 delete-buttons">
                                            <button className="btn shop-btn" onClick={(e) => DeleteAccountOtp(e)} disabled={!isChecked}>
                                                Delete
                                            </button>
                                            <Link to="/profile" style={{ color: "inherit" }} className="text-decoration-none">
                                                <button className="btn cancel-btn">
                                                    Cancel
                                                </button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default DeleteAccount;