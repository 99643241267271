import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Stepper from "@keyvaluesystems/react-stepper";
import { DateFormatShow } from "../utils/validate"
import moment from "moment"
import {GetReturnStatus} from "../utils/generalMethod"
import useLogoImage from '../hooks/useLogoImage';

export default function ReturnUpdates({ returnModalShow, setReturnModalShow, orderItem ,orderDetail}) {
    const [updateStep, setUpdateStep] = useState([])
    const [logoUrl, setLogoUrl] = useLogoImage();
    useEffect(() => {
        if (orderItem) {
            let arrStep = []
            orderItem?.returnRefundActivity?.returnStatusUpdate?.map((item, index) => {
                arrStep.push({
                    "stepLabel": <p>{GetReturnStatusDate(item,orderItem)}</p>,
                    "stepDescription": <span  dangerouslySetInnerHTML={{ __html: GetReturnStatus(item,orderItem,orderDetail,logoUrl) }}></span>,
                    "completed": (item?.status === "Requested" || orderItem?.returnRefundActivity?.returnStatusUpdate?.length > (index+1)) ? true : false,
                })

            })
            setUpdateStep(arrStep)
        }
    }, [orderItem])

    const GetReturnStatusDate = (item,orderItem) =>{
        return <p>{DateFormatShow(item?.updatedAt,false)}<span>{moment(item?.updatedAt).format("hh:mm a")}</span></p>
    }
    
    return (
        <div>
            {/* Viewall updates modal */}
            <Modal show={returnModalShow} onHide={() => setReturnModalShow(false)} size="md" className='update-modal' aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className='pb-1'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Track Return
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='order-updates'>
                        <p>Reference No# : <span>{orderItem?.returnRefundActivity?.returnReference}</span></p>
                        <p>Requested On : <span>{DateFormatShow(orderItem?.returnRefundActivity?.createdAt,false)}</span></p>
                    </div>
                    <div className='order-status'>
                        <Stepper showDescriptionsForAllSteps={true}
                            steps={updateStep}
                            currentStepIndex={1}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}