import React from "react";
import ProductList from "../../../common/ProductList";
import ProductListQuery from "../../../common/ProductListQuery"

const AllProductList = () => {
  return (
    <ProductListQuery page="allProducts" allProduct={true} />
  );
};

export default AllProductList;

