import React, { useEffect } from 'react';
import Lottie from "react-lottie";
import animationData from './Animation - 1715598069126.json';

const NoResultsFound = () =>{
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         window.location.href = '/';
    //     }, 3000); // 3 second
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className='not-found'>
            <Lottie options={defaultOptions} width={200} height={200} />
            <div className="n-found my-1">
                <div className='d-flex flex-column align-items-center'>
                    <p>No results found</p>
                </div>
            </div>
        </div>
    )
}

export default NoResultsFound