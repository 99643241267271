import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import ChangeMobile from './profileEdit/changeMobile'
import Amplify, { Auth } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { useNavigate } from "react-router-dom";
import { ValidateName, AccountFieldNameConstant } from "../../constants/validateMsgConstant";
import ValidateField from "../../utils/useValid";
import { UpdateUserAddress } from "../../utils/generalMethod";
import customLogger from "../../common/loggerService";
import { showSuccessToastr, showErrorToastr } from "../../common/toastrService";
import useProfile from "../../hooks/useProfile";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import useUserDetails from '../../hooks/useUserDetails'
import ProfileIcon from '../../assets/images/account/profile.svg';
import Orders from '../../assets/images/account/orders.svg';
import Wishlist from '../../assets/images/account/wishlist.svg';
import Return from '../../assets/images/account/return.svg';
import ManageAddress from '../../assets/images/account/address.svg';
import { Link, useLocation } from "react-router-dom";
import ChangeEmail from "./profileEdit/changeEmail";
import EmailOtp from "./profileEdit/emailOtp";
import NewEmail from "./profileEdit/newEmail";
import NewEmailOtp from "./profileEdit/newEmailOtp";
import MobileOtp from "./profileEdit/mobileOtp";
import ChangePassword from "./profileEdit/changePassword";
import awsmobile from '../../aws-exports';
import { Storage } from 'aws-amplify';
import ProfileImagUpload from '../account/profileImgUpload';
import CameraIcon from '../../assets/images/account/camera.png'
import DeleteIcon from "../../assets/images/icons/delete.png";
import { ToastrMessages } from "../../constants/toastrMessages";
const formValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}
export default function ProfileTab() {
  const navigate = useNavigate()
  const [nameEdit, setNameEdit] = useState(false)
  const [passwordValues, setPasswordValues] = useState(formValues)
  const [passwordErrors, setPasswordErrors] = useState({})
  const [isPasswordSubmit, setIsPasswordSubmit] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [userResponse, setUserResponse] = useState(null)
  const [useuserDetails, setUseUserDetails] = useUserDetails();
  const [userDetails, setUserDetails] = useProfile()
  const [emailChange, setEmailChange] = useState(false)
  const [phoneChange, setPhoneChange] = useState(false)
  const [emailOtpChange, setEmailOtpChange] = useState(false)
  const [emailNewChange, setEmailNewChange] = useState(false)
  const [emailNewOtpChange, setEmailNewOtpChange] = useState(false)
  const [phoneOtp, setPhoneOtp] = useState(false)
  const [passwordChange, setPasswordChange] = useState(false)
  const localName = localStorage.getItem('fullName')
  const [profileImgUpload, setProfileImgUpload] = useState(false);
  const [userNameEdit, setUserNameEdit] = useState(false)
  const [userProfileResponse, setUserProfileResponse] = useState(null)
  const initialFormValues = {
    firstName: useuserDetails?.firstName || '',
    lastName: useuserDetails?.lastName || '',
  };
  const [values, setValues] = useState(initialFormValues);
  const [userImageAddedResponse, setUserImageAddedResponse] = useState(null)

  useEffect(() => {
    const GetUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (!currentUser) navigate('/login')
      }
      catch {
        navigate('/login')
      }
    }
    GetUser()
  }, [])

  const HandleChangeMobile = () => {
    setPhoneChange(true)
    navigate('/change-phoneno')
  }

  const HandleChangeEmail = () => {
    setEmailChange(true)
    navigate('/change-email')
  }

  const HandlePasswordChange = () => {
    setPasswordChange(true)
    navigate('/change-password')
  }
  const HandleDeleteAccount = () => {
    setPasswordChange(true)
    navigate('/delete-account')
  }


  const [profileImage, setProfileImage] = useState(useuserDetails?.profileImageUrl ? useuserDetails?.profileImageUrl : ProfileIcon);

  useEffect(() => {
    setValues({
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
    });
  }, [userDetails]);

  const HandleChange = (e) => {
    const { name, value } = e.target
    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  }

  const HandleBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validation({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: fieldErrors[name]
    });
  };

  const validation = (values) => {
    const errors = {};
    errors.firstName = ValidateField(
      values.firstName,
      AccountFieldNameConstant?.FIRSTNAME,
      [ValidateName?.REQUIRED, ValidateName?.ISTEXT_ONLY],
    );

    errors.lastName = ValidateField(
      values.lastName,
      AccountFieldNameConstant?.LASTNAME,
      [ValidateName?.REQUIRED, ValidateName?.ISTEXT_ONLY],
    );
    let error = {}
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined)
        error[key] = errors[key]
    })
    return error
  }

  const ChangeUserName = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
    setNameEdit(false);
  }

  useEffect(() => {
    const UpdateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          'custom:firstName': values.firstName,
          'custom:lastName': values.lastName,
        })
        const data = {
          pk: useuserDetails?.pk,
          id: useuserDetails?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          userName: `${values?.firstName} ${values?.lastName}`,
          email: useuserDetails?.email,
          status: useuserDetails?.status,
          role: useuserDetails?.role
        };

        UpdateUserAddress(data, setUserResponse);
      } catch (error) {
        showErrorToastr(error.message);
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      UpdateUser();
    }
  }, [errors]);

  useEffect(() => {
    if (userResponse?.severity === "success") {
      localStorage.setItem('fullName', `${values?.firstName} ${values?.lastName}`)
      setUserNameEdit(false)
      showSuccessToastr(ToastrMessages?.USERUPDATED);
      setUseUserDetails(userResponse?.data?.data?.updateUser)
    }
  }, [userResponse]);

  const handleProfileImgUpload = () => {
    setProfileImgUpload(true)
  }

  const HandleUserNameEdit = () => {
    setValues({
      firstName: useuserDetails?.firstName || '',
      lastName: useuserDetails?.lastName || '',
    });
    setUserNameEdit(true)
  }

  const HandleClose = () => {
    setUserNameEdit(false)
  }

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = date.toLocaleTimeString('en-US');
    return { formattedDate, formattedTime };
  };
  const { formattedDate, formattedTime } = formatDateTime(useuserDetails?.loginAt || '');

  const HandleRemoveProfileImage = () => {
    try {
      const data = {
        pk: useuserDetails?.pk || `USER#`,
        id: useuserDetails?.id,
        profileImageUrl: ''
      };

      UpdateUserAddress(data, setUserProfileResponse);
    } catch (error) {
      customLogger.error(error)
    }
  }

  useEffect(() => {
    if (userProfileResponse?.severity === "success") {
      showSuccessToastr(ToastrMessages?.PROFILEDELETED);
      setUseUserDetails(userProfileResponse?.data?.data?.updateUser)
    }
  }, [userProfileResponse])

  useEffect(()=>{
    if(userImageAddedResponse?.severity === "success"){
      showSuccessToastr(ToastrMessages?.PROFILEADDED);
      setUseUserDetails(userImageAddedResponse?.data?.data?.updateUser)
    }
  },[userImageAddedResponse])

  return (
    <section id="account" className='my-1'>
      <Container>
        <Row className='mt-1'>
          <Col md={12} className='account-section' >
            <Tab.Container>
              <Card className='mx-5'>
                <Nav className="flex-column">
                  <CardHeader>
                    <Nav.Item><Nav.Link ><h2>Profile</h2></Nav.Link></Nav.Item>
                  </CardHeader>
                  <CardBody>
                    <>
                      {!emailChange && !emailOtpChange && !emailNewChange && !emailNewOtpChange && !phoneChange && !phoneOtp && !passwordChange && (
                        <div className="account-right">
                          <div className="profile-section">
                            <div className="d-flex justify-content-between flex-column flex-lg-row flex-xl-row flex-xxl-row">
                              <div className="profile">
                                <div style={{ position: 'relative' }}>
                                  <img
                                    src={useuserDetails?.profileImageUrl ? useuserDetails.profileImageUrl : ProfileIcon}
                                    className="img-fluid profile-img"
                                    alt="Profile Icon"
                                  />
                                  {useuserDetails?.profileImageUrl ? (
                                    <div className="camera-icon" onClick={HandleRemoveProfileImage}>
                                      <img src={DeleteIcon} />
                                    </div>
                                  ) : (
                                    <div className="camera-icon" onClick={handleProfileImgUpload}>
                                      <img src={CameraIcon} alt="Camera Icon" />
                                    </div>
                                  )}
                                </div>

                                <div className="user-detail">
                                  {userNameEdit ? (
                                    <>
                                      <Row className="d-flex gap-2 align-items-center justify-content-center mb-2 name">
                                        <Col className="user-name">
                                          <label>First Name</label>
                                          <input class="form-control"
                                            type="text"
                                            name="firstName"
                                            id='firstName'
                                            value={values?.firstName}
                                            onBlur={(e) => HandleBlur(e)}
                                            onChange={(e) => HandleChange(e)} />
                                          {
                                            errors?.firstName && <p className="form-error">{errors?.firstName}</p>
                                          }
                                        </Col>
                                        <Col className="user-name">
                                          <label>Last Name</label>
                                          <input class="form-control"
                                            type="text"
                                            id='lastName'
                                            name="lastName"
                                            value={values?.lastName}
                                            onBlur={(e) => HandleBlur(e)}
                                            onChange={(e) => HandleChange(e)} />
                                          {
                                            errors?.lastName && <p className="form-error">{errors?.lastName}</p>
                                          }
                                        </Col>
                                        <Col className="mt-2 save-btns">
                                          <button class="close-button" onClick={HandleClose}>✖</button>
                                          <button class="tick-button" onClick={(e) => ChangeUserName(e)}>✔</button>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <div className="d-flex mb-2 ms-xl-0 ms-lg-0 ms-xxl-0 ms-4">
                                      <h2>{useuserDetails?.userName || localName}</h2>
                                      <span class="material-symbols-outlined edit-icon" onClick={HandleUserNameEdit}>
                                        border_color
                                      </span>
                                    </div>
                                  )}
                                  {(useuserDetails?.phoneNumber || userDetails?.attributes?.phone_number) && (
                                    <p><span>Phone No: </span>{useuserDetails?.phoneNumber || userDetails?.attributes?.phone_number}</p>
                                  )}
                                  {(useuserDetails?.email || userDetails?.attributes?.email) && (
                                    <p><span>Email: </span>{useuserDetails?.email || userDetails?.attributes?.email}</p>
                                  )}
                                  <p><span>Last Signed in: </span>{formattedDate}, {formattedTime}</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center profile-btns">
                                <div><button className="btn" onClick={HandleChangeEmail}>Change Email</button></div>
                                <div><button className="btn" onClick={HandleChangeMobile}>Change Number</button></div>
                                <div><button className="btn" onClick={HandlePasswordChange}>Change Password</button></div>
                                <div><button className="btn" onClick={HandleDeleteAccount}>Delete Account</button></div>
                              </div>
                            </div>
                          </div>
                          <div classname="quick-menu">
                            <h2>Quick Menu</h2>
                            <div className="manage-list">
                              <Link to="/orders"> <div className="list-box">
                                <img src={Orders} className="img-fluid" />
                                <h4>Orders</h4>
                                <p>View your order status</p>
                              </div></Link>
                              <Link to="/wishlist">
                                <div className="list-box">
                                  <img src={Wishlist} className="img-fluid" />
                                  <h4>Wishlist</h4>
                                  <p>Explore your saved items</p>
                                </div></Link>
                              <Link to="/address"><div className="list-box">
                                <img src={ManageAddress} className="img-fluid" />
                                <h4>Manage Address</h4>
                                <p>Update your address details</p>
                              </div></Link>
                            </div>
                          </div>
                        </div>
                      )}
                      {profileImgUpload && (
                        <ProfileImagUpload
                          userDetails={userDetails}
                          setProfileImage={setProfileImage}
                          setUserImageAddedResponse={setUserImageAddedResponse}                          
                          UpdateUserAddress={UpdateUserAddress}
                          setProfileImgUpload={setProfileImgUpload} />
                      )}
                    </>
                  </CardBody>
                </Nav>
              </Card>
            </Tab.Container>
          </Col>
        </Row>

      </Container>
    </section>

  );
}
