import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";
import MainPage from "./pages/mainPage";
import Home from "./views/home/home";
import MainCategoryList from "./views/products/mainCategoryProducts/mainCategoryList";
import Login from "./views/login/login";
import OtpVerification from "./views/login/otpVerification";
import CreateAccount from "./views/login/createAccount";
import SubCategoryList from "./views/products/subCategoryProducts/subCategoryList";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import awsmobile from "./aws-exports";
import ProductDetail from "./views/products/productDetails/productDetail";
import CartList from "./views/cart/cartList";
import Checkout from "./views/checkout/checkout";
import OrderConfirm from "./views/order/orderConfirm";
import Account from "./views/account/account";
import Contact from "./views/contact/contacts";
import OrderHistory from "./views/account/orderHistory";
import AllProductList from "./views/products/allProducts/allProductList";
import { UserProfileContextProvider } from "./context/UserProfileContext";
import { CountryContextProvider } from "./context/CountryContext";
import { UserDetailsContextProvider } from "./context/UserDetailsContext";
import { ChangeLocationContextProvider } from "./context/ChangeLocationContext"
import ReturnProduct from "./views/account/returnProduct";
import ReturnSummary from "./views/account/returnSummary";
import TermsCondition from "./views/policy/TermsCondition";
import FAQ from "./views/policy/faq";
import { ProtectedRoute } from "./Routes/ProtectedRoute";
import { LoggedIn } from "./providers/queryData";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import ForgotPassword from "./views/login/forgotPassword";
import ResetOtp from "./views/login/resetOtp";
import ResetPassword from "./views/login/resetPassword";
import { PreviousPageProvider } from "./context/usePreviousPage";
import NotFound from "./views/not-found/notFound";
import NoResultsFound from "./views/not-found/noResultsFound";
import { CartPageProvider } from "./context/CartContext";
import ChangeMobile from "./views/account/profileEdit/changeMobile";
import ChangeEmail from "./views/account/profileEdit/changeEmail"
import MobileOtp from "./views/account/profileEdit/mobileOtp";
import { NotificationProvider } from "./context/NotificationContext";
import EmailOtp from "./views/account/profileEdit/emailOtp";
import NewEmail from "./views/account/profileEdit/newEmail"
import NewEmailOtp from "./views/account/profileEdit/newEmailOtp";
import ViewAllNotification from "./pages/notification/viewAllNotifications";
import { SearchProvider } from "./context/SearchContext";
import { SearchValueProvider } from "./context/SearchValueContext";
import ProfileTab from './views/account/profileTab';
import OrdersTab from './views/account/ordersTab';
import AddressTab from "./views/account/addressTab";
import WishlistTab from "./views/account/wishlistTab";
import RefundPolicy from "./views/policy/refund-policy";
import ShippingPolicy from "./views/policy/shipping-policy";
import PrivacyPolicy from "./views/policy/privacy-policy";
import PaymentConfirmation from "./views/checkout/paymentConfirmation";
import QPayPayment from "./views/checkout/qpayPayment";
import PaymentFailed from "./views/checkout/paymentFailed";
import ChangePassword from "./views/account/profileEdit/changePassword";
import DeleteAccount from "./views/account/profileEdit/deleteAccount";
import AccountDeletionOtp from "./views/account/profileEdit/deleteAccountOtp";
import DeleteAccountOtp from "./views/account/profileEdit/deleteAccountOtp";
import DeleteAccountSuccess from "./views/account/profileEdit/deleteAccountSuccess";
import { LogoContextProvider } from "./context/LogoImageUrl";
import CookiePolicy from "./views/policy/cookie-policy";
import QPayPaymentApp from "./views/paymentApp/qpayPaymentApp"
import PaymentConfirmationApp from "./views/paymentApp/paymentConfirmationApp";
import OrderConfirmApp from "./views/paymentApp/orderConfirmApp";
import SearchProducts from "./views/products/allProducts/searchProducts";
import ShareCart from "./views/cart/shareCart";
import PaymentFailedApp from "./views/paymentApp/paymentFailedApp";
import { ProductsContextProvider } from "./context/ProductsContext"
import MapView from "./views/account/MapView"
import CommunityPolicy from "./views/policy/community-policy";
import { Helmet } from 'react-helmet';
import { GetCompanyDetails } from "./utils/generalMethod";
import StoreLocation from "./views/cart/storeLocation";
import ReactGA from 'react-ga4';
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

Amplify.configure(awsmobile);

function App() {
  useEffect(() => {
    ReactGA?.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, [])

  const [logoResponse, setLogoReposnse] = useState(null);

  useEffect(() => {
    const GetCountryDetails = async () => {
      await GetCompanyDetails({}, setLogoReposnse)
    }
    GetCountryDetails();
  }, [])

  useEffect(() => {
    if (logoResponse?.data?.data?.listCompanyDetails?.items[0]?.theme) {
      const logoUrl = logoResponse?.data?.data?.listCompanyDetails?.items[0]
      //font sizes
      document.documentElement.style.setProperty('--fontFamilyRoboto', logoUrl?.theme?.font);
      document.documentElement.style.setProperty('--fontFamilyPoppins', logoUrl?.theme?.font);
      document.documentElement.style.setProperty('--fontFamilyUbuntu', logoUrl?.theme?.font);
      document.documentElement.style.setProperty('--fontFamilyOpenSans', logoUrl?.theme?.font);
      // colors
      document.documentElement.style.setProperty('--headerBackgroundColor', logoUrl?.theme?.headerBackgroundColor);
      document.documentElement.style.setProperty('--headerIconColor', logoUrl?.theme?.headerIconColor);
      document.documentElement.style.setProperty('--headerTextColor', logoUrl?.theme?.headerTextColor);
      document.documentElement.style.setProperty('--navigationMenuColor', logoUrl?.theme?.navigationMenuColor);
      document.documentElement.style.setProperty('--navigationSubMenuColor', logoUrl?.theme?.navigationSubMenuColor);
      document.documentElement.style.setProperty('--footerBackgroundColor', logoUrl?.theme?.footerBackgroundColor);
      document.documentElement.style.setProperty('--footerHeadingTextColor', logoUrl?.theme?.footerHeadingColor);
      document.documentElement.style.setProperty('--footerTextColor', logoUrl?.theme?.footerTextColor);
      document.documentElement.style.setProperty('--primaryButtonBackgroundColor', logoUrl?.theme?.primaryButtonBackgroundColor);
      document.documentElement.style.setProperty('--primaryButtonTextColor', logoUrl?.theme?.primaryButtonTextColor);
      document.documentElement.style.setProperty('--secondaryButtonBackgroundColor', logoUrl?.theme?.secondaryButtonBackgroundColor);
      document.documentElement.style.setProperty('--secondaryButtonTextColor', logoUrl?.theme?.secondaryButtonTextColor);
      //font sizes
      document.documentElement.style.setProperty('--navigationMenuFontSize14px', logoUrl?.theme?.navigationMenu?.fontSize);
      document.documentElement.style.setProperty('--navigationMenuFontWeight600', logoUrl?.theme?.navigationMenu?.fontWeight);
      document.documentElement.style.setProperty('--navigationSubMenuFontSize16px', logoUrl?.theme?.navigationSubMenu?.fontSize);
      document.documentElement.style.setProperty('--navigationSubMenuFontWeight600', logoUrl?.theme?.navigationSubMenu?.fontWeight);

      document.documentElement.style.setProperty('--productTitleFontSize14px', logoUrl?.theme?.productTitle?.fontSize);
      document.documentElement.style.setProperty('--productTitleFontWeight400', logoUrl?.theme?.productTitle?.fontWeight);
      document.documentElement.style.setProperty('--productSubTitleFontSize16px', logoUrl?.theme?.productSubTitle?.fontSize);
      document.documentElement.style.setProperty('--productSubTitleFontWeight400', logoUrl?.theme?.productSubTitle?.fontWeight);
      document.documentElement.style.setProperty('--productPriceFontSize18px', logoUrl?.theme?.productPrice?.fontSize);
      document.documentElement.style.setProperty('--productPriceFontWeight600', logoUrl?.theme?.productPrice?.fontWeight);

      document.documentElement.style.setProperty('--productDetailsTitleFontSize20px', logoUrl?.theme?.productDetailName?.fontSize);
      document.documentElement.style.setProperty('--productDetailsTitleFontWeight600', logoUrl?.theme?.productDetailName?.fontWeight);
      document.documentElement.style.setProperty('--productDetailsSubTitleFontSize16px', logoUrl?.theme?.productDetailSubTitle?.fontSize);
      document.documentElement.style.setProperty('--productDetailsSubTitleFontWeight400', logoUrl?.theme?.productDetailSubTitle?.fontWeight);
      document.documentElement.style.setProperty('--productDetailsPriceFontSize22px', logoUrl?.theme?.productDetailPrice?.fontSize);
      document.documentElement.style.setProperty('--productDetailsPriceFontWeight600', logoUrl?.theme?.productDetailPrice?.fontWeight);

      document.documentElement.style.setProperty('--customerReviewsTitleFontSize14px', logoUrl?.theme?.customerReviewsTitle?.fontSize);
      document.documentElement.style.setProperty('--customerReviewsTitleFontWeight600', logoUrl?.theme?.customerReviewsTitle?.fontWeight);
      document.documentElement.style.setProperty('--customerReviewsDetailsFontSize14px', logoUrl?.theme?.customerReviewsDetails?.fontSize);
      document.documentElement.style.setProperty('--customerReviewsDetailsFontWeight400', logoUrl?.theme?.customerReviewsDetails?.fontWeight);

      document.documentElement.style.setProperty('--notificationsTitleFontSize15px', logoUrl?.theme?.notificationsTitle?.fontSize);
      document.documentElement.style.setProperty('--notificationsTitleFontWeight500', logoUrl?.theme?.notificationsTitle?.fontWeight);
      document.documentElement.style.setProperty('--notificationsSubTitleFontSize12px', logoUrl?.theme?.notificationsSubTitle?.fontSize);
      document.documentElement.style.setProperty('--notificationsSubTitleFontWeight500', logoUrl?.theme?.notificationsSubTitle?.fontWeight);

      document.documentElement.style.setProperty('--profileHeadingFontSize16px', logoUrl?.theme?.profileHeading?.fontSize);
      document.documentElement.style.setProperty('--profileHeadingFontWeight600', logoUrl?.theme?.profileHeading?.fontWeight);
      document.documentElement.style.setProperty('--profileContentFontSize14px', logoUrl?.theme?.profileContent?.fontSize);
      document.documentElement.style.setProperty('--profileContentFontWeight500', logoUrl?.theme?.profileContent?.fontWeight);

      document.documentElement.style.setProperty('--othersContentTitleFontSize24px', logoUrl?.theme?.contentTitle?.fontSize);
      document.documentElement.style.setProperty('--othersContentTitleFontWeight600', logoUrl?.theme?.contentTitle?.fontWeight);
      document.documentElement.style.setProperty('--othersContentDescriptionFontSize16px', logoUrl?.theme?.contentDescription?.fontSize);
      document.documentElement.style.setProperty('--othersContentDescriptionFontWeight400', logoUrl?.theme?.contentDescription?.fontWeight);

      document.documentElement.style.setProperty('--faqTitleHeaderFontSize15px', logoUrl?.theme?.titleHeader?.fontSize);
      document.documentElement.style.setProperty('--faqTitleHeaderFontWeight600', logoUrl?.theme?.titleHeader?.fontWeight);
      document.documentElement.style.setProperty('--faqTitleDetailsFontSize14px', logoUrl?.theme?.titleDetails?.fontSize);
      document.documentElement.style.setProperty('--faqTitleDetailsFontWeight400', logoUrl?.theme?.titleDetails?.fontWeight);

      document.documentElement.style.setProperty('--primaryButtonFontSize14px', logoUrl?.theme?.primaryButton?.fontSize);
      document.documentElement.style.setProperty('--primaryButtonFontWeight500', logoUrl?.theme?.primaryButton?.fontWeight);
      document.documentElement.style.setProperty('--secondaryButtonFontSize14px', logoUrl?.theme?.secondaryButton?.fontSize);
      document.documentElement.style.setProperty('--secondaryButtonFontWeight500', logoUrl?.theme?.secondaryButton?.fontWeight);
    }
  }, [logoResponse?.data?.data?.listCompanyDetails?.items[0]]);

  return (
    <>
      <Helmet>
        <title>{logoResponse?.data?.data?.listCompanyDetails?.items[0]?.brandName || "E-Commerce Website"}</title>
        <meta name="description" content={logoResponse?.data?.data?.listCompanyDetails?.items[0]?.seoDescription || "E-Commerce Websites"} />
      </Helmet>
      <ProductsContextProvider>
        <LogoContextProvider>
          <SearchValueProvider>
            <SearchProvider>
              <NotificationProvider>
                <CartPageProvider>
                  <PreviousPageProvider>
                    <UserProfileContextProvider>
                      <CountryContextProvider>
                        <UserDetailsContextProvider>
                          <ChangeLocationContextProvider>
                            <Routes>
                              <Route path="/login" element={<Login />} />
                              <Route path="/create-account" element={<CreateAccount />} />
                              <Route path="/otp-verification" element={<OtpVerification />} />
                              <Route path="/reset-otp" element={<ResetOtp />} />
                              <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                              />
                              <Route path="/reset-password" element={<ResetPassword />} />
                              <Route path="/qPayPaymentApp" element={<QPayPaymentApp />} />
                              <Route path="/paymentConfirmationApp" element={<PaymentConfirmationApp />} />
                              <Route path="/orderConfirmApp" element={<OrderConfirmApp />} />
                              <Route path="/paymentFailedApp" element={<PaymentFailedApp />} />
                              <Route path="*" element={<NotFound />} />
                              <Route exact path="/" element={<MainPage />}>
                                <Route index element={<Home />} />
                                <Route path="/change-phoneno" element={<ChangeMobile />} />
                                <Route path="/change-email" element={<ChangeEmail />} />
                                <Route path="/change-password" element={<ChangePassword />} />
                                <Route path="/delete-account" element={<DeleteAccount />} />
                                <Route path="/account-deletion-otp" element={<DeleteAccountOtp />} />
                                <Route path="/delete-account-success" element={<DeleteAccountSuccess />} />
                                <Route path="/mobile-otp" element={<MobileOtp />} />
                                <Route path="/email-otp" element={<EmailOtp />} />
                                <Route path="/new-email" element={<NewEmail />} />
                                <Route path="/new-email-otp" element={<NewEmailOtp />} />
                                <Route path="/products/:mainCategory" element={<MainCategoryList />} />
                                <Route
                                  path="/products/:mainCategory/:subCategory"
                                  element={<SubCategoryList />}
                                />
                                <Route
                                  path="/product-detail/:productId"
                                  element={<ProductDetail />}
                                />
                                <Route path="/cart" element={<CartList />} />
                                <Route path="/checkout" element={<Checkout />} />
                                <Route path="/order-confirm" element={<OrderConfirm />} />
                                <Route path="/account" element={<Account />} />
                                <Route path="/contact-us" element={<Contact />} />
                                <Route path="/order-history" element={<OrderHistory />} />
                                <Route path="/return-product" element={<ReturnProduct />} />
                                <Route path="/return-summary" element={<ReturnSummary />} />
                                <Route path="/all-products" element={<AllProductList />} />
                                <Route path="/terms-condition" element={<TermsCondition />} />
                                <Route path="/refund-policy" element={<RefundPolicy />} />
                                <Route path="/shipping-policy" element={<ShippingPolicy />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/cookie-policy" element={<CookiePolicy />} />
                                <Route path="/view-notification" element={<ViewAllNotification />} />
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/no-results" element={<NoResultsFound />} />
                                <Route path="/profile" element={<ProfileTab />} />
                                <Route path="/orders" element={<OrdersTab />} />
                                <Route path="/address" element={<AddressTab />} />
                                <Route path="/wishlist" element={<WishlistTab />} />
                                <Route path="/payment-confirm" element={<PaymentConfirmation />} />
                                <Route path="/qPayPayment" element={<QPayPayment />} />
                                <Route path="/paymentFailed" element={<PaymentFailed />} />

                                <Route path="/search-product" element={<SearchProducts />} />
                                <Route path="/shareCart/:userid" element={<ShareCart />} />
                                <Route path="/mapview" element={<MapView />} />
                                <Route path="/store-location" element={<StoreLocation />} />
                                <Route path="/community-policy" element={< CommunityPolicy />} />
                              </Route>
                            </Routes>
                          </ChangeLocationContextProvider>
                        </UserDetailsContextProvider>
                      </CountryContextProvider>
                    </UserProfileContextProvider>
                  </PreviousPageProvider>
                </CartPageProvider>
              </NotificationProvider>
            </SearchProvider>
          </SearchValueProvider>
        </LogoContextProvider>
      </ProductsContextProvider>
    </>
  );
}
export default App;