import React from 'react';
import { Table } from 'react-bootstrap';
import { FormatValueDecimal } from "../../utils/validate"
import { useNavigate } from 'react-router-dom';
import useLogoImage from '../../hooks/useLogoImage';
const ProductItemApp = ({ orderList, orderItemList }) => {
    const navigate = useNavigate()
    const [logoUrl, setLogoUrl] = useLogoImage();
    return (
        <div className='product-table'>
            <Table>
                <thead>
                    <th width={"50%"}>Item</th>
                    <th className="text-end" width={"20%"}>Unit Price</th>
                    <th className="text-end" width={"10%"}>Tax</th>
                    <th className="text-end" width={"10%"}>Quantity</th>
                    <th className="text-end" width={"20%"}>Amount</th>
                </thead>
                <tbody>
                    {orderItemList.map((item) => {
                        const regularPrice = item.itemDetail.price
                        const amount = item?.totalPrice
                        const tax = item.taxAmount
                        const handleLinkClick = (e) => {
                            e.preventDefault();
                            navigate(`/product-detail/${item?.productId}`, {
                                state: {
                                    values: {
                                        subproduct_id: item.productId,
                                        allProducts: item.productId,
                                        search: item.productId,
                                    },
                                },
                            });
                        };
                        return (
                            <tr>
                                <td >
                                    <div className='product-list'>
                                        <div className='pdt-img me-3'>
                                            <img src={item.itemDetail.image} className='img-fluid' onClick={handleLinkClick} style={{ cursor: "pointer" }} />
                                        </div>
                                        <div className='pdt-detail'>
                                            <h6>
                                            {item.itemDetail.productName}
                                            {item.attrValue && ` - ${item.attrName} ${item.attrValue}`}
                                            </h6>
                                            <p className="pdt-model" style={{ color: "black", fontSize: "16px", fontWeight: "400" }}>
                                                <span>Brand: </span>{item?.itemDetail?.brand} {item?.itemDetail?.model && item?.itemDetail?.model !== "null" && ( <> <span>|</span> <span>Model: </span>{item?.itemDetail?.model} </>)} {item?.itemDetail?.sku && item?.itemDetail?.sku !== "null" && ( <> <span>|</span> <span>SKU: </span>{item?.itemDetail?.sku} </>)}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td align='right'>{logoUrl?.currency} {FormatValueDecimal(regularPrice)}</td>
                                <td align='right'>{logoUrl?.currency} {FormatValueDecimal(tax)}</td>
                                <td align='right'>{item.quantity}</td>
                                <td align='right'>{logoUrl?.currency} {FormatValueDecimal(amount)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

export default ProductItemApp