/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSettings = /* GraphQL */ `
  query GetSettings($pk: String!, $id: ID!) {
    getSettings(pk: $pk, id: $id) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        notificationType
        createdAt
        emailTo
        productExpiryDuration
        theme
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const settingsByDate = /* GraphQL */ `
  query SettingsByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingsByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        notificationType
        createdAt
        emailTo
        productExpiryDuration
        theme
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyDetails = /* GraphQL */ `
  query GetCompanyDetails($pk: String!, $id: ID!) {
    getCompanyDetails(pk: $pk, id: $id) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyDetails = /* GraphQL */ `
  query ListCompanyDetails(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelCompanyDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyDetails(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        createdAt
        primaryLogo {
          alterText
          title
          url
          description
          type
          __typename
        }
        secondaryLogo {
          alterText
          title
          url
          description
          type
          __typename
        }
        companyName
        webSite
        phoneNumber
        email
        ccEmail
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        status
        currency
        categorySequenced
        copyrightText
        mobileAppUrls {
          type
          title
          alterText
          url
          certificationName
          redirectTo
          __typename
        }
        theme {
          preferredTheme
          headerBackgroundColor
          headerTextColor
          headerIconColor
          footerBackgroundColor
          footerTextColor
          footerHeadingColor
          font
          navigationMenu {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          navigationMenuColor
          navigationSubMenu {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          navigationSubMenuColor
          productTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productSubTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productPrice {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDetailName {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDetailSubTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDetailPrice {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDescriptionHeading {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDescription {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          customerReviewsTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          customerReviewsDetails {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          notificationsTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          notificationsSubTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          profileHeading {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          profileContent {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          contentTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          contentDescription {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          titleHeader {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          titleDetails {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          primaryButton {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          primaryButtonBackgroundColor
          primaryButtonTextColor
          secondaryButton {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          secondaryButtonBackgroundColor
          secondaryButtonTextColor
          __typename
        }
        mobileTheme {
          locationBackgroundColor
          locationTextColor
          locationIconColor
          searchBarBackgroundColor
          newArrivalsColor
          bestSellersColor
          recentlyViewedColor
          navBackgroundColor
          navIconColor
          font
          productListTitle
          productListBrand
          productListStock
          productListPrice
          productDetailName
          productDetailPrice
          productDetailStock
          productDetailBrand
          customerReviewsTitle
          customerReviewsDetails
          notificationsTitle
          notificationsSubTitle
          profileName
          profileHeadings
          primaryButtonText
          primaryButtonBackgroundColor
          primaryButtonTextColor
          secondaryButtonText
          secondaryButtonBackgroundColor
          secondaryButtonTextColor
          __typename
        }
        seoDescription
        daysToReturn
        brandName
        iconUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companyDetailsByDate = /* GraphQL */ `
  query CompanyDetailsByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyDetailsByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        createdAt
        primaryLogo {
          alterText
          title
          url
          description
          type
          __typename
        }
        secondaryLogo {
          alterText
          title
          url
          description
          type
          __typename
        }
        companyName
        webSite
        phoneNumber
        email
        ccEmail
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        status
        currency
        categorySequenced
        copyrightText
        mobileAppUrls {
          type
          title
          alterText
          url
          certificationName
          redirectTo
          __typename
        }
        theme {
          preferredTheme
          headerBackgroundColor
          headerTextColor
          headerIconColor
          footerBackgroundColor
          footerTextColor
          footerHeadingColor
          font
          navigationMenu {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          navigationMenuColor
          navigationSubMenu {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          navigationSubMenuColor
          productTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productSubTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productPrice {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDetailName {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDetailSubTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDetailPrice {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDescriptionHeading {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          productDescription {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          customerReviewsTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          customerReviewsDetails {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          notificationsTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          notificationsSubTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          profileHeading {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          profileContent {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          contentTitle {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          contentDescription {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          titleHeader {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          titleDetails {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          primaryButton {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          primaryButtonBackgroundColor
          primaryButtonTextColor
          secondaryButton {
            title
            example
            fontWeight
            fontSize
            __typename
          }
          secondaryButtonBackgroundColor
          secondaryButtonTextColor
          __typename
        }
        mobileTheme {
          locationBackgroundColor
          locationTextColor
          locationIconColor
          searchBarBackgroundColor
          newArrivalsColor
          bestSellersColor
          recentlyViewedColor
          navBackgroundColor
          navIconColor
          font
          productListTitle
          productListBrand
          productListStock
          productListPrice
          productDetailName
          productDetailPrice
          productDetailStock
          productDetailBrand
          customerReviewsTitle
          customerReviewsDetails
          notificationsTitle
          notificationsSubTitle
          profileName
          profileHeadings
          primaryButtonText
          primaryButtonBackgroundColor
          primaryButtonTextColor
          secondaryButtonText
          secondaryButtonBackgroundColor
          secondaryButtonTextColor
          __typename
        }
        seoDescription
        daysToReturn
        brandName
        iconUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentMode = /* GraphQL */ `
  query GetPaymentMode($pk: String!, $id: ID!) {
    getPaymentMode(pk: $pk, id: $id) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPaymentModes = /* GraphQL */ `
  query ListPaymentModes(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelPaymentModeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPaymentModes(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        createdAt
        method
        description
        provider
        logoUrl
        stagingUrl
        productionUrl
        status
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentModeByDate = /* GraphQL */ `
  query PaymentModeByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentModeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentModeByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        createdAt
        method
        description
        provider
        logoUrl
        stagingUrl
        productionUrl
        status
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($pk: String!, $id: ID!) {
    getUser(pk: $pk, id: $id) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        userName
        firstName
        lastName
        companyName
        profileImageUrl
        email
        phoneNumber
        role
        password
        multiFactorAuth
        emailNotification
        mandatePasswordChange
        emailVerified
        otp
        status
        searchKey
        addressDetails {
          name
          phoneNumber
          email
          address {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          type
          addressName
          default
          latitude
          longitude
          __typename
        }
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByDate = /* GraphQL */ `
  query UserByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        userName
        firstName
        lastName
        companyName
        profileImageUrl
        email
        phoneNumber
        role
        password
        multiFactorAuth
        emailNotification
        mandatePasswordChange
        emailVerified
        otp
        status
        searchKey
        addressDetails {
          name
          phoneNumber
          email
          address {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          type
          addressName
          default
          latitude
          longitude
          __typename
        }
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $pk: String!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      pk: $pk
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        userName
        firstName
        lastName
        companyName
        profileImageUrl
        email
        phoneNumber
        role
        password
        multiFactorAuth
        emailNotification
        mandatePasswordChange
        emailVerified
        otp
        status
        searchKey
        addressDetails {
          name
          phoneNumber
          email
          address {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          type
          addressName
          default
          latitude
          longitude
          __typename
        }
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($pk: String!, $slug: String!) {
    getCategory(pk: $pk, slug: $slug) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $pk: String
    $slug: ModelStringKeyConditionInput
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      pk: $pk
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        slug
        id
        catName
        mainCategory
        mainCategoryID
        categoryImg
        description
        webBannerImg
        webBannerImgBottom
        mobBannerImg
        mobBannerImgBottom
        categoryType
        searchKey
        catStatus
        sequenceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const categoryByID = /* GraphQL */ `
  query CategoryByID(
    $pk: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByID(
      pk: $pk
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        slug
        id
        catName
        mainCategory
        mainCategoryID
        categoryImg
        description
        webBannerImg
        webBannerImgBottom
        mobBannerImg
        mobBannerImgBottom
        categoryType
        searchKey
        catStatus
        sequenceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($pk: String!, $id: ID!) {
    getBrand(pk: $pk, id: $id) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBrands(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        brandName
        searchKey
        manufacturerName
        status
        countryOfOrigin
        brandLogo
        brandUrl
        brandDescription
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAttributeName = /* GraphQL */ `
  query GetAttributeName($pk: String!, $slug: String!) {
    getAttributeName(pk: $pk, slug: $slug) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAttributeNames = /* GraphQL */ `
  query ListAttributeNames(
    $pk: String
    $slug: ModelStringKeyConditionInput
    $filter: ModelAttributeNameFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAttributeNames(
      pk: $pk
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        slug
        id
        sequenceNumber
        searchKey
        status
        attrName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attributeNameByID = /* GraphQL */ `
  query AttributeNameByID(
    $pk: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttributeNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attributeNameByID(
      pk: $pk
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        slug
        id
        sequenceNumber
        searchKey
        status
        attrName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($pk: String!, $slug: String!) {
    getCountry(pk: $pk, slug: $slug) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $pk: String
    $slug: ModelStringKeyConditionInput
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCountries(
      pk: $pk
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        slug
        id
        name
        shortName
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDropDownMaster = /* GraphQL */ `
  query GetDropDownMaster($pk: String!, $id: ID!) {
    getDropDownMaster(pk: $pk, id: $id) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDropDownMasters = /* GraphQL */ `
  query ListDropDownMasters(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelDropDownMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDropDownMasters(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        value
        status
        searchKey
        sequenceNumber
        createdBy
        updatedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($pk: String!, $id: ID!) {
    getCart(pk: $pk, id: $id) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCarts(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        userId
        productID
        createdAt
        productImage
        productName
        mainCategoryId
        mainCategory
        subCategoryId
        subCategoryN
        attrValueID
        price
        salesPrice
        stockUnit
        itemsPerUnit
        expiryDate
        selectedQuantity
        brandDetail {
          brandID
          brandName
          brandDescription
          manufacturerName
          imageUrl
          __typename
        }
        wareHouseId
        wareHouseName
        attrName
        attrValue
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        userName
        userMail
        model
        cartURL
        taxCode
        taxCharge
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        sku
        searchKey
        totalPrice
        mobileNumber
        currentStock
        brandId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cartByDate = /* GraphQL */ `
  query CartByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        productID
        createdAt
        productImage
        productName
        mainCategoryId
        mainCategory
        subCategoryId
        subCategoryN
        attrValueID
        price
        salesPrice
        stockUnit
        itemsPerUnit
        expiryDate
        selectedQuantity
        brandDetail {
          brandID
          brandName
          brandDescription
          manufacturerName
          imageUrl
          __typename
        }
        wareHouseId
        wareHouseName
        attrName
        attrValue
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        userName
        userMail
        model
        cartURL
        taxCode
        taxCharge
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        sku
        searchKey
        totalPrice
        mobileNumber
        currentStock
        brandId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cartByUserId = /* GraphQL */ `
  query CartByUserId(
    $pk: String!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartByUserId(
      pk: $pk
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        productID
        createdAt
        productImage
        productName
        mainCategoryId
        mainCategory
        subCategoryId
        subCategoryN
        attrValueID
        price
        salesPrice
        stockUnit
        itemsPerUnit
        expiryDate
        selectedQuantity
        brandDetail {
          brandID
          brandName
          brandDescription
          manufacturerName
          imageUrl
          __typename
        }
        wareHouseId
        wareHouseName
        attrName
        attrValue
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        userName
        userMail
        model
        cartURL
        taxCode
        taxCharge
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        sku
        searchKey
        totalPrice
        mobileNumber
        currentStock
        brandId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWareHouse = /* GraphQL */ `
  query GetWareHouse($pk: String!, $id: ID!) {
    getWareHouse(pk: $pk, id: $id) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWareHouses = /* GraphQL */ `
  query ListWareHouses(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelWareHouseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWareHouses(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        name
        createdAt
        addressLine1
        addressLine2
        searchKey
        countryId
        country
        state
        city
        zipCode
        contactPerson
        email
        phoneNumber
        workingHoursFrom
        workingHoursTo
        status
        latitude
        longitude
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wareHouseByDate = /* GraphQL */ `
  query WareHouseByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWareHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wareHouseByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        name
        createdAt
        addressLine1
        addressLine2
        searchKey
        countryId
        country
        state
        city
        zipCode
        contactPerson
        email
        phoneNumber
        workingHoursFrom
        workingHoursTo
        status
        latitude
        longitude
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneralContactUs = /* GraphQL */ `
  query GetGeneralContactUs($pk: String!, $id: ID!) {
    getGeneralContactUs(pk: $pk, id: $id) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeneralContactuses = /* GraphQL */ `
  query ListGeneralContactuses(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelGeneralContactUsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneralContactuses(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        referenceNumber
        userName
        email
        phoneNumber
        companyName
        subject
        message
        replyMessage
        enquiryStatus
        attachments
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($pk: String!, $id: ID!) {
    getNotifications(pk: $pk, id: $id) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        userId
        userName
        notificationType
        Title
        Message
        orderID
        DateTime
        notificationStatus
        notificationModule
        referenceID
        targetURL
        searchKey
        expiryDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationByDate = /* GraphQL */ `
  query NotificationByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        userName
        notificationType
        Title
        Message
        orderID
        DateTime
        notificationStatus
        notificationModule
        referenceID
        targetURL
        searchKey
        expiryDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModuleMaster = /* GraphQL */ `
  query GetModuleMaster($pk: String!, $id: ID!) {
    getModuleMaster(pk: $pk, id: $id) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listModuleMasters = /* GraphQL */ `
  query ListModuleMasters(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelModuleMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModuleMasters(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        moduleName
        sequenceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleManagement = /* GraphQL */ `
  query GetRoleManagement($pk: String!, $id: ID!) {
    getRoleManagement(pk: $pk, id: $id) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const listRoleManagements = /* GraphQL */ `
  query ListRoleManagements(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelRoleManagementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoleManagements(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        menuId
        roleName
        module {
          moduleId
          menu
          view
          add
          edit
          delete
          __typename
        }
        status
        searchKey
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleByStatus = /* GraphQL */ `
  query RoleByStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoleManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleByStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        menuId
        roleName
        module {
          moduleId
          menu
          view
          add
          edit
          delete
          __typename
        }
        status
        searchKey
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($pk: String!, $id: ID!) {
    getContent(pk: $pk, id: $id) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContents(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        contentName
        title
        sequenceNumber
        content
        searchKey
        docUrl
        visibleToOthers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($pk: String!, $id: ID!) {
    getFAQ(pk: $pk, id: $id) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFAQS(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        type
        sequenceNumber
        name
        FAQSections {
          question
          answer
          __typename
        }
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductReview = /* GraphQL */ `
  query GetProductReview($pk: String!, $id: ID!) {
    getProductReview(pk: $pk, id: $id) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductReviews = /* GraphQL */ `
  query ListProductReviews(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelProductReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductReviews(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        orderItemId
        productId
        productImage
        productName
        userId
        userName
        email
        overallRating
        title
        reviewComment
        removeAbusiveComment
        abusiveReportedBy {
          userId
          userName
          email
          phoneNumber
          reasons
          reportedDate
          __typename
        }
        attachments
        createdAt
        reply
        repliedOnDate
        orderedDate
        reviewedDate
        reviewStatus
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productReviewByOrderItemId = /* GraphQL */ `
  query ProductReviewByOrderItemId(
    $pk: String!
    $orderItemId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productReviewByOrderItemId(
      pk: $pk
      orderItemId: $orderItemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        orderItemId
        productId
        productImage
        productName
        userId
        userName
        email
        overallRating
        title
        reviewComment
        removeAbusiveComment
        abusiveReportedBy {
          userId
          userName
          email
          phoneNumber
          reasons
          reportedDate
          __typename
        }
        attachments
        createdAt
        reply
        repliedOnDate
        orderedDate
        reviewedDate
        reviewStatus
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productReviewByProductId = /* GraphQL */ `
  query ProductReviewByProductId(
    $productId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productReviewByProductId(
      productId: $productId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        orderItemId
        productId
        productImage
        productName
        userId
        userName
        email
        overallRating
        title
        reviewComment
        removeAbusiveComment
        abusiveReportedBy {
          userId
          userName
          email
          phoneNumber
          reasons
          reportedDate
          __typename
        }
        attachments
        createdAt
        reply
        repliedOnDate
        orderedDate
        reviewedDate
        reviewStatus
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTokenDetails = /* GraphQL */ `
  query GetTokenDetails($pk: String!, $id: ID!) {
    getTokenDetails(pk: $pk, id: $id) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTokenDetails = /* GraphQL */ `
  query ListTokenDetails(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTokenDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTokenDetails(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        expiresIn
        token
        tokenType
        userID
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tokebByUserId = /* GraphQL */ `
  query TokebByUserId(
    $pk: String!
    $userID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTokenDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tokebByUserId(
      pk: $pk
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        expiresIn
        token
        tokenType
        userID
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTax = /* GraphQL */ `
  query GetTax($pk: String!, $id: ID!) {
    getTax(pk: $pk, id: $id) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTaxes = /* GraphQL */ `
  query ListTaxes(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTaxes(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        taxCode
        taxType
        status
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const taxByCode = /* GraphQL */ `
  query TaxByCode(
    $pk: String!
    $taxCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taxByCode(
      pk: $pk
      taxCode: $taxCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        taxCode
        taxType
        status
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCarouselImage = /* GraphQL */ `
  query GetCarouselImage($pk: String!, $id: String!) {
    getCarouselImage(pk: $pk, id: $id) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCarouselImages = /* GraphQL */ `
  query ListCarouselImages(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelCarouselImageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCarouselImages(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        type
        deviceType
        animation
        pauseTime
        transitionSpeed
        stopAnimation
        displayArrows
        displayNavigationBullets
        image {
          alterText
          title
          url
          description
          type
          __typename
        }
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWishList = /* GraphQL */ `
  query GetWishList($pk: String!, $id: ID!) {
    getWishList(pk: $pk, id: $id) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWishLists = /* GraphQL */ `
  query ListWishLists(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelWishListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWishLists(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        userId
        productId
        productName
        productImage
        mainCategory
        mainCategoryId
        subCategory
        subCategoryId
        brandName
        brandId
        model
        rating
        regularPrice
        salePrice
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wishListByUserId = /* GraphQL */ `
  query WishListByUserId(
    $pk: String!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWishListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wishListByUserId(
      pk: $pk
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        productId
        productName
        productImage
        mainCategory
        mainCategoryId
        subCategory
        subCategoryId
        brandName
        brandId
        model
        rating
        regularPrice
        salePrice
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wishListByProductId = /* GraphQL */ `
  query WishListByProductId(
    $pk: String!
    $productId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWishListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wishListByProductId(
      pk: $pk
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        productId
        productName
        productImage
        mainCategory
        mainCategoryId
        subCategory
        subCategoryId
        brandName
        brandId
        model
        rating
        regularPrice
        salePrice
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($pk: String!, $id: ID!) {
    getConfiguration(pk: $pk, id: $id) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConfigurations(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        configurationDescription
        continuousSettings {
          prefixValue
          numberOfDigits
          includeYearSuffix
          startingNumber
          runningNumber
          __typename
        }
        type
        modifiedTime
        modifiedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMailLog = /* GraphQL */ `
  query GetMailLog($pk: String!, $id: ID!) {
    getMailLog(pk: $pk, id: $id) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const listMailLogs = /* GraphQL */ `
  query ListMailLogs(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelMailLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMailLogs(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        referenceId
        referenceForm
        userId
        mailDetails {
          sentTo
          sentDate
          __typename
        }
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeliveryPartner = /* GraphQL */ `
  query GetDeliveryPartner($pk: String!, $id: ID!) {
    getDeliveryPartner(pk: $pk, id: $id) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDeliveryPartners = /* GraphQL */ `
  query ListDeliveryPartners(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelDeliveryPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeliveryPartners(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        logo
        logoUrl
        companyName
        displayName
        apiKey
        apiKeyExpiryDate
        contactPerson
        address
        email
        phoneNumber
        status
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLedger = /* GraphQL */ `
  query GetLedger($pk: String!, $id: ID!) {
    getLedger(pk: $pk, id: $id) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLedgers = /* GraphQL */ `
  query ListLedgers(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelLedgerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLedgers(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        type
        transDate
        transReference
        transDetails
        transTo
        transToId
        transAmount
        transType
        paymentStatus
        buyerName
        orderRef
        orderAmount
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ledgerByTransDate = /* GraphQL */ `
  query LedgerByTransDate(
    $pk: String!
    $transDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLedgerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ledgerByTransDate(
      pk: $pk
      transDate: $transDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        type
        transDate
        transReference
        transDetails
        transTo
        transToId
        transAmount
        transType
        paymentStatus
        buyerName
        orderRef
        orderAmount
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ledgerByTransReference = /* GraphQL */ `
  query LedgerByTransReference(
    $pk: String!
    $transReference: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLedgerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ledgerByTransReference(
      pk: $pk
      transReference: $transReference
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        type
        transDate
        transReference
        transDetails
        transTo
        transToId
        transAmount
        transType
        paymentStatus
        buyerName
        orderRef
        orderAmount
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ledgerByTransToId = /* GraphQL */ `
  query LedgerByTransToId(
    $pk: String!
    $transToId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLedgerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ledgerByTransToId(
      pk: $pk
      transToId: $transToId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        type
        transDate
        transReference
        transDetails
        transTo
        transToId
        transAmount
        transType
        paymentStatus
        buyerName
        orderRef
        orderAmount
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductVisit = /* GraphQL */ `
  query GetProductVisit($pk: String!, $id: ID!) {
    getProductVisit(pk: $pk, id: $id) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductVisits = /* GraphQL */ `
  query ListProductVisits(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelProductVisitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductVisits(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        prodId
        userId
        userName
        visitedAt
        place
        visitedInfo {
          platform
          location
          time
          ipAddress
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productVisitByVisitedAt = /* GraphQL */ `
  query ProductVisitByVisitedAt(
    $pk: String!
    $visitedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productVisitByVisitedAt(
      pk: $pk
      visitedAt: $visitedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        prodId
        userId
        userName
        visitedAt
        place
        visitedInfo {
          platform
          location
          time
          ipAddress
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromoCodes = /* GraphQL */ `
  query GetPromoCodes($pk: String!, $id: ID!) {
    getPromoCodes(pk: $pk, id: $id) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelPromoCodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPromoCodes(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        promoImage
        promoURL
        code
        discount {
          type
          value
          __typename
        }
        redemptionType
        searchKey
        validFrom
        validTo
        description
        terms
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const promoCodesByDate = /* GraphQL */ `
  query PromoCodesByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromoCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoCodesByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        promoImage
        promoURL
        code
        discount {
          type
          value
          __typename
        }
        redemptionType
        searchKey
        validFrom
        validTo
        description
        terms
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJwtTokenIdentifier = /* GraphQL */ `
  query GetJwtTokenIdentifier($pk: String!, $jtiValue: String!) {
    getJwtTokenIdentifier(pk: $pk, jtiValue: $jtiValue) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJwtTokenIdentifiers = /* GraphQL */ `
  query ListJwtTokenIdentifiers(
    $pk: String
    $jtiValue: ModelStringKeyConditionInput
    $filter: ModelJwtTokenIdentifierFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJwtTokenIdentifiers(
      pk: $pk
      jtiValue: $jtiValue
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        jtiValue
        issueDate
        expiryDateTime
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnits = /* GraphQL */ `
  query GetUnits($pk: String!, $id: ID!) {
    getUnits(pk: $pk, id: $id) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelUnitsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUnits(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        value
        sequenceNumber
        searchKey
        type
        status
        createdBy
        updatedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const unitsByType = /* GraphQL */ `
  query UnitsByType(
    $pk: String!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUnitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsByType(
      pk: $pk
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        value
        sequenceNumber
        searchKey
        type
        status
        createdBy
        updatedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShipping = /* GraphQL */ `
  query GetShipping($pk: String!, $id: ID!) {
    getShipping(pk: $pk, id: $id) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
export const listShippings = /* GraphQL */ `
  query ListShippings(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelShippingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShippings(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        city
        countryId
        countryName
        state
        deliveryCharge
        coverage
        zipCodes
        latitude
        longitude
        radius
        searchKey
        status
        createdAt
        updatedAt
        createdBy
        modifiedBy
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($pk: String!, $id: ID!) {
    getProduct(pk: $pk, id: $id) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        id
        pk
        globalPk
        productName
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        brandName
        brandID
        countryName
        countryID
        productDescription
        productFeatures
        keyWord
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        galleryImages {
          alterText
          title
          url
          description
          type
          __typename
        }
        singleVariant
        attrName
        attrNameID
        attrValue {
          value
          attrValueID
          __typename
        }
        productSpecification {
          name
          value
          __typename
        }
        seoDescription
        stockStatus
        searchKey
        status
        scheduleDate
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        showWhenZeroStock
        allowPreOrder
        overallRating
        noOfRating
        leadTime
        totalStockQuantity
        totalReservedStock
        displaySalePrice
        displayRegularPrice
        thumbnailUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productById = /* GraphQL */ `
  query ProductById(
    $id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productById(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        pk
        globalPk
        productName
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        brandName
        brandID
        countryName
        countryID
        productDescription
        productFeatures
        keyWord
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        galleryImages {
          alterText
          title
          url
          description
          type
          __typename
        }
        singleVariant
        attrName
        attrNameID
        attrValue {
          value
          attrValueID
          __typename
        }
        productSpecification {
          name
          value
          __typename
        }
        seoDescription
        stockStatus
        searchKey
        status
        scheduleDate
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        showWhenZeroStock
        allowPreOrder
        overallRating
        noOfRating
        leadTime
        totalStockQuantity
        totalReservedStock
        displaySalePrice
        displayRegularPrice
        thumbnailUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAllProducts = /* GraphQL */ `
  query ListAllProducts(
    $globalPk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllProducts(
      globalPk: $globalPk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        pk
        globalPk
        productName
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        brandName
        brandID
        countryName
        countryID
        productDescription
        productFeatures
        keyWord
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        galleryImages {
          alterText
          title
          url
          description
          type
          __typename
        }
        singleVariant
        attrName
        attrNameID
        attrValue {
          value
          attrValueID
          __typename
        }
        productSpecification {
          name
          value
          __typename
        }
        seoDescription
        stockStatus
        searchKey
        status
        scheduleDate
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        showWhenZeroStock
        allowPreOrder
        overallRating
        noOfRating
        leadTime
        totalStockQuantity
        totalReservedStock
        displaySalePrice
        displayRegularPrice
        thumbnailUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productByMainCategoryIdGsi = /* GraphQL */ `
  query ProductByMainCategoryIdGsi(
    $mainCategoryID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByMainCategoryIdGsi(
      mainCategoryID: $mainCategoryID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        pk
        globalPk
        productName
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        brandName
        brandID
        countryName
        countryID
        productDescription
        productFeatures
        keyWord
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        galleryImages {
          alterText
          title
          url
          description
          type
          __typename
        }
        singleVariant
        attrName
        attrNameID
        attrValue {
          value
          attrValueID
          __typename
        }
        productSpecification {
          name
          value
          __typename
        }
        seoDescription
        stockStatus
        searchKey
        status
        scheduleDate
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        showWhenZeroStock
        allowPreOrder
        overallRating
        noOfRating
        leadTime
        totalStockQuantity
        totalReservedStock
        displaySalePrice
        displayRegularPrice
        thumbnailUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productByBrandIdGsi = /* GraphQL */ `
  query ProductByBrandIdGsi(
    $brandID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByBrandIdGsi(
      brandID: $brandID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        pk
        globalPk
        productName
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        brandName
        brandID
        countryName
        countryID
        productDescription
        productFeatures
        keyWord
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        galleryImages {
          alterText
          title
          url
          description
          type
          __typename
        }
        singleVariant
        attrName
        attrNameID
        attrValue {
          value
          attrValueID
          __typename
        }
        productSpecification {
          name
          value
          __typename
        }
        seoDescription
        stockStatus
        searchKey
        status
        scheduleDate
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        showWhenZeroStock
        allowPreOrder
        overallRating
        noOfRating
        leadTime
        totalStockQuantity
        totalReservedStock
        displaySalePrice
        displayRegularPrice
        thumbnailUrl
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductImage = /* GraphQL */ `
  query GetProductImage($pk: String!, $id: ID!) {
    getProductImage(pk: $pk, id: $id) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductImages = /* GraphQL */ `
  query ListProductImages(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductImages(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        alterText
        title
        url
        description
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        thumbnailUrl
        type
        productID
        isDeleted
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByImgCategory = /* GraphQL */ `
  query ItemsByImgCategory(
    $pk: String!
    $mainCategoryIDSubCategoryID: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByImgCategory(
      pk: $pk
      mainCategoryIDSubCategoryID: $mainCategoryIDSubCategoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        alterText
        title
        url
        description
        mainCategory
        mainCategoryID
        subCategory
        subCategoryID
        thumbnailUrl
        type
        productID
        isDeleted
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductDetails = /* GraphQL */ `
  query GetProductDetails($pk: String!, $id: ID!) {
    getProductDetails(pk: $pk, id: $id) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductDetails = /* GraphQL */ `
  query ListProductDetails(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelProductDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductDetails(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        productID
        attrName
        attrNameID
        attrValue
        attrValueID
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        model
        manufacturePartCode
        sku
        stockStatus
        stockQuantity
        reservedStock
        stockUnit
        itemsPerUnit
        regularPrice
        salePrice
        dataSheet
        submittal
        catalog
        iom
        storageInfo {
          wareHouseId
          wareHouse
          rackNo
          rowNo
          binNo
          note
          __typename
        }
        shipplingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        partialReturn
        type
        isDeleted
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productDetailsByProductID = /* GraphQL */ `
  query ProductDetailsByProductID(
    $pk: String!
    $productID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productDetailsByProductID(
      pk: $pk
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        productID
        attrName
        attrNameID
        attrValue
        attrValueID
        featuredImage {
          alterText
          title
          url
          description
          type
          __typename
        }
        model
        manufacturePartCode
        sku
        stockStatus
        stockQuantity
        reservedStock
        stockUnit
        itemsPerUnit
        regularPrice
        salePrice
        dataSheet
        submittal
        catalog
        iom
        storageInfo {
          wareHouseId
          wareHouse
          rackNo
          rowNo
          binNo
          note
          __typename
        }
        shipplingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        partialReturn
        type
        isDeleted
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($pk: String!, $id: ID!) {
    getOrder(pk: $pk, id: $id) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        userId
        createdAt
        userName
        email
        phoneNumber
        orderType
        totalProducts
        orderSummary {
          cartTotal
          productDiscount
          deliveryFee
          tax
          __typename
        }
        totalAmount
        paymentDetails {
          paymentMode
          paymentMethod
          cardType
          cardHolderName
          cardNumber
          CardExpiryDate
          UPI
          paymentDate
          referenceNumber
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          remaningAmount
          chequeStatus
          partialPaymentDetails {
            chequeNumber
            bankName
            chequeDate
            receivedAmount
            chequeStatus
            paymentDate
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        paymentStatus
        status
        wishListId
        couponDetails {
          couponCode
          amount
          discount {
            type
            value
            __typename
          }
          advancedSetting {
            appliedTo
            specificType {
              name
              id
              __typename
            }
            __typename
          }
          __typename
        }
        discountByCoupon
        shippingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        shippingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryInfo {
          deliveryPartner
          deliveryType
          productWeight
          __typename
        }
        billingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        billingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryDate
        invoiceNumber
        invoiceURL
        orderPdf
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderByDate = /* GraphQL */ `
  query OrderByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        createdAt
        userName
        email
        phoneNumber
        orderType
        totalProducts
        orderSummary {
          cartTotal
          productDiscount
          deliveryFee
          tax
          __typename
        }
        totalAmount
        paymentDetails {
          paymentMode
          paymentMethod
          cardType
          cardHolderName
          cardNumber
          CardExpiryDate
          UPI
          paymentDate
          referenceNumber
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          remaningAmount
          chequeStatus
          partialPaymentDetails {
            chequeNumber
            bankName
            chequeDate
            receivedAmount
            chequeStatus
            paymentDate
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        paymentStatus
        status
        wishListId
        couponDetails {
          couponCode
          amount
          discount {
            type
            value
            __typename
          }
          advancedSetting {
            appliedTo
            specificType {
              name
              id
              __typename
            }
            __typename
          }
          __typename
        }
        discountByCoupon
        shippingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        shippingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryInfo {
          deliveryPartner
          deliveryType
          productWeight
          __typename
        }
        billingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        billingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryDate
        invoiceNumber
        invoiceURL
        orderPdf
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderByUserId = /* GraphQL */ `
  query OrderByUserId(
    $pk: String!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByUserId(
      pk: $pk
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        createdAt
        userName
        email
        phoneNumber
        orderType
        totalProducts
        orderSummary {
          cartTotal
          productDiscount
          deliveryFee
          tax
          __typename
        }
        totalAmount
        paymentDetails {
          paymentMode
          paymentMethod
          cardType
          cardHolderName
          cardNumber
          CardExpiryDate
          UPI
          paymentDate
          referenceNumber
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          remaningAmount
          chequeStatus
          partialPaymentDetails {
            chequeNumber
            bankName
            chequeDate
            receivedAmount
            chequeStatus
            paymentDate
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        paymentStatus
        status
        wishListId
        couponDetails {
          couponCode
          amount
          discount {
            type
            value
            __typename
          }
          advancedSetting {
            appliedTo
            specificType {
              name
              id
              __typename
            }
            __typename
          }
          __typename
        }
        discountByCoupon
        shippingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        shippingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryInfo {
          deliveryPartner
          deliveryType
          productWeight
          __typename
        }
        billingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        billingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryDate
        invoiceNumber
        invoiceURL
        orderPdf
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderByStatus = /* GraphQL */ `
  query GetOrderByStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userId
        createdAt
        userName
        email
        phoneNumber
        orderType
        totalProducts
        orderSummary {
          cartTotal
          productDiscount
          deliveryFee
          tax
          __typename
        }
        totalAmount
        paymentDetails {
          paymentMode
          paymentMethod
          cardType
          cardHolderName
          cardNumber
          CardExpiryDate
          UPI
          paymentDate
          referenceNumber
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          remaningAmount
          chequeStatus
          partialPaymentDetails {
            chequeNumber
            bankName
            chequeDate
            receivedAmount
            chequeStatus
            paymentDate
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        paymentStatus
        status
        wishListId
        couponDetails {
          couponCode
          amount
          discount {
            type
            value
            __typename
          }
          advancedSetting {
            appliedTo
            specificType {
              name
              id
              __typename
            }
            __typename
          }
          __typename
        }
        discountByCoupon
        shippingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        shippingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryInfo {
          deliveryPartner
          deliveryType
          productWeight
          __typename
        }
        billingUserDetails {
          userName
          phoneNumber
          email
          __typename
        }
        billingAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        deliveryDate
        invoiceNumber
        invoiceURL
        orderPdf
        searchKey
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($pk: String!, $id: ID!) {
    getOrderItem(pk: $pk, id: $id) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderItems(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemByCreatedAt = /* GraphQL */ `
  query OrderItemByCreatedAt(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemByCreatedAt(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemByStatus = /* GraphQL */ `
  query OrderItemByStatus(
    $pk: String!
    $deliveryStatusCreatedAt: ModelOrderItemByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemByStatus(
      pk: $pk
      deliveryStatusCreatedAt: $deliveryStatusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemByMainCategory = /* GraphQL */ `
  query OrderItemByMainCategory(
    $pk: String!
    $mainCategoryIdMainCategory: ModelOrderItemByMainCategoryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemByMainCategory(
      pk: $pk
      mainCategoryIdMainCategory: $mainCategoryIdMainCategory
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemBySubCategory = /* GraphQL */ `
  query OrderItemBySubCategory(
    $pk: String!
    $subCategoryIdSubCategory: ModelOrderItemBySubCategoryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemBySubCategory(
      pk: $pk
      subCategoryIdSubCategory: $subCategoryIdSubCategory
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemByWarehouse = /* GraphQL */ `
  query OrderItemByWarehouse(
    $pk: String!
    $wareHouseIdWareHouseName: ModelOrderItemByWarehouseCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemByWarehouse(
      pk: $pk
      wareHouseIdWareHouseName: $wareHouseIdWareHouseName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemsByDeliveryStatus = /* GraphQL */ `
  query OrderItemsByDeliveryStatus(
    $deliveryStatus: ProductDeliveryStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemsByDeliveryStatus(
      deliveryStatus: $deliveryStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        productId
        mainCategoryId
        mainCategory
        subCategoryId
        subCategory
        userId
        wareHouseId
        attrValueID
        wareHouseName
        userName
        email
        phoneNumber
        createdAt
        quantity
        stockUnit
        itemsPerUnit
        taxCode
        taxAmount
        taxDetail {
          taxCharge
          taxID
          taxName
          __typename
        }
        regularPrice
        totalPrice
        itemDetail {
          productName
          price
          image
          brand
          model
          tax
          currentStock
          sku
          qrcode
          __typename
        }
        sku
        attrName
        attrValue
        deliveryStatus
        deliveryBy
        deliveryTracking {
          status
          date
          trackingBy
          phoneNumber
          remarks
          receipt
          __typename
        }
        deliveryPartner
        outForDeliveryDate
        outForDeliveryTime
        expectedDeliveryDate
        trackingID
        trackingUrl
        deliveryPersonInfo {
          name
          contactNumber
          vehiclenumber
          notes
          __typename
        }
        orderedOn
        returnRefundActivity {
          returnReference
          reason
          comment
          quantity
          subTotal
          total
          attachments
          paymentMode
          paymentMethod
          returnType
          returnAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpDetails {
            pickUpAddress {
              addressLine1
              addressLine2
              street
              landmark
              state
              city
              postalCode
              country
              __typename
            }
            pickUpBy
            deliveryPartner
            name
            phoneNumber
            expectedPickUpDate
            time
            __typename
          }
          receivedInfo {
            receivedOn
            receivedAs
            images
            __typename
          }
          updateInStock
          updateInStockQty
          refundInfo {
            date
            paymentMethod
            refundAmount
            refundInfo
            proofImage
            proofImageUrl
            rejectedReason
            __typename
          }
          status
          returnStatusUpdate {
            returnStatus
            updatedAt
            updatedBy
            __typename
          }
          rejectReason
          createdAt
          __typename
        }
        eta
        paymentStatus
        billOfLading
        packingList
        searchKey
        shippingInfo {
          weightValue
          weightUnit
          lengthValue
          lengthUnit
          heightValue
          heightUnit
          widthValue
          widthUnit
          __typename
        }
        returnAvailable
        disputeResolved
        brandId
        returnValue
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportMaster = /* GraphQL */ `
  query GetReportMaster($pk: String!, $id: ID!) {
    getReportMaster(pk: $pk, id: $id) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listReportMasters = /* GraphQL */ `
  query ListReportMasters(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelReportMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReportMasters(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        reportName
        description
        sequenceNumber
        searchKey
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
