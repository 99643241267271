import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
  const showSuccessToastr = (message) => {
    toastr.success(message);
  };

  const showErrorToastr = (message) => {
    toastr.error(message);
  };
  
export { showSuccessToastr, showErrorToastr };