import React, {useState, useEffect} from 'react';
import { Outlet } from "react-router-dom";
import Header from '../pages/header';
import Footer from "../pages/footer";
import { GetData } from '../providers/queryData';
import { resourceconstant} from '../constants';
import { useNavigate } from 'react-router-dom';
import {Container} from "react-bootstrap";
import useProducts from '../hooks/useProducts';

const MainPage = () => {
  const [productsList, setProductsList] = useProducts();
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [filterMainCategory, setFilterMainCategory] = useState([])
  const [filterSubCategory, setFilterSubCategory] = useState([])
  const [categoryItem, setCategoryItem] = useState([])

  const navigate = useNavigate()

  const MainCategoryList = () => {
    let filter = {
      pk: {
        eq: 'MAIN#',
      },
    }
    GetData(resourceconstant.LIST_CATEGORY, filter, setFilterMainCategory)
  };

  useEffect(() => {
    if (filterMainCategory?.severity === "success") {
      const sortedCategories = filterMainCategory?.data?.data?.listCategories?.items?.sort((a, b) => {
        if (a.sequenceNumber != null && b.sequenceNumber != null) {
          return a.sequenceNumber - b.sequenceNumber;
        }
        if (a.sequenceNumber != null) return -1;
        if (b.sequenceNumber != null) return 1;
        return 0;
      });
  
      setCategory(sortedCategories);
    }
  }, [filterMainCategory]);

  const SubCategoryList = () => {
    let filter = {
      pk: { beginsWith: 'SUB#' }
    }
    GetData(resourceconstant.LIST_CATEGORY, filter, setFilterSubCategory)
  };

  useEffect(() => {
    if (filterSubCategory?.severity === "success" && category && productsList) {
      const subcategories = filterSubCategory?.data?.data?.listCategories?.items || [];

      const filteredCategories = category.filter((categorys) => {
        return productsList.some((subCategorys) => subCategorys.mainCategoryID === categorys.id);
      });
      setSubCategory(subcategories);
      setCategoryItem(filteredCategories);
    }
  }, [filterSubCategory, category, productsList]);
  
  useEffect(() => {
    if (category) {
      category.forEach((cat) => {
        SubCategoryList(cat.id);
      });
    }
  }, [category]);

  const HandleAllProducts = async (e) => {
    e.preventDefault()
    navigate('/all-products', {
      state: {
        allProducts: true
      }
    });
    window.scroll(0, 0)
  }

  useEffect(() => {
    MainCategoryList()
    SubCategoryList()
  }, [])

  return (
    <div className="page">
      <div className="background bgWrapper">
        <header className="headerWrapper">
          <Header category={categoryItem} subCategory={subCategory} HandleAllProducts={HandleAllProducts} productsList={productsList} />
        </header>
        <Container fluid>
          <div className="content-sec">
            <Outlet />
          </div>
          <div className="footer-sec">
            <div className="footer-wrapper">
              <Footer />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MainPage;
