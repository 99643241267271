import React, { useState, useEffect} from "react";
import { GetData } from "../../../providers/queryData";
import { resourceconstant} from "../../../constants";
import ProductList from "../../../common/ProductList";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListQuery from "../../../common/ProductListQuery"

const SubCategoryList = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const [categoryList, setcategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [category, setCategory] = useState([])
    const [mainCategoryId, setMainCategoryId] = useState("")
    const [subCategoryId, setSubCategoryId] = useState("")
    const [subCategoryDetail, setSubCategoryDetail] = useState({});
    const current = new URL(window.location.href);
    const pathSegments = current.pathname.split('/');
    const subCategory = pathSegments[pathSegments.length - 1];
    const mainCategory = pathSegments[pathSegments.length - 2];

    useEffect(() => {
        const fetchData = async () => {
            if (subCategory && mainCategory) {
                await MainListCategories();
                await GetSubCategoryList();
            }
        };

        fetchData();
    }, [subCategory, mainCategory]);

    const MainListCategories = () => {
        let filter = {
            pk: {
                eq: "MAIN#"
            },
            slug: {
                eq: mainCategory
            }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setcategoryList)
    };

    const GetSubCategoryList = () => {
        let filter = {
            pk: {
                beginsWith: 'SUB#',
            },
            slug: {
                eq: subCategory
            }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setSubCategoryList)
    };

    useEffect(() => {
        if (categoryList?.severity === "success") {
            const item = categoryList?.data?.data?.listCategories?.items
            setCategory(item)
        }
    }, [categoryList])

    useEffect(() => {
        if (subCategoryList?.severity === "success") {
            const items = subCategoryList?.data?.data?.listCategories?.items || [];
            const filtered = items?.filter(data => data?.mainCategoryID === category?.[0]?.id);
            if(filtered?.length>0)
                {
                    setSubCategoryDetail(filtered[0])
                    setSubCategoryId(filtered[0]?.id)
                    setMainCategoryId(filtered[0]?.mainCategoryID)
                }
        }
    }, [subCategoryList, category]);

  return (
    <ProductListQuery page="subCategory" 
            mainCategoryId={mainCategoryId} 
            subCategoryId={subCategoryId} 
            mainCategory={mainCategory}
            subCategory={subCategory} 
            subCategoryDetail={subCategoryDetail} />
)
};

export default SubCategoryList