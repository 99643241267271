import React, { createContext, useContext, useState } from 'react';
const PreviousPageContext = createContext('');
export const usePreviousPage = () => useContext(PreviousPageContext);
export const PreviousPageProvider = ({ children }) => {
    const [previousPage, setPreviousPage] = useState('');
    return (
        <PreviousPageContext.Provider value={[previousPage, setPreviousPage]}>
            {children}
        </PreviousPageContext.Provider>
    );
};
