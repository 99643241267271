import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useProfile from '../../hooks/useProfile';
import { showErrorToastr, showSuccessToastr } from '../../common/toastrService';
import useCart from '../../hooks/useCartPage';
import { FormatValueDecimal } from '../../utils/validate';
import { GetData } from '../../providers/queryData';
import { resourceconstant } from '../../constants';
import useLogoImage from '../../hooks/useLogoImage';
const OrderSummery = ({ defaultAddress, filteredPromoCodes, selectedCoupon, CartList, setMyCart, cartList, myCart, cartResponseData, HandleSaveToCart, listingCart, isSaveCart, cartError, setCartError }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [deliveyFeeCart, setDeliveryFeeCart] = useState([])
    const [deliveryFeeItemCart, setDeliveryFeeItemCart] = useState([])
    const [shippingDataCart, setShippingDataCart] = useState([])
    const [userProfile, setUserProfile] = useProfile()
    const userid = userProfile?.attributes?.sub;
    const [cartPageItem, setCartPageItem] = useCart()
    const cartData = localStorage.getItem('cartLength')
    const mergedCartList = [...listingCart, ...cartList.filter(item => !listingCart.some(cart => cart?.productID === item?.productID))];
    const userCartList = userid ? mergedCartList.filter(cart => cart?.userID === userid || cart?.userId === userid) : [];
    const cartTotal = cartPageItem.reduce((total, item) => total + (item?.salesPrice * item.selectedQuantity), 0);
    const regularTotal = cartPageItem.reduce((total, item) => total + (item?.price * item.selectedQuantity), 0);
    const salePrice = cartPageItem.reduce((total, item) => total + (item?.salesPrice * item.selectedQuantity), 0);
    const quantity = cartPageItem.find(item => item.selectedQuantity)

    const productDiscount = cartPageItem.reduce((total, item) => {
        const discountPercentage = regularTotal - salePrice;
        return discountPercentage;
    }, 0);
    const totalTax = cartPageItem.reduce((total, item) => total + (item?.taxCharge * item.selectedQuantity), 0);
    const deliveryFee = deliveryFeeItemCart?.deliveryCharge !== undefined && deliveryFeeItemCart?.deliveryCharge !== null ? deliveryFeeItemCart?.deliveryCharge : 0
    const totalAmount = cartTotal + totalTax + deliveryFee;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const stateToPass = {
            values: {
                salePrice: salePrice,
                cartTotal: cartTotal,
                regularTotal: regularTotal,
                productDiscount: productDiscount,
                totalTax: totalTax,
                totalAmount: totalAmount,
            },
            checkoutData: null
        };
        if (cartResponseData?.status === true && myCart !== "success") {
            navigate('/checkout', {
                state: stateToPass
            });
        }
        else if (myCart === "success" && cartResponseData?.status === true) {
            showSuccessToastr(cartResponseData?.message)
            setMyCart(null)
        }
        else if (myCart === "success" && cartResponseData?.status === false) {
            showErrorToastr(cartResponseData?.message)
            setMyCart(null)
        }
        CartList()
    }, [cartResponseData])

    const renderDiscountValue = (discount) => {
        if (!discount) return null;

        if (typeof discount.value === 'string' && discount.value.endsWith('%')) {
            const percentage = parseFloat(discount.value) / 100;
            const amount = percentage * 100;
            return `{logoUrl?.currency} ${amount.toFixed(2)}`;
        } else if (typeof discount.value === 'number') {
            const amount = (discount.value / 100) * 100;
            return `{logoUrl?.currency} ${amount.toFixed(2)}`;
        } else {
            return `{logoUrl?.currency} ${discount.value}`;
        }
    };

    const renderCoupons = () => {
        if (filteredPromoCodes && filteredPromoCodes.length > 0) {
            return filteredPromoCodes.map((item, index) => (
                <tr key={index}>
                    <th>Coupon({item.code})</th>
                    <td className='text-end' style={{ color: "#1A0003" }}>{renderDiscountValue(item.discount)}</td>
                </tr>
            ));
        } else if (selectedCoupon) {
            return (
                <tr>
                    <th>Coupon({selectedCoupon.code})</th>
                    <td className='text-end' style={{ color: "#1A0003" }}>{renderDiscountValue(selectedCoupon.discount)}</td>
                </tr>
            );
        }
        return null;
    };

    const DeliveryData = () => {
        let filter = {
        }
        GetData(resourceconstant.DELIVERY_FEE, filter, setDeliveryFeeCart)
    };

    useEffect(() => {
        if (deliveyFeeCart?.severity === "success") {
            const deliveryItem = deliveyFeeCart?.data?.data?.listShippings?.items || [];
            setShippingDataCart(deliveryItem)
        }
    }, [deliveyFeeCart]);

    useEffect(() => {
        DeliveryData()
    }, [])

    useEffect(() => {
        if (shippingDataCart && defaultAddress) {
            const { postalCode, country, city, state } = defaultAddress?.address || {};

            const zipDeliveryFee = shippingDataCart.find(item =>
                item?.zipCodes?.some(zip => zip === postalCode) &&
                item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
            );
            let stateDeliveryFee = null;

            if (!zipDeliveryFee) {
                stateDeliveryFee = shippingDataCart.find((item) =>
                    item?.state?.trim()?.toLowerCase() === state?.trim()?.toLowerCase() && item?.city?.trim()?.toLowerCase() === city?.trim()?.toLowerCase() && item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
                  );
          
                  if (!stateDeliveryFee) {
                    stateDeliveryFee = shippingDataCart.find((item) =>
                      !item?.state &&
                      item?.city?.trim()?.toLowerCase() === city?.trim()?.toLowerCase() && item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
                    );
                  }
            }
            let countryDeliveryFee = null;

            if (!zipDeliveryFee && !stateDeliveryFee) {
              countryDeliveryFee = shippingDataCart.find((item) =>
                (!item?.city || item?.city?.trim() === '') &&
                (item?.state?.trim()?.toLowerCase() === state?.trim()?.toLowerCase() && item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase())
      
              );
      
              if (!countryDeliveryFee) {
                countryDeliveryFee = shippingDataCart.find((item) =>
                  (!item?.city || item?.city?.trim() === '') &&
                  !item?.state &&
                  item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
                );
              }
            }
            if (zipDeliveryFee && cartPageItem?.length > 0) {
                setDeliveryFeeItemCart(zipDeliveryFee);
            } else if (stateDeliveryFee && cartPageItem?.length > 0) {
                setDeliveryFeeItemCart(stateDeliveryFee);
            } else if (countryDeliveryFee && cartPageItem?.length > 0) {
                setDeliveryFeeItemCart(countryDeliveryFee);
            } else {
                setDeliveryFeeItemCart([]);
            }
        }
    }, [shippingDataCart, defaultAddress, cartPageItem]);

    return (
        <Card className='order-summary-card'>
            <CardHeader>
                <h2>Order Summary</h2>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <div className='table-responsive'>
                            <table className='table table-borderless mb-0'>
                                <tbody>
                                    <tr>
                                        <th>Cart Total</th>
                                        <td className='text-end'>{logoUrl?.currency} {cartTotal ? FormatValueDecimal(cartTotal) : 0}</td>
                                    </tr>
                                    <tr>
                                        <th>You Saved</th>
                                        <td className='text-end' style={{ color: "#1BAA57" }}> {logoUrl?.currency} {FormatValueDecimal(productDiscount)}</td>
                                    </tr>
                                    {renderCoupons()}
                                    <tr>
                                        <th>Delivery Fee</th>
                                        <td className="text-end">
                                        {deliveryFeeItemCart?.deliveryCharge !== undefined && deliveryFeeItemCart?.deliveryCharge !== null && deliveryFeeItemCart?.deliveryCharge !== 0
                                            ? `${logoUrl?.currency} ${FormatValueDecimal(deliveryFeeItemCart.deliveryCharge)}`
                                            : deliveryFeeItemCart?.deliveryCharge === 0 ? "Free" : "Calculate at checkout"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tax</th>
                                        <td className='text-end'>{logoUrl?.currency} {FormatValueDecimal(totalTax ? totalTax : 0)}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{logoUrl?.currency} {FormatValueDecimal(totalAmount ? totalAmount : 0)}</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className='text-center'>
                                            <button type='button' className='btn' onClick={() => HandleSaveToCart('checkout')}>
                                                <Link>Checkout ({cartPageItem.length} Items)</Link>
                                            </button>
                                            {
                                                cartError && <p className='form-error my-2'>{cartError}</p>
                                            }
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default OrderSummery