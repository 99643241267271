import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Magnifier } from "react-image-magnifiers";

function ProductDetailCarousel({
  productDetailList,
  productList,
  selectedAttrValue,
}) {
  const images = [
    {
      original: selectedAttrValue?.featuredImage?.url
        ? selectedAttrValue.featuredImage?.url
        : productList[0]?.featuredImage?.url
        ? productList[0]?.featuredImage?.url
        : productDetailList[0]?.featuredImage?.url,
      thumbnail:  selectedAttrValue?.featuredImage?.url
        ? selectedAttrValue.featuredImage?.url
        : productList[0]?.featuredImage?.url
        ? productList[0]?.featuredImage?.url
        : productDetailList[0]?.featuredImage?.url,
    },
    ...(productList[0]?.galleryImages.map((item) => ({
      original: item.url,
      thumbnail: item.url,
    })) || []),
  ];

  return (
    <div className="product-detail-carousel">
      <div className="carousel-container">
        <ImageGallery
          items={images}
          showThumbnails={true}
          showFullscreenButton={false}
          showPlayButton={false}
          infinite={false}
          thumbnailPosition="bottom"
          disableThumbnailScroll={true}
          renderItem={(item) => (
            <div className="zoom-container">
              <Magnifier
                imageSrc={item.original}
                imageAlt="Product Image"
                largeImageSrc={item.original}
                dragToMove={false}
                className="zoom-image"
                magnificationLevel={1.5}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default ProductDetailCarousel;
