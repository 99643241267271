import React, { useEffect, useState } from "react";
import ProductList from "../../../common/ProductList";
import { GetData } from "../../../providers/queryData";
import { resourceconstant } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListQuery from "../../../common/ProductListQuery"

const MainCategoryList = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const [mainCategoryId, setMainCategoryId] = useState("")
    const [filteredCategory, setFilteredCategory] = useState([])
    const [mainCategoryImage, setMainCategoryImage] = useState({});
    const current = new URL(window.location.href);
    const pathSegments = current.pathname.split('/');
    const mainCategory = pathSegments[pathSegments.length - 1];

    useEffect(() => {
        GetCategoryListForFilter();
    }, [mainCategory])

    const GetCategoryListForFilter = () => {
        let filter = {
            pk: {
                eq: 'MAIN#',
            },
            slug: {
                eq: mainCategory
            }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setFilteredCategory)
    };

    useEffect(() => {
        if (filteredCategory?.severity === "success") {
            const newCategoryId = filteredCategory?.data?.data?.listCategories?.items[0]?.id;
            setMainCategoryImage(filteredCategory?.data?.data?.listCategories?.items[0])
            setMainCategoryId(newCategoryId);
        }
    }, [filteredCategory]);

    return (
        <ProductListQuery page="mainCategory" mainCategoryId={mainCategoryId} mainCategory={mainCategory} mainCategoryImage={mainCategoryImage} />
    );
};

export default MainCategoryList;
