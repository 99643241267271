import React, { useState, useEffect, useMemo } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import { FormSelect } from "react-bootstrap";
import Filter from "../../assets/images/icons/filter.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useProfile from "../../hooks/useProfile";
import { GetOrderList, GetOrderItemList, OrderTabStatus } from "../../utils/generalMethod";
import { formatDate, checkValue, PriceFormat, DateFormatShow, CheckNull } from "../../utils/validate";
import OrderUpdates from "../../common/orderUpdates";
import CancelOrder from "../../common/cancelOrder";
import OrderHistory from "../../views/account/orderHistory";
import FilterShow from '../account/filterShow';
import moment from "moment"
import Amplify, { Auth } from 'aws-amplify';
import NoResultsFound from '../not-found/noResultsFound';
import ListPagination from "../../components/listPagination";
import Spinner from "../../common/spinner"
import useLogoImage from "../../hooks/useLogoImage";

export default function OrdersTab() {
    const navigate = useNavigate();
    const location = useLocation();
    const [userProfile, setUserProfile] = useProfile()
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [responseData, setResponseData] = useState(null);
    const [orderDetail, setOrderDetail] = useState(null);
    const [orderItemResponse, setOrderItemResponse] = useState(null)
    const [orderItemDetail, setOrderItemDetail] = useState(null);
    const [filterOrderItemDetail, setFilterOrderItemDetail] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [cancelOrderDetail, setCancelOrderDetail] = useState({})
    const [cancelShow, setCancelShow] = useState(false);
    const [sorderDetail, showOrderDetail] = useState(false);
    const [orderHistoryDetail, setOrderHistoryDetail] = useState({ orderItem: null, orderDetail: null, orderProducts: null })
    const [search, setSearch] = useState("")
    const [searchOrderDetail, setSearchOrderDetail] = useState(null);
    const [filterShow, setFilterShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterForm, setFilterForm] = useState({
        orderDate: "",
        orderStatus: "",
        paymentType: "",
        shippingMethod: ""
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const totalPages = Math.ceil(searchOrderDetail?.length / itemsPerPage);
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedOrderList = useMemo(() => {
        if (searchOrderDetail?.length <= itemsPerPage) setCurrentPage(1);
        else if (
            currentPage > Math.ceil(searchOrderDetail?.length / itemsPerPage)
        ) {
            setCurrentPage(1);
        }

        const firstPageIndex = (currentPage - 1) * itemsPerPage;
        const lastPageIndex = firstPageIndex + itemsPerPage;
        return searchOrderDetail?.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, searchOrderDetail]);


    useEffect(() => {
        showOrderDetail(false)
    }, [])

    useEffect(() => {
        if (location?.state?.from === "return") {
            showOrderDetail(true)
            setOrderHistoryDetail({
                orderItem: location?.state?.orderItem,
                orderDetail: location?.state?.orderDetail,
                orderProducts: location?.state?.itemData
            })
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location?.state])

    useEffect(() => {
        if (location?.state?.search) {
            setSearch(location?.state?.search)
            showOrderDetail(false);
            window.history.replaceState({}, '')
        }
    }, [location?.state?.search])

    useEffect(() => {
        const GetUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                if (!currentUser) navigate('/login')
            }
            catch {
                navigate('/login')
            }
        }
        GetUser()
    }, [])

    useEffect(() => {
        setLoading(true)
        GetOrdersDetails();
    }, [userProfile?.attributes?.sub]);

    const GetOrdersDetails = () => {
        const userid = userProfile?.attributes?.sub;
        let filter = {
            userId: { eq: `${userid}` },
        };

        GetOrderList(filter, setResponseData);
        GetOrderItemList(filter, setOrderItemResponse);
    };

    useEffect(() => {
        if (responseData?.severity === "success") {
            setOrderDetail(responseData?.data?.data?.listOrders?.items);
        }

    }, [responseData]);

    useEffect(() => {
        if (orderItemResponse?.severity === "success") {
            setOrderItemDetail(orderItemResponse?.data?.data?.listOrderItems?.items);
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }, [orderItemResponse]);

    const showOrderHistory = (orderItem, item, itemData) => {
        showOrderDetail(true)
        setOrderHistoryDetail({
            orderItem: orderItem,
            orderDetail: item,
            orderProducts: itemData
        })
    }

    const BackTab = () => {
        showOrderDetail(false)
        setCurrentPage(1)
    }

    useEffect(() => {
        if (orderDetail?.length > 0) {
            let filterdata = [...orderDetail].sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            });

            let orderItem = [];
            filterdata = filterdata?.filter((item) => {
                let itemData = orderItemDetail?.filter(orderItem => orderItem.pk === `ORDERI#${item?.id}` && checkValue(orderItem?.deliveryStatus) !== "")
                if (itemData?.length > 0) {
                    orderItem.push(...itemData)
                    return true
                }
                else return false
            })
            setSearchOrderDetail(filterdata)
            setFilterOrderItemDetail(orderItem)
            if (search) ApplyFilter();
        }

    }, [orderDetail, orderItemDetail])

    const HandleKeyDown = (event) => {
        if (event.key === 'Enter') {
            ApplyFilter();
        }
    }

    const HandleSearch = () => {
        ApplyFilter();
    }

    useEffect(() => {
        ApplyFilter();
    }, [search])

    const handleFilterShow = () => {
        setFilterShow(true)
    }

    const HandleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterForm({ ...filterForm, [name]: value })
    }

    const ApplyFilter = () => {
        setCurrentPage(1);
        let orders = orderDetail?.filter((item) => {
            let itemData = orderItemDetail?.filter(orderItem => orderItem.pk === `ORDERI#${item?.id}` && checkValue(orderItem?.deliveryStatus) !== "")
            let meetsCriteria = true;
            if (filterForm?.orderDate && filterForm.orderDate !== moment(item.createdAt).format("YYYY-MM-DD"))
                meetsCriteria = false;
            if (search && item?.id?.indexOf(search) < 0)
                meetsCriteria = false;
            if (checkValue(filterForm.orderStatus) !== "") {
                itemData = itemData?.filter(orderItem =>
                    orderItem?.returnRefundActivity?.status ?
                        checkValue("return-" + orderItem?.returnRefundActivity?.status) === filterForm.orderStatus
                        : checkValue("order-" + orderItem?.deliveryStatus) === filterForm.orderStatus)
            }
            if (itemData?.length <= 0) meetsCriteria = false;
            return meetsCriteria;
        })
        let filterdata = []
        let orderItem = []
        if (orders?.length > 0) {
            filterdata = [...orders].sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            });

            orders?.map((data, index) => {
                let itemData = orderItemDetail?.filter(orderItem => orderItem.pk === `ORDERI#${data?.id}` && checkValue(orderItem?.deliveryStatus) !== "")
                if (checkValue(filterForm.orderStatus) !== "") {
                    itemData = itemData?.filter(orderItem =>
                        orderItem?.returnRefundActivity?.status ?
                            checkValue("return-" + orderItem?.returnRefundActivity?.status) === filterForm.orderStatus
                            : checkValue("order-" + orderItem?.deliveryStatus) === filterForm.orderStatus)
                }
                if (itemData?.length > 0) orderItem.push(...itemData)
            })
        }
        setFilterOrderItemDetail(orderItem)

        setSearchOrderDetail(filterdata)
        setFilterShow(false)
    }

    const ClearFilter = () => {
        setFilterForm({
            orderDate: "",
            orderStatus: "",
            paymentType: "",
            shippingMethod: ""
        });
        setSearch('')
        let filterdata = []
        let orderItem = []
        if (orderDetail?.length > 0) {
            filterdata = [...orderDetail].sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            });
            if (CheckNull(search) !== "") {
                filterdata = filterdata?.filter(item => item?.id?.indexOf(search) > 0)
            }
            filterdata = filterdata?.filter((item) => {
                let itemData = orderItemDetail?.filter(orderItem => orderItem.pk === `ORDERI#${item?.id}` && checkValue(orderItem?.deliveryStatus) !== "")
                if (itemData?.length > 0) {
                    orderItem.push(...itemData)
                    return true
                }
                else return false
            })
        }
        setSearchOrderDetail(filterdata)
        setFilterOrderItemDetail(orderItem)
        setFilterShow(false)
        setCurrentPage(1);
    }
    return (
        <section id="account" className='my-1'>
            <Container>
                <Row className='mt-1'>
                    <Col md={12}>
                        <Tab.Container>
                            <Card className='mx-5'>
                                <Nav className="flex-column">
                                    <CardBody>
                                       {!sorderDetail && <Row className="orders">
                                            <Col xxl={6} xl={6} lg={3} md={12} sm={12} xs={12}>
                                                {!sorderDetail &&
                                                    <h2>Order History</h2>
                                                }
                                            </Col>
                                            <Col xxl={6} xl={6} lg={9} md={12} sm={12} xs={12}>
                                                <div className='d-flex align-items-center flex-wrap gap-1 justify-content-end order-history'>
                                                    <div className="input-group">
                                                        <span className="input-group-text">
                                                            <i className="fa fa-search" onClick={HandleSearch}></i>
                                                        </span>
                                                        <input
                                                            type="Search"
                                                            placeholder="Search all Orders"
                                                            aria-label="Input group"
                                                            aria-describedby="btnGroupAddon"
                                                            onKeyDown={HandleKeyDown}
                                                            onChange={(e) => { setSearch(e.target.value) }}
                                                            value={search}
                                                        />
                                                    </div>
                                                    <div className="filter-dropdown">
                                                        <button onClick={handleFilterShow}><img src={Filter} />
                                                            Filter <i className="fa fa-chevron-down"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>}
                                        <div>
                                        {!sorderDetail &&  <hr />}
                                            {!sorderDetail && paginatedOrderList?.map((item, index) => {
                                                let itemData = filterOrderItemDetail?.filter(orderItem => orderItem.pk === `ORDERI#${item?.id}` && checkValue(orderItem?.deliveryStatus) !== "")
                                                return (
                                                    <div className='product-table mb-2'>
                                                        <table>
                                                            <thead>
                                                                <th>
                                                                    <p>{item?.deliveryInfo?.deliveryType === "Doorstep" ? "Ship To" : "Bill To"}</p>
                                                                    <h2>{item?.deliveryInfo?.deliveryType === "Doorstep" ? item?.shippingUserDetails?.userName : item?.billingUserDetails?.userName}</h2>
                                                                </th>
                                                                <th>
                                                                    <p>Order #</p>
                                                                    <h2>{item?.id}</h2>
                                                                </th>
                                                                <th>
                                                                    <p>Total</p>
                                                                    <h2>{logoUrl?.currency} {PriceFormat(item?.totalAmount)}</h2>
                                                                </th>
                                                                <th>
                                                                    <p>Ordered On</p>
                                                                    <h2>{DateFormatShow(item?.createdAt, false)}</h2>
                                                                </th>
                                                                <th>{item?.invoiceURL ? <a href={item?.invoiceURL} target="_blank" style={{ textDecoration: "none" }} rel="noreferrer">View Invoice</a> : ""}</th>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    itemData?.length > 0 ?
                                                                        itemData?.map((orderItem, orderIndex) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td colSpan={4}>
                                                                                        <div className='product-list'>
                                                                                            <div className='pdt-img me-3'>
                                                                                                <img src={orderItem?.itemDetail?.image} className='img-fluid' />
                                                                                            </div>
                                                                                            <div className='pdt-detail'>
                                                                                                <h6>{orderItem?.itemDetail?.productName} {orderItem?.attrValue && "-"} {orderItem?.attrName} {orderItem?.attrValue}</h6>
                                                                                                <p className="pdt-model" style={{ color: "black", fontSize: "15px", fontWeight: "400", marginBottom: "4px" }}>
                                                                                                    {checkValue(orderItem?.itemDetail?.brand) !== "" && <span> Brand: </span>}
                                                                                                    {checkValue(orderItem?.itemDetail?.brand) !== "" && orderItem?.itemDetail?.brand}
                                                                                                    {(checkValue(orderItem?.itemDetail?.model) !== "" && checkValue(orderItem?.itemDetail?.brand) !== "") && <span> | </span>}
                                                                                                    {checkValue(orderItem?.itemDetail?.model) !== "" && <span> Model: </span>}
                                                                                                    {checkValue(orderItem?.itemDetail?.model) !== "" && orderItem?.itemDetail?.model}
                                                                                                    {(checkValue(orderItem?.itemDetail?.sku) !== "") && (checkValue(orderItem?.itemDetail?.brand) !== "" || checkValue(orderItem?.itemDetail?.model) !== "") && <span> | </span>}
                                                                                                    {checkValue(orderItem?.itemDetail?.sku) !== "" && <span> SKU: </span>}
                                                                                                    {checkValue(orderItem?.itemDetail?.sku) !== "" && orderItem?.itemDetail?.sku}
                                                                                                </p>
                                                                                                {orderItem?.expectedDeliveryDate && orderItem?.deliveryStatus === "OutForDelivery" && <p className='my-3'>Estimated day of delivery : <span color='yellow'>{DateFormatShow(orderItem?.expectedDeliveryDate, false)}</span></p>}
                                                                                                <p className='mt-3'>Status: {orderItem?.returnRefundActivity?.status ? OrderTabStatus("return", orderItem?.returnRefundActivity?.status, item?.deliveryInfo?.deliveryType) : OrderTabStatus("order", orderItem?.deliveryStatus, item?.deliveryInfo?.deliveryType)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td align='right'>
                                                                                        {orderItem?.deliveryStatus !== "Cancelled" && (<button type='button' color='primary' className='next-collapse' onClick={() => showOrderHistory(orderItem, item, itemData)}>
                                                                                            <i className='fa fa-chevron-right'></i>
                                                                                        </button>)}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }) : ""
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                            }
                                            {loading ? <div style={{ color: "red" }}><Spinner /></div> : searchOrderDetail?.length == 0 ? <div style={{ color: "red" }}><NoResultsFound /></div> : ""}
                                            {sorderDetail && <OrderHistory filterOrderItemDetail={filterOrderItemDetail} setFilterOrderItemDetail={setFilterOrderItemDetail} showOrderDetail={showOrderDetail} BackTab={BackTab} orderHistoryDetail={orderHistoryDetail} GetOrdersDetails={GetOrdersDetails} />}

                                            <FilterShow setFilterShow={setFilterShow} filterShow={filterShow} filterForm={filterForm} HandleChangeFilter={HandleChangeFilter} ApplyFilter={ApplyFilter} ClearFilter={ClearFilter} />
                                        </div>
                                    </CardBody>
                                </Nav>
                            </Card>
                        </Tab.Container>
                    </Col>
                </Row>
                <Row><col md={12}></col></Row>
                {!sorderDetail && searchOrderDetail?.length > 0 ? (<Row>
                    <Col md={12} className="mt-2">
                        <Card className="pagination-list-card mx-5">
                            <CardBody>
                                <h2>
                                    Page {currentPage} of{" "}
                                    {totalPages}
                                </h2>
                                <ListPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>) : ""}
            </Container>
        </section>



    )
}
