import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Footer from "../../../pages/footer";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AccountDelete from "../../../assets/images/products/delete-account.png"
import { AccountFieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import successIcon from "../../../assets/images/icons/success-icon.png";
const formValues = {
    email: '',
}
const DeleteAccountSuccess = ({ emailOtpChange }) => {
    const navigate = useNavigate();


    return (
        <>
            <div className="login-page delete-account">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="login-form">
                                    <Row>
                                        <Col className="success-icon text-center mb-2">
                                            <img src={successIcon} />
                                        </Col>
                                        <Col md={12} className="text-center">
                                            <h5>
                                                Your account has been successfully deleted
                                            </h5>
                                        </Col>
                                        <Col md={12}>
                                            <p>We’re sorry to see you go. If you have any feedback or need further assistance, please contact our support team.</p>
                                        </Col>
                                        <Col sm={12} md={12} className="d-flex justify-content-center gap-2 delete-buttons">
                                            <Link to="/" style={{ color: "inherit" }}>
                                                <button className="btn shop-btn">
                                                    Explore More
                                                </button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
};
export default DeleteAccountSuccess;