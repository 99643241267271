import React, { useState } from "react";
  
export const CartPageContext = React.createContext({});
export const CartPageProvider = ({ children }) => {
    const [cartPageItem, setCartPageItem] = useState([]);
  
    return (
        <CartPageContext.Provider value={{ cartPageItem, setCartPageItem }}>
            {children}
        </CartPageContext.Provider>
    );
};