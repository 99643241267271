import React, { useState } from "react";
  
export const ProductContext = React.createContext({});
export const ProductsContextProvider = ({ children }) => {
    const [productsList, setProductsList] = useState([]);
  
    return (
        <ProductContext.Provider value={{ productsList, setProductsList }}>
            {children}
        </ProductContext.Provider>
    );
};