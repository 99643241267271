import React, { useEffect } from 'react';
import Lottie from "react-lottie";
import animationData from './Animation - 1715597853514.json';

const NotFound = () =>{
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         window.location.href = '/';
    //     }, 3000); // 3 second
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className='not-found'>
            <a href='/'>
            <Lottie options={defaultOptions} width={"auto"} height={"auto"} />
            </a>
            {/* <div className="n-found my-3">
                <div className='d-flex flex-column align-items-center my-3'>
                    <h2>404</h2>
                    <h4>Page Not Found</h4>
                    <p>We're sorry, the page you requested could not be found
                    <br />
                    Please go back to the homepage
                </p>
                Go HOME</a>
                </div>
                
            </div> */}
        </div>
    )
}

export default NotFound