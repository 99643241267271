import React, { useEffect, useState } from 'react';
import { Col, Container, Card, CardBody, Row, Button, Modal, CardHeader } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import VisaCard from '../../assets/images/icons/visa.png';
import Product1 from '../../assets/images/products/16.png';
import moment from 'moment';
import useLogoImage from '../../hooks/useLogoImage';

export default function ReturnSummary() {
    const location = useLocation();
    const navigate = useNavigate();
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [ordersDetails, setOrdersDetails] = useState({})
    useEffect(() => {
        if (location?.state) {
            setOrdersDetails(location?.state?.orderDetails?.data?.updateOrderItem)
        }
    }, [location?.state])

    const ContinueShopping = () => {
        navigate("/all-products")
    }
    return (
        <>
            <section id="return-product" className='mt-3'>
                <Container>
                    <Card className='mt-3 mb-1'>
                        <CardBody>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="bread-crumbs">
                                        <nav>
                                            <Link to="/" className="breadcrumb-not-active">
                                                Home
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <Link className="breadcrumb-not-active" to="/account">
                                                Account
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <Link className="breadcrumb-not-active" to="/account">
                                                Orders
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <Link className="breadcrumb-not-active" to="/account">
                                                OD330193341162266100
                                            </Link>
                                            <span className="breadcrumb-arrow">/</span>
                                            <a className="breadcrumb-active">Return Product</a>
                                        </nav>
                                    </div>
                                </Col>
                            </Row>
                            <div className='product-table mb-2'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='product-list'>
                                                    <div className='pdt-img me-3'>
                                                        <img src={ordersDetails?.itemDetail?.image} className='img-fluid' style={{ width: "78px", height: "90px" }} />
                                                    </div>
                                                    <div className='pdt-detail'>
                                                        <h6>{ordersDetails?.itemDetail?.productName}</h6>
                                                        <div className='d-flex align-items-center'>
                                                            <p className='m-0 fw-bold me-5' style={{ color: "#CA0015" }}>{logoUrl?.currency} {ordersDetails?.itemDetail?.price}</p>
                                                            <p className='m-0'>Qty: <span>{ordersDetails?.quantity}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td align='right'>
                                                <div className='bottom-buttons'>
                                                    <button type='button' className='btn confirm-btn mb-2 me-2' onClick={ContinueShopping}>Continue Shopping</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                    <Card className='mb-3'>
                        <CardBody>
                            <h2 className='sub-title mb-1'>Return Summary</h2>
                            <p className='fw-normal'>Thank you for initiating a return request. We have received your request successfully. Here is a summary of your return:</p>

                            <div className='user-address my-3'>
                                <h2 className='sub-title mb-1 fs-6'>Return Reference : {ordersDetails?.returnRefundActivity?.returnReference}</h2>
                                <p>Requested on : <span className='fw-medium' style={{ color: "#5F4D4F" }}>{moment(ordersDetails?.returnRefundActivity?.createdAt).format("DD/MMM/YYYY")}</span></p>
                                <br />
                                <h4 className='sub-title mb-1'>Return Reason</h4>
                                <p>{ordersDetails?.returnRefundActivity?.reason}</p>
                            </div>
                            <Row>
                                <Col md={8}>
                                    <h2 className='sub-title my-3 fs-6'>Pickup Address</h2>
                                    <div className='user-address'>
                                        <h4>{ordersDetails?.userName}</h4>
                                        <br />                                       
                                        <p>
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.addressLine1 && `${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.addressLine1}, `}
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.addressLine2 && `${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.addressLine2}, `}
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.street && `${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.street}, `}<br />
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.city && `${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.city}, `}
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.state && `${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.state}, `}<br />
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.country && `${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.country}`}
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.postalCode && ` - ${ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.postalCode}`}
                                            </p>
                                            <p>
                                            {ordersDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress?.phoneNumber && (
                                                <span>Contact Number: {ordersDetails.returnRefundActivity?.pickUpDetails?.pickUpAddress.phoneNumber}</span>
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </section>
        </>
    )
}