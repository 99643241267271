import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AddressSlider from './addressSlider';
import BillingAddressSlider from './billingAddressSlider';
const ShippingAddress = ({ checkoutData, HandleNewAddress, doorDelivery, isBilled, isShipped, isChangeAddress, shippingData, deliveryFeeItem, setIsClickContinue, HandleContinueClick, countryItem, setIsSelfPickup, setIsSameCheck, isSameCheck, HandleSameCheckbox, isSelfPickup, changeShipp, changeBill, setChangeShipp, setChangeBill, setIsChangeAddress, userAddress, setBillValues, setShippValues, setIsBilled, setIsShipped, shippValues, billValues, setCheckedShipp, setCheckedBill, checkedShipp, checkedBill }) => {

    useEffect(() => {
        if (isSelfPickup === false) {
            if (isChangeAddress && JSON.stringify(shippValues) !== JSON.stringify(billValues)) {
                setIsSameCheck(false);
            }
            else {
                setIsSameCheck(true);
            }
        }
        else {
            setIsSameCheck(false)
            setIsSelfPickup(true)
        }

    }, []);

    return (
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-doorstep-delivery" role="tabpanel" aria-labelledby="pills-doorstep-delivery-tab">
                <Row className='mt-4'>
                    {!isSelfPickup && (
                        <>
                            <Col md={12} className='mb-3'>
                                <h3>Shipping Address</h3>
                            </Col>
                            <AddressSlider isSelfPickup={isSelfPickup} isShipped={isShipped} setIsSameCheck={setIsSameCheck} billValues={billValues} setCheckedBill={setCheckedBill} isChangeAddress={isChangeAddress} shippingData={shippingData} deliveryFeeItem={deliveryFeeItem} setIsClickContinue={setIsClickContinue} countryItem={countryItem} setIsBilled={setIsBilled} setChangeBill={setChangeBill} setBillValues={setBillValues} isSameCheck={isSameCheck} changeBill={changeBill} changeShipp={changeShipp} setChangeShipp={setChangeShipp} setIsChangeAddress={setIsChangeAddress} userAddress={userAddress} setShippValues={setShippValues} setIsShipped={setIsShipped} shippValues={shippValues} setCheckedShipp={setCheckedShipp} checkedShipp={checkedShipp} />

                            <Col md={12} className='my-3'>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="address" checked={isSameCheck} onClick={HandleSameCheckbox} />
                                    <label class="form-check-label" for="address">
                                        Billing address same as shipping address
                                    </label>
                                </div>
                            </Col>
                        </>
                    )}
                    {!isSameCheck && (
                        <>
                            <Col md={12} className='mb-3 d-flex'>
                                <h3>Billing Address</h3>
                                {userAddress && userAddress?.addressDetails?.length > 0 && isSelfPickup && (
                                    <li className="nav-item new-address-button" role="presentation">
                                        <Link
                                            to="/address"
                                            state={{ activeKey: "address", newAddress: true, checkoutData: checkoutData }}
                                        >
                                            <button
                                                type="button"
                                                className="btn shop-btn"
                                                onChange={HandleNewAddress}
                                            >
                                                <i className="fa fa-plus"></i>Add Address
                                            </button>
                                        </Link>
                                    </li>
                                )}
                            </Col>
                            <BillingAddressSlider setCheckedShipp={setCheckedShipp} isBilled={isBilled} setIsSameCheck={setIsSameCheck} isChangeAddress={isChangeAddress} setIsClickContinue={setIsClickContinue} countryItem={countryItem} setShippValues={setShippValues} setChangeShipp={setChangeShipp} isSelfPickup={isSelfPickup} setIsShipped={setIsShipped} isSameCheck={isSameCheck} shippValues={shippValues} changeBill={changeBill} changeShipp={changeShipp} setChangeBill={setChangeBill} setIsChangeAddress={setIsChangeAddress} userAddress={userAddress} setBillValues={setBillValues} setIsBilled={setIsBilled} billValues={billValues} setCheckedBill={setCheckedBill} checkedBill={checkedBill} />
                        </>
                    )}
                </Row>
                <Col md={12} className="text-center">
                    <button type='button' className='btn' onClick={HandleContinueClick}>Continue</button>
                </Col>
            </div>
        </div>
    )
}

export default ShippingAddress