/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyDetails = /* GraphQL */ `
  mutation CreateCompanyDetails(
    $input: CreateCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    createCompanyDetails(input: $input, condition: $condition) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyDetails = /* GraphQL */ `
  mutation UpdateCompanyDetails(
    $input: UpdateCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    updateCompanyDetails(input: $input, condition: $condition) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyDetails = /* GraphQL */ `
  mutation DeleteCompanyDetails(
    $input: DeleteCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    deleteCompanyDetails(input: $input, condition: $condition) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPaymentMode = /* GraphQL */ `
  mutation CreatePaymentMode(
    $input: CreatePaymentModeInput!
    $condition: ModelPaymentModeConditionInput
  ) {
    createPaymentMode(input: $input, condition: $condition) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePaymentMode = /* GraphQL */ `
  mutation UpdatePaymentMode(
    $input: UpdatePaymentModeInput!
    $condition: ModelPaymentModeConditionInput
  ) {
    updatePaymentMode(input: $input, condition: $condition) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePaymentMode = /* GraphQL */ `
  mutation DeletePaymentMode(
    $input: DeletePaymentModeInput!
    $condition: ModelPaymentModeConditionInput
  ) {
    deletePaymentMode(input: $input, condition: $condition) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAttributeName = /* GraphQL */ `
  mutation CreateAttributeName(
    $input: CreateAttributeNameInput!
    $condition: ModelAttributeNameConditionInput
  ) {
    createAttributeName(input: $input, condition: $condition) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAttributeName = /* GraphQL */ `
  mutation UpdateAttributeName(
    $input: UpdateAttributeNameInput!
    $condition: ModelAttributeNameConditionInput
  ) {
    updateAttributeName(input: $input, condition: $condition) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAttributeName = /* GraphQL */ `
  mutation DeleteAttributeName(
    $input: DeleteAttributeNameInput!
    $condition: ModelAttributeNameConditionInput
  ) {
    deleteAttributeName(input: $input, condition: $condition) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDropDownMaster = /* GraphQL */ `
  mutation CreateDropDownMaster(
    $input: CreateDropDownMasterInput!
    $condition: ModelDropDownMasterConditionInput
  ) {
    createDropDownMaster(input: $input, condition: $condition) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDropDownMaster = /* GraphQL */ `
  mutation UpdateDropDownMaster(
    $input: UpdateDropDownMasterInput!
    $condition: ModelDropDownMasterConditionInput
  ) {
    updateDropDownMaster(input: $input, condition: $condition) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDropDownMaster = /* GraphQL */ `
  mutation DeleteDropDownMaster(
    $input: DeleteDropDownMasterInput!
    $condition: ModelDropDownMasterConditionInput
  ) {
    deleteDropDownMaster(input: $input, condition: $condition) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWareHouse = /* GraphQL */ `
  mutation CreateWareHouse(
    $input: CreateWareHouseInput!
    $condition: ModelWareHouseConditionInput
  ) {
    createWareHouse(input: $input, condition: $condition) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWareHouse = /* GraphQL */ `
  mutation UpdateWareHouse(
    $input: UpdateWareHouseInput!
    $condition: ModelWareHouseConditionInput
  ) {
    updateWareHouse(input: $input, condition: $condition) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWareHouse = /* GraphQL */ `
  mutation DeleteWareHouse(
    $input: DeleteWareHouseInput!
    $condition: ModelWareHouseConditionInput
  ) {
    deleteWareHouse(input: $input, condition: $condition) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createModuleMaster = /* GraphQL */ `
  mutation CreateModuleMaster(
    $input: CreateModuleMasterInput!
    $condition: ModelModuleMasterConditionInput
  ) {
    createModuleMaster(input: $input, condition: $condition) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateModuleMaster = /* GraphQL */ `
  mutation UpdateModuleMaster(
    $input: UpdateModuleMasterInput!
    $condition: ModelModuleMasterConditionInput
  ) {
    updateModuleMaster(input: $input, condition: $condition) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteModuleMaster = /* GraphQL */ `
  mutation DeleteModuleMaster(
    $input: DeleteModuleMasterInput!
    $condition: ModelModuleMasterConditionInput
  ) {
    deleteModuleMaster(input: $input, condition: $condition) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRoleManagement = /* GraphQL */ `
  mutation CreateRoleManagement(
    $input: CreateRoleManagementInput!
    $condition: ModelRoleManagementConditionInput
  ) {
    createRoleManagement(input: $input, condition: $condition) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const updateRoleManagement = /* GraphQL */ `
  mutation UpdateRoleManagement(
    $input: UpdateRoleManagementInput!
    $condition: ModelRoleManagementConditionInput
  ) {
    updateRoleManagement(input: $input, condition: $condition) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const deleteRoleManagement = /* GraphQL */ `
  mutation DeleteRoleManagement(
    $input: DeleteRoleManagementInput!
    $condition: ModelRoleManagementConditionInput
  ) {
    deleteRoleManagement(input: $input, condition: $condition) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProductReview = /* GraphQL */ `
  mutation CreateProductReview(
    $input: CreateProductReviewInput!
    $condition: ModelProductReviewConditionInput
  ) {
    createProductReview(input: $input, condition: $condition) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProductReview = /* GraphQL */ `
  mutation UpdateProductReview(
    $input: UpdateProductReviewInput!
    $condition: ModelProductReviewConditionInput
  ) {
    updateProductReview(input: $input, condition: $condition) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProductReview = /* GraphQL */ `
  mutation DeleteProductReview(
    $input: DeleteProductReviewInput!
    $condition: ModelProductReviewConditionInput
  ) {
    deleteProductReview(input: $input, condition: $condition) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTokenDetails = /* GraphQL */ `
  mutation CreateTokenDetails(
    $input: CreateTokenDetailsInput!
    $condition: ModelTokenDetailsConditionInput
  ) {
    createTokenDetails(input: $input, condition: $condition) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTokenDetails = /* GraphQL */ `
  mutation UpdateTokenDetails(
    $input: UpdateTokenDetailsInput!
    $condition: ModelTokenDetailsConditionInput
  ) {
    updateTokenDetails(input: $input, condition: $condition) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTokenDetails = /* GraphQL */ `
  mutation DeleteTokenDetails(
    $input: DeleteTokenDetailsInput!
    $condition: ModelTokenDetailsConditionInput
  ) {
    deleteTokenDetails(input: $input, condition: $condition) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTax = /* GraphQL */ `
  mutation CreateTax(
    $input: CreateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    createTax(input: $input, condition: $condition) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTax = /* GraphQL */ `
  mutation UpdateTax(
    $input: UpdateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    updateTax(input: $input, condition: $condition) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTax = /* GraphQL */ `
  mutation DeleteTax(
    $input: DeleteTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    deleteTax(input: $input, condition: $condition) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCarouselImage = /* GraphQL */ `
  mutation CreateCarouselImage(
    $input: CreateCarouselImageInput!
    $condition: ModelCarouselImageConditionInput
  ) {
    createCarouselImage(input: $input, condition: $condition) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCarouselImage = /* GraphQL */ `
  mutation UpdateCarouselImage(
    $input: UpdateCarouselImageInput!
    $condition: ModelCarouselImageConditionInput
  ) {
    updateCarouselImage(input: $input, condition: $condition) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCarouselImage = /* GraphQL */ `
  mutation DeleteCarouselImage(
    $input: DeleteCarouselImageInput!
    $condition: ModelCarouselImageConditionInput
  ) {
    deleteCarouselImage(input: $input, condition: $condition) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWishList = /* GraphQL */ `
  mutation CreateWishList(
    $input: CreateWishListInput!
    $condition: ModelWishListConditionInput
  ) {
    createWishList(input: $input, condition: $condition) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWishList = /* GraphQL */ `
  mutation UpdateWishList(
    $input: UpdateWishListInput!
    $condition: ModelWishListConditionInput
  ) {
    updateWishList(input: $input, condition: $condition) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWishList = /* GraphQL */ `
  mutation DeleteWishList(
    $input: DeleteWishListInput!
    $condition: ModelWishListConditionInput
  ) {
    deleteWishList(input: $input, condition: $condition) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMailLog = /* GraphQL */ `
  mutation CreateMailLog(
    $input: CreateMailLogInput!
    $condition: ModelMailLogConditionInput
  ) {
    createMailLog(input: $input, condition: $condition) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const updateMailLog = /* GraphQL */ `
  mutation UpdateMailLog(
    $input: UpdateMailLogInput!
    $condition: ModelMailLogConditionInput
  ) {
    updateMailLog(input: $input, condition: $condition) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const deleteMailLog = /* GraphQL */ `
  mutation DeleteMailLog(
    $input: DeleteMailLogInput!
    $condition: ModelMailLogConditionInput
  ) {
    deleteMailLog(input: $input, condition: $condition) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const createDeliveryPartner = /* GraphQL */ `
  mutation CreateDeliveryPartner(
    $input: CreateDeliveryPartnerInput!
    $condition: ModelDeliveryPartnerConditionInput
  ) {
    createDeliveryPartner(input: $input, condition: $condition) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDeliveryPartner = /* GraphQL */ `
  mutation UpdateDeliveryPartner(
    $input: UpdateDeliveryPartnerInput!
    $condition: ModelDeliveryPartnerConditionInput
  ) {
    updateDeliveryPartner(input: $input, condition: $condition) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDeliveryPartner = /* GraphQL */ `
  mutation DeleteDeliveryPartner(
    $input: DeleteDeliveryPartnerInput!
    $condition: ModelDeliveryPartnerConditionInput
  ) {
    deleteDeliveryPartner(input: $input, condition: $condition) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLedger = /* GraphQL */ `
  mutation CreateLedger(
    $input: CreateLedgerInput!
    $condition: ModelLedgerConditionInput
  ) {
    createLedger(input: $input, condition: $condition) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLedger = /* GraphQL */ `
  mutation UpdateLedger(
    $input: UpdateLedgerInput!
    $condition: ModelLedgerConditionInput
  ) {
    updateLedger(input: $input, condition: $condition) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLedger = /* GraphQL */ `
  mutation DeleteLedger(
    $input: DeleteLedgerInput!
    $condition: ModelLedgerConditionInput
  ) {
    deleteLedger(input: $input, condition: $condition) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProductVisit = /* GraphQL */ `
  mutation DeleteProductVisit(
    $input: DeleteProductVisitInput!
    $condition: ModelProductVisitConditionInput
  ) {
    deleteProductVisit(input: $input, condition: $condition) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPromoCodes = /* GraphQL */ `
  mutation CreatePromoCodes(
    $input: CreatePromoCodesInput!
    $condition: ModelPromoCodesConditionInput
  ) {
    createPromoCodes(input: $input, condition: $condition) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePromoCodes = /* GraphQL */ `
  mutation UpdatePromoCodes(
    $input: UpdatePromoCodesInput!
    $condition: ModelPromoCodesConditionInput
  ) {
    updatePromoCodes(input: $input, condition: $condition) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePromoCodes = /* GraphQL */ `
  mutation DeletePromoCodes(
    $input: DeletePromoCodesInput!
    $condition: ModelPromoCodesConditionInput
  ) {
    deletePromoCodes(input: $input, condition: $condition) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJwtTokenIdentifier = /* GraphQL */ `
  mutation CreateJwtTokenIdentifier(
    $input: CreateJwtTokenIdentifierInput!
    $condition: ModelJwtTokenIdentifierConditionInput
  ) {
    createJwtTokenIdentifier(input: $input, condition: $condition) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJwtTokenIdentifier = /* GraphQL */ `
  mutation UpdateJwtTokenIdentifier(
    $input: UpdateJwtTokenIdentifierInput!
    $condition: ModelJwtTokenIdentifierConditionInput
  ) {
    updateJwtTokenIdentifier(input: $input, condition: $condition) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJwtTokenIdentifier = /* GraphQL */ `
  mutation DeleteJwtTokenIdentifier(
    $input: DeleteJwtTokenIdentifierInput!
    $condition: ModelJwtTokenIdentifierConditionInput
  ) {
    deleteJwtTokenIdentifier(input: $input, condition: $condition) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUnits = /* GraphQL */ `
  mutation CreateUnits(
    $input: CreateUnitsInput!
    $condition: ModelUnitsConditionInput
  ) {
    createUnits(input: $input, condition: $condition) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUnits = /* GraphQL */ `
  mutation UpdateUnits(
    $input: UpdateUnitsInput!
    $condition: ModelUnitsConditionInput
  ) {
    updateUnits(input: $input, condition: $condition) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUnits = /* GraphQL */ `
  mutation DeleteUnits(
    $input: DeleteUnitsInput!
    $condition: ModelUnitsConditionInput
  ) {
    deleteUnits(input: $input, condition: $condition) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createShipping = /* GraphQL */ `
  mutation CreateShipping(
    $input: CreateShippingInput!
    $condition: ModelShippingConditionInput
  ) {
    createShipping(input: $input, condition: $condition) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
export const deleteShipping = /* GraphQL */ `
  mutation DeleteShipping(
    $input: DeleteShippingInput!
    $condition: ModelShippingConditionInput
  ) {
    deleteShipping(input: $input, condition: $condition) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProductImage = /* GraphQL */ `
  mutation CreateProductImage(
    $input: CreateProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    createProductImage(input: $input, condition: $condition) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProductImage = /* GraphQL */ `
  mutation UpdateProductImage(
    $input: UpdateProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    updateProductImage(input: $input, condition: $condition) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProductImage = /* GraphQL */ `
  mutation DeleteProductImage(
    $input: DeleteProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    deleteProductImage(input: $input, condition: $condition) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProductDetails = /* GraphQL */ `
  mutation CreateProductDetails(
    $input: CreateProductDetailsInput!
    $condition: ModelProductDetailsConditionInput
  ) {
    createProductDetails(input: $input, condition: $condition) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProductDetails = /* GraphQL */ `
  mutation UpdateProductDetails(
    $input: UpdateProductDetailsInput!
    $condition: ModelProductDetailsConditionInput
  ) {
    updateProductDetails(input: $input, condition: $condition) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProductDetails = /* GraphQL */ `
  mutation DeleteProductDetails(
    $input: DeleteProductDetailsInput!
    $condition: ModelProductDetailsConditionInput
  ) {
    deleteProductDetails(input: $input, condition: $condition) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const createReportMaster = /* GraphQL */ `
  mutation CreateReportMaster(
    $input: CreateReportMasterInput!
    $condition: ModelReportMasterConditionInput
  ) {
    createReportMaster(input: $input, condition: $condition) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateReportMaster = /* GraphQL */ `
  mutation UpdateReportMaster(
    $input: UpdateReportMasterInput!
    $condition: ModelReportMasterConditionInput
  ) {
    updateReportMaster(input: $input, condition: $condition) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteReportMaster = /* GraphQL */ `
  mutation DeleteReportMaster(
    $input: DeleteReportMasterInput!
    $condition: ModelReportMasterConditionInput
  ) {
    deleteReportMaster(input: $input, condition: $condition) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeneralContactUs = /* GraphQL */ `
  mutation CreateGeneralContactUs(
    $input: CreateGeneralContactUsInput!
    $condition: ModelGeneralContactUsConditionInput
  ) {
    createGeneralContactUs(input: $input, condition: $condition) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeneralContactUs = /* GraphQL */ `
  mutation UpdateGeneralContactUs(
    $input: UpdateGeneralContactUsInput!
    $condition: ModelGeneralContactUsConditionInput
  ) {
    updateGeneralContactUs(input: $input, condition: $condition) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeneralContactUs = /* GraphQL */ `
  mutation DeleteGeneralContactUs(
    $input: DeleteGeneralContactUsInput!
    $condition: ModelGeneralContactUsConditionInput
  ) {
    deleteGeneralContactUs(input: $input, condition: $condition) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProductVisit = /* GraphQL */ `
  mutation CreateProductVisit(
    $input: CreateProductVisitInput!
    $condition: ModelProductVisitConditionInput
  ) {
    createProductVisit(input: $input, condition: $condition) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProductVisit = /* GraphQL */ `
  mutation UpdateProductVisit(
    $input: UpdateProductVisitInput!
    $condition: ModelProductVisitConditionInput
  ) {
    updateProductVisit(input: $input, condition: $condition) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateShipping = /* GraphQL */ `
  mutation UpdateShipping(
    $input: UpdateShippingInput!
    $condition: ModelShippingConditionInput
  ) {
    updateShipping(input: $input, condition: $condition) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
