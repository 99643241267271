import { ValidateMessages, ValidateName } from '../constants/validateMsgConstant'
import * as valid from './validate'

export default function validateField(value, message, validationTypes = []) {
  const errors = [];
  validationTypes?.forEach(validationType => {
    switch (validationType) {
      case ValidateName?.REQUIRED:
        if (!valid?.isRequired(value)) {
          errors.push(ValidateMessages.REQUIRED(message));
        }
        break;
      case ValidateName?.ISTEXT_ONLY:
        if (valid?.isRequired(value) && !valid?.isTextOnly(value)) {
          errors.push(ValidateMessages?.SHOULD_STRING(message));
        }
        break;
        case ValidateName?.LASTNAME_LENGTH:
        if (valid?.isRequired(value) && value.length <= 1) {
          errors.push(ValidateMessages?.SHOULD_BE_LONGER_THAN_ONE_CHAR(message));
        }
        break;
      case ValidateName?.EMAIL:
        if (valid?.isRequired(value) && !valid?.isValidEmail(value)) {
          errors.push(ValidateMessages.NOT_VALID(message));
        }
        break;
      case ValidateName?.ISVALID_PHONE:
        if (valid?.isRequired(value) && !valid?.isValidCountryPhone(value)) {
          errors.push(ValidateMessages.NOT_VALID(message));
        }
        break;
      case ValidateName?.LOGINPASSWORD:
        if (valid?.isRequired(value) && !valid?.isValidPassword(value)) {
          errors.push(ValidateMessages.PASSWORD_RESTRICTION);
        }
        break;
      case ValidateName?.OTPCODE:
        if (valid?.isRequired(value) && !valid?.isNumberOnly(value)) {
          errors.push(ValidateMessages.NOT_VALID(message));
        }
        break;
      case ValidateName?.ISNUMBERONLY:
        if (valid?.isRequired(value) && !valid?.isNumberOnly(value)) {
          errors.push(ValidateMessages.NOT_VALID(message));
        }
        break;
        case ValidateName?.ALL_CHARACTER:
        if (valid?.isRequired(value) && !valid?.isSingleSpaceAllowed(value)) {
          errors.push(ValidateMessages.NOT_VALID(message));
        }
        break;
        case ValidateName?.MAXIMUM_LENGTH:
        if (value && value.length > 50) {
          errors.push(ValidateMessages.MAXIMUM_CHARACTER);
        }
        break;
      default:
        if (typeof validationType === 'function') {
          const customError = validationType(value);
          if (customError) {
            errors.push(customError);
          }
        }
        break;
    }
  })

  return errors.length > 0 ? errors : undefined;
}