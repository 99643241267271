import { useContext, useEffect, useState } from 'react';
import { CountryContext } from "../context/CountryContext";
import { GetCountryList } from "../utils/generalMethod"

function useCountry() {

    const { countryList, setCountryList } = useContext(CountryContext);
    const [countryResponse, setCountryReposnse] = useState(null);

    useEffect(() => {
        const GetCountryDetails = async () => {
            GetCountryList({}, setCountryReposnse)
        }
        GetCountryDetails();
    }, [])

    useEffect(() => {
        setCountryList(countryResponse?.data?.data?.listCountries?.items)
    }, [countryResponse])

    return [countryList, setCountryList];
}

export default useCountry;