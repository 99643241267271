import React, { useState } from "react";
import { CTooltip } from '@coreui/react';
const ProductToolTip = ({ productName }) => {
  const isOverLimit = productName?.length > 69;
  const truncatedName = isOverLimit ? `${productName?.slice(0, 65)}...` : productName;
  return (
    <CTooltip content={`${productName}`} placement="bottom">
      <span>{truncatedName}</span>
    </CTooltip>
  )
}

export default ProductToolTip
