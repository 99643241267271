import React, { useEffect, useState } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
const center = {
    lat: 8.682928764058133,
    lng: 77.73327728786936,
};
const MapView = ({ google, setShowMapView, setAddress, ChooseAddressFromMap }) => {
    const navigate = useNavigate();
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [addressDetail, setAddressDetail] = useState('');
    const [addressDisplay, setAddressDisplay] = useState('');

    const handleMapClick = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        setSelectedPosition({ lat, lng });
        getAddressFromLatLng(lat, lng)
    };

    const getAddressFromLatLng = async (lat, lng) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);

            if (response.ok) {
                const data = await response.json();
                if (data && data.display_name) {
                    setAddressDetail(data);
                    let strDisplay = "";
                    if (data?.address?.house_number) strDisplay = strDisplay + data?.address?.house_number + ","
                    if (data?.address?.village || data?.address?.road || data?.address?.industrial)
                        strDisplay = strDisplay + (data?.address?.village || data?.address?.road || data?.address?.industrial) + ",<br/>"
                    if (data?.address?.city) strDisplay = strDisplay + data?.address?.city + ",<br/>"
                    if (data?.address?.county || data?.address?.municipality) strDisplay = strDisplay + (data?.address?.county || data?.address?.municipality) + ",<br/>"
                    if (data?.address?.state_district || data?.address?.district || data?.address?.town) strDisplay = strDisplay + (data?.address?.state_district || data?.address?.district || data?.address?.town) + ",<br/>"
                    if (data?.address?.state) strDisplay = strDisplay + data?.address?.state + ",<br/>"
                    if (data?.address?.country) strDisplay = strDisplay + data?.address?.country
                    if (data?.address?.postcode) strDisplay = strDisplay + " - " + data?.address?.postcode
                    setAddressDisplay(strDisplay)
                } else {
                    console.error('No address found in the response:', data);
                }
            } else {
                console.error('Error fetching data:', response.statusText);
            }
        } catch (error) {
            console.error('Error during fetch request:', error);
        }
    };

    const HandleAddress = () => {
        ChooseAddressFromMap(addressDetail)
    }

    const BackTab = () => {
        setShowMapView(false);
        setAddress(true);
    }

    return (<>

        <section id="mapViewSection">
            <container>
                <Row className="mb-3">
                    <Col md={12}>
                        <Card className='mt-1'>
                            <CardBody className='py-2'>
                                    <i onClick={BackTab} className="fa fa-chevron-left"></i>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={addressDetail ? 9 : 12} className="mt-1">
                        <Card>
                            <CardBody>
                                <Map
                                    google={google}
                                    zoom={10}
                                    center={center}
                                    style={{ width: '100%', height: '100%' }}
                                    onClick={handleMapClick}
                                    className="map-container">
                                    {selectedPosition && <Marker position={selectedPosition} />}
                                </Map>
                            </CardBody>
                        </Card>
                    </Col>
                    {addressDisplay &&
                        (<Col md={3} className="mt-1">
                            <Card>
                                <CardBody>
                                    <div className="address-tile">
                                        <div className="user-detail">
                                            <p>
                                                <div dangerouslySetInnerHTML={{ __html: addressDisplay }} />
                                            </p>
                                            {/* <p>
                                                {address?.address?.house_number && address?.address?.house_number + ","}
                                                {(address?.address?.village || address?.address?.road || address?.address?.industrial) && address?.address?.village || address?.address?.road || address?.address?.industrial}, <br />{" "}
                                                {address?.address?.city && address?.address?.city + "," + <br />}
                                                {address?.address?.county && address?.address?.county + "," + <br />}
                                                {address?.address?.state_district && address?.address?.state_district + "," + <br />}
                                                {address?.address?.state && address?.address?.state + ","}<br />
                                                {address?.address?.country} {address?.address?.postcode && " - " + address?.address?.postcode}
                                            </p> */}
                                        </div>
                                        <button
                                            className="btn shop-btn"
                                            onClick={HandleAddress}
                                        >
                                            Use this address
                                        </button>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>)
                    }
                </Row>
            </container>
        </section>
    </>
    );
};
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAFp4VTBGhPLTrcSXpHil5XO0L2HDsR1X4',
})(MapView);
