import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Amplify, { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { AccountFieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import InputGroup from 'react-bootstrap/InputGroup';
const formValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}
const ChangePassword = () => {
  const [passwordValues, setPasswordValues] = useState(formValues)
  const [passwordErrors, setPasswordErrors] = useState({})
  const [isPasswordSubmit, setIsPasswordSubmit] = useState(false)
  const navigate = useNavigate()

  const HandleChangePassword = (e) => {
    const { name, value } = e.target
    setPasswordErrors({
      ...passwordErrors,
      [name]: ""
    });
    setPasswordValues({
      ...passwordValues,
      [name]: value,
    });
  }

  const HandleBlurPassword = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validationPassword({
      ...passwordValues,
      [name]: value,
    });
    setPasswordErrors({
      ...passwordErrors,
      [name]: fieldErrors[name]
    });
  };

  const validationPassword = (passwordValues) => {
    const passwordErrors = {};
    passwordErrors.currentPassword = ValidateField(
      passwordValues.currentPassword,
      AccountFieldNameConstant?.CURRENTPASSWORD,
      [ValidateName?.REQUIRED, ValidateName?.LOGINPASSWORD],
    );

    passwordErrors.newPassword = ValidateField(
      passwordValues.newPassword,
      AccountFieldNameConstant?.NEWPASSWORD,
      [ValidateName?.REQUIRED, ValidateName?.LOGINPASSWORD],
    );

    passwordErrors.confirmPassword = ValidateField(
      passwordValues.confirmPassword,
      AccountFieldNameConstant?.CONFIRMPASSWORD,
      [ValidateName?.REQUIRED, ValidateName?.LOGINPASSWORD],
    );

    if (passwordValues.newPassword !== passwordValues.confirmPassword) {
      passwordErrors.confirmPassword = 'Confirm password does not match new password';
    }

    let error = {}
    Object.keys(passwordErrors).map((key, index) => {
      if (passwordErrors[key] !== undefined)
        error[key] = passwordErrors[key]
    })
    return error
  }

  const SendChangePassword = (e) => {
    e.preventDefault();
    setPasswordErrors(validationPassword(passwordValues));
    setIsPasswordSubmit(true);
  }

  useEffect(() => {
    const HandlePassword = async () => {
      const user = await Auth.currentAuthenticatedUser();
      try {
        await Auth.changePassword(user, passwordValues?.currentPassword, passwordValues?.newPassword);
        showSuccessToastr('Password Changed Successfully')
        navigate('/login')
      } catch (error) {
        showErrorToastr(error.message);
      }
    };
    if (Object.keys(passwordErrors).length === 0 && isPasswordSubmit) {
      HandlePassword();
    }
  }, [passwordErrors]);


  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const CurrentPasswordVisibility = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };

  const NewPasswordVisibility = () => {
    setNewPasswordShown(!newPasswordShown);
  };

  const ConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  // const [passwordShown, setPasswordShown] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setPasswordShown(!passwordShown);
  // };


  return (
    <div className="login-page change-password">
      <Card>
        <Row>
          <Col md={12}>
            <div className="login-sec">
              <div className="login-form" style={{ maxWidth: "650px" }}>
                <form>
                  <Row>
                    <Col md={12}>
                      <Link to="/profile" style={{ color: "inherit" }}>
                        <div class="back-btn">
                          <i className="fa fa-chevron-left"></i>
                        </div>
                      </Link>
                      <h1>
                        Change Password
                      </h1>
                    </Col>
                    <Col sm={12} md={12}>
                      <div class="form-group mt-1">
                        <label>Current Password</label>
                        <InputGroup>
                          <Form.Control
                            type={currentPasswordShown ? "text" : "password"}
                            id="currentPassword" name="" placeholder="Enter" onBlur={(e) => HandleBlurPassword(e)} onChange={(e) => HandleChangePassword(e)} style={{ borderRight: 0 }}
                          />
                          <InputGroup.Text onClick={CurrentPasswordVisibility} style={{ cursor: 'pointer' }}>
                            <i className={`fa ${currentPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                          </InputGroup.Text>
                        </InputGroup>
                        {
                          passwordErrors?.currentPassword && <p className="form-error">{passwordErrors?.currentPassword}</p>
                        }
                        {/* <input class="form-control" type="password" id="currentPassword" name="currentPassword" placeholder="Enter" onBlur={(e) => HandleBlurPassword(e)} onChange={(e) => HandleChangePassword(e)} />
                        {
                          passwordErrors?.currentPassword && <p className="form-error">{passwordErrors?.currentPassword}</p>
                        } */}
                      </div>
                    </Col>
                    <Col sm={12} md={12}>
                      <div class="form-group">
                        <label>New Password</label>
                        <InputGroup>
                          <Form.Control
                            type={newPasswordShown ? "text" : "password"}
                            id="newPassword" name="newPassword" placeholder="Enter" onBlur={(e) => HandleBlurPassword(e)} onChange={(e) => HandleChangePassword(e)} style={{ borderRight: 0 }}
                          />
                          <InputGroup.Text onClick={NewPasswordVisibility} style={{ cursor: 'pointer' }}>
                            <i className={`fa ${newPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                          </InputGroup.Text>
                        </InputGroup>
                        {
                          passwordErrors?.newPassword && <p className="form-error">{passwordErrors?.newPassword}</p>
                        }
                        {/* <input class="form-control" type="password" id="newPassword" name="newPassword" placeholder="Enter" onBlur={(e) => HandleBlurPassword(e)} onChange={(e) => HandleChangePassword(e)} />
                        {
                          passwordErrors?.newPassword && <p className="form-error">{passwordErrors?.newPassword}</p>
                        } */}
                      </div>
                    </Col>
                    <Col sm={12} md={12}>
                      {/* <div class="form-group">
                        <label>Confirm Password</label>
                        <input class="form-control" type="password" id="confirmPassword" name="confirmPassword" placeholder="Enter" onBlur={(e) => HandleBlurPassword(e)} onChange={(e) => HandleChangePassword(e)} />
                        {
                          passwordErrors?.confirmPassword && <p className="form-error">{passwordErrors?.confirmPassword}</p>
                        }
                      </div> */}
                      <div class="form-group">
                        <label>Confirm Password</label>
                        <InputGroup>
                          <Form.Control
                            type={confirmPasswordShown ? "text" : "password"}
                            id="confirmPassword" name="confirmPassword" placeholder="Enter" onBlur={(e) => HandleBlurPassword(e)} onChange={(e) => HandleChangePassword(e)} style={{ borderRight: 0 }}
                          />
                          <InputGroup.Text onClick={ConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
                            <i className={`fa ${confirmPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                          </InputGroup.Text>
                        </InputGroup>
                        {
                          passwordErrors?.confirmPassword && <p className="form-error">{passwordErrors?.confirmPassword}</p>
                        }
                      </div>
                    </Col>
                    <Col md={12}>
                      <Link to="/login" style={{ color: "inherit" }}><button className="btn shop-btn mb-1" type="submit" onClick={(e) => SendChangePassword(e)}>Change Password</button></Link>
                      <Link to="/profile" style={{ color: "inherit" }}><button class="btn cancel-btn">Cancel</button></Link>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>

          </Col>
        </Row>
      </Card>
    </div>

  )
}

export default ChangePassword