import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify'
import Footer from "../../pages/footer";
import customLogger from "../../common/loggerService";
import { useNavigate } from 'react-router-dom';
import { ValidateName, AccountFieldNameConstant } from "../../constants/validateMsgConstant";
import { v4 as uuid } from 'uuid';
import ValidateField from "../../utils/useValid";
import { showErrorToastr } from "../../common/toastrService";
import { commonImages, Status } from "../../constants";
import { Link } from 'react-router-dom';
import { GetData, PostData } from "../../providers/queryData";
import { resourceconstant } from "../../constants";
import { useLocation, useHistory } from 'react-router-dom';
import { usePreviousPage } from "../../context/usePreviousPage";
import { UpdateUserAddress } from "../../utils/generalMethod";
import useUserDetails from '../../hooks/useUserDetails'
import InputGroup from 'react-bootstrap/InputGroup';
import GoogleIcon from "../../assets/images/icons/Google-icon.png";
import facebookIcon from "../../assets/images/icons/Facebook-icon.png";
import { Helmet } from "react-helmet";
import { ToastrMessages } from "../../constants/toastrMessages";
import useLogoImage from "../../hooks/useLogoImage";
const formValues = {
    email: '',
    password: ''
}
const Login = () => {
    const [showSignin, setSignin] = useState("Email");
    const [authError, setAuthError] = useState(null);
    const [listToken, setListToken] = useState([])
    const [values, setValues] = useState(formValues)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [tokenDetail, setTokenDetail] = useState([])
    const [responseData, setResponseData] = useState([])
    const [previousPage] = usePreviousPage();
    const [userDetails, setUserDetails] = useState(null)
    const [useuserDetails, setUseUserDetails] = useUserDetails()
    const [logoUrl, setLogoUrl] = useLogoImage();

    const handleChange = (e) => {
        setSignin(e.target.value);
    }

    const HandleChangeLogin = (e) => {
        const { name, value } = e.target

        setErrors({
            ...errors,
            [name]: ""
        });
        setValues({
            ...values,
            [name]: value,
        });
    }

    const HandleBlur = (e) => {
        const { name, value } = e.target;
        const fieldErrors = validation({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: fieldErrors[name]
        });
    };

    const validation = (values) => {
        const errors = {};

        errors.email = ValidateField(
            values.email,
            AccountFieldNameConstant?.EMAIL,
            [ValidateName?.REQUIRED, ValidateName?.ISVALID_EMAIL],
        );

        errors.password = ValidateField(
            values?.password,
            AccountFieldNameConstant?.PASSWORD,
            [ValidateName?.REQUIRED, ValidateName?.LOGINPASSWORD],
        );
        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })

        return error
    }

    const navigate = useNavigate();

    const CreateToken = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
        const refresh = currentUser.signInUserSession.refreshToken.token;
        const userid = currentUser.attributes.sub;
        localStorage.setItem('IdToken', accessToken);

        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().split('.')[0] + 'Z';
        const tokenType = "Bearer";

        const accessTokenExpirationTime = new Date(currentUser.signInUserSession.accessToken.payload.exp * 1000);
        const formattedAccessTokenExpirationTime = accessTokenExpirationTime.toISOString().split('.')[0] + 'Z';

        const input = {
            pk: "TOKEN#",
            id: uuid(),
            expiresIn: formattedAccessTokenExpirationTime,
            token: refresh,
            tokenType: tokenType,
            userID: userid,
            loginAt: formattedCurrentDate,
        };

        try {
            PostData(resourceconstant.CREATE_TOKEN_DETAIL, input, setResponseData);
        } catch (error) {
            customLogger.error(error);
        }
    };

    const ListToken = () => {
        let filter = {}
        GetData(resourceconstant.LIST_TOKEN_DETAILS, filter, setTokenDetail)
    };
    useEffect(() => {
        if (tokenDetail?.severity === "success") {
            setListToken(tokenDetail?.data?.data?.listTokenDetails?.items)
        }
    }, [tokenDetail])

    useEffect(() => {
        ListToken()
    }, [])

    const UpdateToken = async (tokenDetailId) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
        const refresh = currentUser.signInUserSession.refreshToken.token;
        const userid = currentUser.attributes.sub;
        localStorage.setItem('IdToken', accessToken);

        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().split('.')[0] + 'Z';
        const tokenType = "Bearer";

        const accessTokenExpirationTime = new Date(currentUser.signInUserSession.accessToken.payload.exp * 1000);
        const formattedAccessTokenExpirationTime = accessTokenExpirationTime.toISOString().split('.')[0] + 'Z';

        const input = {
            pk: "TOKEN#",
            id: `${tokenDetailId}`,
            expiresIn: formattedAccessTokenExpirationTime,
            token: refresh,
            tokenType: tokenType,
            userID: userid,
            loginAt: formattedCurrentDate,
        };

        try {
            PostData(resourceconstant.UPDATE_TOKEN_DETAIL, input, setResponseData);
        } catch (error) {
            customLogger.error(error);
        }
    };

    const LoginWithEmail = (e) => {
        e.preventDefault();
        setErrors(validation(values));
        setIsSubmit(true);
    }

    const UpdateUser = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userid = currentUser.attributes.sub;
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().split('.')[0] + 'Z';
        const { attributes } = currentUser;
        const firstName = attributes['custom:firstName'];
        const lastName = attributes['custom:lastName'];
        let data = {
            pk: `USER#`,
            id: userid,
            loginAt: formattedCurrentDate,
            userName: `${firstName} ${lastName}`
        }
        UpdateUserAddress(data, setUserDetails)
    }
    const updateLocalStorageCartItems = (userid) => {
        const cartData = localStorage.getItem('cartData');
        if (cartData) {
            const cartItems = JSON.parse(cartData);
            const updatedCartItems = cartItems.map(item => ({
                ...item,
                userId: item.userID || userid
            }));
            localStorage.setItem('cartData', JSON.stringify(updatedCartItems));
        }
    };

    useEffect(() => {
        const Login = async () => {
            try {
                const response = await Auth.signIn(values.email, values.password);
                UpdateUser()
                const currentUser = await Auth.currentAuthenticatedUser();
                const { attributes } = currentUser;
                const userid = attributes.sub;
                const firstName = attributes['custom:firstName'];
                const lastName = attributes['custom:lastName'];
                localStorage.setItem("fullName", `${firstName} ${lastName}`)
                updateLocalStorageCartItems(userid);
                const matchedToken = listToken.find((token) => token.userID === response.attributes.sub);

                if (matchedToken) {
                    const tokenDetailId = matchedToken.id;
                    UpdateToken(tokenDetailId);
                } else {
                    CreateToken();
                }
                if (useuserDetails?.status === Status?.INACTIVE) {
                    showErrorToastr(ToastrMessages?.USERDEACTIVATE)
                }
                else {
                    navigate('/')
                }
            } catch (error) {
                if (error.code === 'UserNotConfirmedException') {
                    showErrorToastr("Please verify your email address to complete the registration process. Check your inbox for a verification email. If you don't see it, please check your spam or junk folder. If you still can't find it, click here to resend the verification code.")
                    await Auth.resendSignUp(values.email);
                    navigate('/otp-verification');
                } else {
                    customLogger.error(error);
                    showErrorToastr(error.message);
                }
            }
        };
        if (Object.keys(errors).length === 0 && isSubmit) {
            Login();
        }
    }, [errors]);

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            <div className="login-page">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="logo-sec">
                                    <Link to="/" style={{ color: "inherit" }} className="text-decoration-none">
                                        <div class="back-btn">
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className="login-form">
                                    <form>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Sign In</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                {/* <p>If you have an account, sign in with your email address.</p> */}

                                                {/* <div class="form-group mt-2">
                                                <Form.Check
                                                    inline
                                                    label="Email"
                                                    name="radiob"
                                                    type="radio"
                                                    id={`inline-radio-2`}
                                                    checked={showSignin === 'Email'} value="Email" onChange={(e) =>handleChange(e)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Mobile Number"
                                                    name="radiob"
                                                    type="radio"
                                                    id={`inline-radio-3`}
                                                    value="Mobile" checked={showSignin === 'Mobile'}  onChange={(e) =>handleChange(e)}
                                                />
                                            </div> */}
                                            </Col>
                                            {showSignin == "Email" &&
                                                <>
                                                    <Col sm={12} md={12}>
                                                        <div className="form-group">
                                                            <label>Email <span style={{ color: "red" }}>*</span></label>
                                                            <input className="form-control"
                                                                type="email"
                                                                placeholder="Enter"
                                                                name="email"
                                                                id='email'
                                                                onBlur={(e) => HandleBlur(e)}
                                                                onChange={(e) => HandleChangeLogin(e)} />
                                                            {
                                                                errors?.email && <p className="form-error">{errors?.email}</p>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div className="form-group">
                                                            <label>Password <span style={{ color: "red" }}>*</span></label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type={passwordShown ? "text" : "password"}
                                                                    placeholder="Enter"
                                                                    name="password"
                                                                    id='password'
                                                                    onBlur={(e) => HandleBlur(e)}
                                                                    onChange={(e) => HandleChangeLogin(e)} style={{ borderRight: 0 }} />
                                                                <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                                    <i className={`fa ${passwordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            {
                                                                errors?.password && <p className="form-error">{errors?.password}</p>
                                                            }

                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div class="form-group mb-0 float-end">
                                                            <label><Link to="/forgot-password" className="link-text">Forgot Password?</Link></label>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <button className="btn submit-btn" onClick={LoginWithEmail}>Login</button>
                                                        {authError && <p className="form-error">{authError}</p>}
                                                    </Col>
                                                </>
                                            }
                                            {showSignin == "Mobile" &&
                                                <>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>Mobile number</label>
                                                            <input class="form-control" type="text" placeholder="Enter" />
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <div class="form-group">
                                                            <label>OTP</label>
                                                            <input class="form-control" type="text" placeholder="Enter" />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <button className="btn submit-btn" ><Link to="/">Login</Link></button>
                                                    </Col>
                                                </>
                                            }

                                            <Col md={12}>
                                                <Row className="social-signin mb-2">
                                                    <Col md={12} className="my-2" >
                                                        <p className="text-center">Sign in with social account</p>
                                                    </Col>
                                                    <Col md={12} className="my-2 d-flex justify-content-center align-items-center gap-3 social-signin-col">
                                                        <div className="d-flex me-3 gap-2 align-items-center social-google">
                                                            <img src={GoogleIcon} />
                                                            <p className="text-center">Continue with Google</p>
                                                        </div>
                                                        <div className="d-flex gap-2  align-items-center social-facebook">
                                                            <img src={facebookIcon} />
                                                            <p className="text-center">Continue with Facebook</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-center">By continuing, you agree to {logoUrl?.companyName}’s<Link to="/terms-condition" state={{ activeKey: "terms" }} onClick={() => { window.scroll(0, 0); }}>Conditions of Use</Link>and<Link to='/privacy-policy' onClick={() => { window.scroll(0, 0); }}>Privacy Policy.</Link></p>
                                                <hr />
                                            </Col>
                                            <Col md={12}>
                                                <p className="register-link">New User ?<Link to="/create-account">Create New Account</Link></p>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="footer-sec">
                <div className="footer-wrapper">
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default Login