import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../../../pages/footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CreateEntry } from "../../../providers/queryData";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import { FieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { showErrorToastr } from "../../../common/toastrService";
import { isRequired } from "../../../utils/validate";
import useProfile from "../../../hooks/useProfile";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
  otpVerify: '',
}
const MobileOtp = ({ setPhoneOtp }) => {
  const [values, setValues] = useState(formValues)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [sendOtp, setSendOtp] = useState('')
  const navigate = useNavigate()
  const [userProfile, setUserProfile] = useProfile()
  const userid = userProfile?.attributes?.sub;
  const phoneNo = localStorage.getItem('profilePhoneno');
  const [logoUrl, setLogoUrl] = useLogoImage();

  const HandleChangeOtp = (e) => {
    const { name, value } = e.target

    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  }

  const HandleBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validation({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: fieldErrors[name]
    });
  };

  const validation = (values) => {
    const errors = {};
    errors.otpVerify = ValidateField(
      values.otpVerify,
      FieldNameConstant?.OTP,
      [ValidateName?.REQUIRED, ValidateName?.OTPCODE],
    );

    let error = {}
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined)
        error[key] = errors[key]
    })
    return error
  }

  const PhoneOtp = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
  }

  useEffect(() => {
    const HandlePhoneOtp = async (e) => {
      try {
        const data = {
          user_id: userid,
          new_phone_number: phoneNo,
          input_otp: values?.otpVerify
        };
        await CreateEntry(resourceconstant?.PROFILE_PHONE_VERIFY_OTP, true, setSendOtp, data)
        navigate('/login');
      } catch (error) {
        customLogger.error(error);
        showErrorToastr(error.message)
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      HandlePhoneOtp();
    }
  }, [errors])

  useEffect(() => {
    if (sendOtp?.status === true) {
      navigate("/login");
    }
  }, [sendOtp])

  return (
    <>
      <div className="login-page otp">
        <Card>
          <Row>
            <Col md={12}>
              <div className="login-sec">
                <div className="logo-sec">
                  <Link to="/change-phoneno" style={{ color: "inherit" }}>
                    <div class="back-btn">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                  </Link>
                </div>
                <div className="login-form" style={{ maxWidth: "650px" }}>
                  <form
                    onSubmit={(e) => PhoneOtp(e)}>
                    <Row>
                      <Col md={12}>
                        <h1>Verify Your Identity</h1>
                      </Col>
                      <Col sm={12} md={12}>
                        <div class="form-group">
                          <p>
                            Please enter the One-Time Password (OTP) sent to +91 789 456 1230.This helps us ensure the security of your account
                          </p>
                        </div>
                      </Col>
                      <Col sm={12} md={12}>
                        <div class="form-group">
                          <div>
                            <input class="form-control" type="text" placeholder="Enter" name='otpVerify' id="otpVerify" onChange={(e) => HandleChangeOtp(e)} onBlur={(e) => HandleBlur(e)} />
                            {
                              errors?.otpVerify && <p className="form-error">{errors?.otpVerify}</p>
                            }
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <p className="register-link mt-2 text-start">
                          <a style={{ color: "#045EC6", cursor: "pointer" }}>
                            Resend OTP
                          </a>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-end mt-2">
                          00.30
                        </p>
                      </Col>
                      <Col md={12}>
                        <button className="btn submit-btn" type="submit">
                          Verify Code
                        </button>
                      </Col>
                      <Col md={12}>
                        <p className="text-center">
                          By continuing, you agree to {logoUrl?.comapnyName}’s{" "}
                          <Link
                            to="/terms-condition"
                            state={{ activeKey: "terms" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Conditions of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Privacy Policy
                          </Link>
                          .
                        </p>
                        <hr />
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default MobileOtp;
