import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import CommentIcon from '../../assets/images/icons/message.png';
import { GetData } from '../../providers/queryData';
import { PolicyContent, resourceconstant } from '../../constants';
import NoContentFound from '../not-found/noContent';
const CommunityPolicy = () => {
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState([]);
    const [privacyPolicy, setPrivacyPolicy] = useState([])

    const GetPolicyContent = () => {
        let filter = {}
        GetData(resourceconstant.POLICY_CONTENTS, filter, setPrivacyPolicy)
    };

    useEffect(() => {
        if (privacyPolicy?.severity === "success") {
            const content = privacyPolicy?.data?.data?.listContents?.items
            const policyCon = content.filter(item => item.title && item.content && item?.contentName?.startsWith(PolicyContent?.COMMUNITY));
            setPrivacyPolicyContent(policyCon)
        }
    }, [privacyPolicy])

    useEffect(() => {
        GetPolicyContent()
    }, [])

    return (
        <section id="policy-sec" className='my-3'>
            <Container>
                <Row className='mt-1'>
                    <Col md={12}>
                    {privacyPolicyContent?.length > 0 ? (
                        privacyPolicyContent.map((item, index) => (
                            <Card>
                                <CardHeader>
                                    <h2><img src={CommentIcon} />{item?.title}</h2>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <div className="policy-right">
                                                <div className='policy-description' dangerouslySetInnerHTML={{ __html: item?.content }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        ))
                    ) : <NoContentFound />}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CommunityPolicy