export const ValidateMessages = {
  REQUIRED: (fieldName) => `${fieldName} is required`,
  SELECTED: (fieldName) => `please select ${fieldName}`,
  NOT_VALID: (fieldName) => `${fieldName} is not valid`,
  SHOULD_STRING: (fieldName) => `Accepts only alphabets and spaces`,
  NOT_ALLOWED: "Negative values/text/zero not allowed",
  ALREADY_EXISTS: "Contact with same email or phone number already exists",
  PASSWORD_RESTRICTION:
    "Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character ",
  VALID_NAME_MSG: "Please enter a valid name and not an email format.",
  START_WITH_NO: "Name should not start with a number.",
  SHOULD_NOT_WEB_URL: "Name should not contain a website URL.",
  ONLY_TEXT_ALLOWED: "Name should contain only text.",
  NOT_MATCHED_CAPTCHA: "Captcha does not match",
  SUCCESS_MESSAGE: "Message send successfully.Your reference no is ",
  CONTACT_ERROR_MESSAGE: "Failed to send message",
  OUT_OF_STOCK:"This product is out of stock",
  SHOULD_BE_LONGER_THAN_ONE_CHAR: (field) => `${field} must be longer than 1 character.`,
  DUBLICATE_ADDRESS:"This address is already added. Please provide a different one.",
  MAXIMUM_CHARACTER: "Max 50 characters"
};

export const ValidateName = {
  REQUIRED: "isRequired",
  ISTEXT_ONLY: "isTextOnly",
  ISVALID_PHONE: "isValidPhone",
  ISVALID_EMAIL: "isValidEmail",
  EMAIL: "isValidEmail",
  LOGINPASSWORD: "isValidPassword",
  OTPCODE: "isValidCode",
  ISNUMBERONLY: "isNumberOnly",
  LASTNAME_LENGTH: 'LASTNAME_LENGTH',
  ALL_CHARACTER: 'allCharacter',
  MAXIMUM_LENGTH: 'maximumLength'
};

export const FieldNameConstant = {
  NAME: "Name",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "Email",
  ADDRESS: "Address",
  HOUSER_NUMBER: "Building/house number",
  APPARTMENT: "Apartment/Suite/Unit Number",
  AREA: "Street/Area",
  CITY: "City",
  POSTAL_CODE: "Postal Code",
  COUNTRY: "Country",
  STATE: "State",
  COMPANY_NAME: "Company Name",
  SUBJECT: "Subject",
  USER_MESSAGE: "User Message",
  MESSAGE: "Message",
  OTP: "Otp",
  TITLE: "Title",
  DESCRIPTION: "Description",
  CANCELREASON: "Cancel Reason",
  SPECIFY_REASON: "Specify the reason",
  RETURN_REASON: "Return reason",
  COMMENTS: "Comments",
  CAPTCHA: "Captcha",
  RATING:"Rating",
  POSTALCODE: "Postalcode",
  LANDMARK: "Landmark",
};

export const AccountFieldNameConstant = {
  FIRSTNAME: "FirstName",
  LASTNAME: "LastName",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "Email",
  PASSWORD: "Password",
  CURRENTPASSWORD: "CurrentPassword",
  NEWPASSWORD: "NewPassword",
  CONFIRMPASSWORD: "Confirm password",
  PASSWORDNOTMATCH: "Password do not match"
};

export const PaymentError = {
  PAYMENT: "Please select the payment method",
}

export const PasswordValidation = {
  CHARACTER: "Minimum 8 Characters Long",
  CASES: "Includes Both Upper and Lowercase Characters",
  NUMBER: "Contains At Least One Number",
  SPECIALCHARACTER: "Includes At Least One Special Character"
}